import clsx from "clsx";

import { CommunityMemberAvatar } from "@/client_app/components/CommunityMemberAvatar";
import { Checkbox } from "@/ui/Checkbox";

interface CommunityMember {
  fullName: string;
  profileImageUrl?: string;
}

interface PastPositionListItemDesktopProps {
  communityMember: CommunityMember;
  finishOn: string;
  startOn: string;
  title: string | JSX.Element;
  checked: boolean;
  onChange: () => void;
}

const PastPositionListItemDesktop = ({
  communityMember,
  finishOn,
  startOn,
  title,
  checked,
  onChange,
}: PastPositionListItemDesktopProps): JSX.Element => {
  const { fullName, profileImageUrl } = communityMember;

  const tableCellStyleClasses =
    "border border-neutral-200 border-l-0 border-r-0 first:border-l last:border-r first:rounded-tl-xl first:rounded-bl-xl last:rounded-br-xl last:rounded-tr-xl py-4 px-5 first:pl-6 last:pr-6";

  return (
    <tr>
      <td className={clsx(tableCellStyleClasses)}>
        <div className='flex items-center gap-x-4'>
          <Checkbox checked={checked} onChange={onChange} />
          <CommunityMemberAvatar
            profileImageUrl={profileImageUrl}
            name={fullName}
            size='SMALL'
          />
          <span>{fullName}</span>
        </div>
      </td>
      <td className={clsx(tableCellStyleClasses)}>{title}</td>
      <td className={clsx(tableCellStyleClasses)}>{startOn}</td>
      <td className={clsx(tableCellStyleClasses, "text-error-600")}>
        {finishOn}
      </td>
      <td className={clsx(tableCellStyleClasses)}></td>
    </tr>
  );
};

export { PastPositionListItemDesktop };
