import Rails from "@rails/ujs";
import { downloadFileFromAjaxResponse } from "helpers/download_file_from_ajax_response";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  declare formTarget: HTMLFormElement;

  submitForm(e): void {
    e.preventDefault();
    const formData = new FormData(this.formTarget);
    formData.append(
      "attendance_ids",
      JSON.stringify(this.getUnassignedAttendances())
    );

    Rails.ajax({
      type: "post",
      url: "/admin/unapproved_attendances/csv_exports",
      data: formData,
      dataType: "json",
      success: ({ filename, content, type }) => {
        downloadFileFromAjaxResponse(content, filename, type);
      },
    });
  }

  getUnassignedAttendances(): string[] {
    const checkboxes = document.querySelectorAll(
      "input[class*=attendance-id-checkboxes]:checked"
    );
    const attendanceIds = [];

    for (let i = 0; i < checkboxes.length; i++) {
      const checkBoxElement = checkboxes[i] as HTMLInputElement;
      attendanceIds.push(checkBoxElement.value);
    }

    return attendanceIds;
  }
}
