import clsx from "clsx";

export const PublicHolidayChip = (): JSX.Element => {
  return (
    <div
      className={clsx(
        "inline-block rounded-full py-1 px-3",
        "text-button-sm font-bold uppercase",
        "bg-legacyColor-semantic-success-100"
      )}
    >
      Public Holiday
    </div>
  );
};
