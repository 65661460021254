import clsx from "clsx";
import { gaClickTrack } from "helpers/ga_event_tracking";
import * as React from "react";
import SVG from "react-inlinesvg";

import DownArrow from "@assets/icons/down.svg";
import { Attendance as AttendanceType } from "@graphql/client_app/types";

import {
  getBreakTimes,
  getDailyTotal,
} from "@/client_app/features/timesheetApproval/utils/operations";
import { Checkbox } from "@/ui/Checkbox";

interface FlaggedAttendancesGroupListItemProps {
  attendance: AttendanceType;
  attendancesCount: number;
  stackOrder: number;
  isGroupExpanded: boolean;
  isMobile: boolean;
  minimumEngagementRequired: boolean;
  belowMinimumEngagementReasonToHuman: string;
  minimumEngagementDurationInseconds: number;
  humanReadableMinimumEngagementDuration: string;
  isResubmitted: boolean;
  workedThroughBreak: boolean;
  hasPublicHoliday: boolean;
  onExpandList: (event: React.MouseEvent) => void;
  onAttendanceToggle: (attendanceId: string) => void;
  checkedAttendances: string[];
}

const chipClassName =
  "inline-block rounded-full text-button-sm font-bold uppercase py-1 px-3";

export const minimumEngagementChip = (
  <div className={clsx(chipClassName, "bg-error-100 text-error-600")}>
    Minimum Engagement
  </div>
);

export const updatedTimesheetChip = (
  <div className={clsx(chipClassName, "bg-warning-100 text-warning-600")}>
    Updated Timesheet
  </div>
);

export const publicHolidayChip = (
  <div className={clsx(chipClassName, "bg-legacyColor-semantic-success-100")}>
    Public Holiday
  </div>
);

export const workedThroughBreakChip = (
  <div className={clsx(chipClassName, "bg-error-200 text-error-600")}>
    Worked Though Break
  </div>
);

function renderChips({
  minimumEngagementRequired,
  hasPublicHoliday,
  isResubmitted,
  workedThroughBreak,
}: {
  minimumEngagementRequired: boolean;
  hasPublicHoliday: boolean;
  isResubmitted: boolean;
  workedThroughBreak: boolean;
}): JSX.Element {
  return (
    <>
      {minimumEngagementRequired && (
        <div className='mb-1'>{minimumEngagementChip}</div>
      )}
      {hasPublicHoliday && <div className='mb-1'>{publicHolidayChip}</div>}
      {isResubmitted && <div className='mb-1'>{updatedTimesheetChip}</div>}
      {workedThroughBreak && (
        <div className='mb-1'>{workedThroughBreakChip}</div>
      )}
    </>
  );
}

export const FlaggedAttendancesGroupListItem = React.forwardRef(
  (
    {
      attendance,
      attendancesCount,
      stackOrder,
      isGroupExpanded,
      isMobile,
      minimumEngagementRequired,
      belowMinimumEngagementReasonToHuman,
      humanReadableMinimumEngagementDuration,
      isResubmitted,
      hasPublicHoliday,
      workedThroughBreak,
      onExpandList,
      onAttendanceToggle,
      checkedAttendances,
    }: FlaggedAttendancesGroupListItemProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const cellClassNames = "col-span-full md:col-span-2 md:text-center";
    const style = {
      transform: isGroupExpanded
        ? "translateY(0%)"
        : `translateY(${(stackOrder - 1) * -100}%)`,
    };

    function gaEventTracking(clickText: string) {
      gaClickTrack({
        click_text: clickText,
      });
    }

    function handleViewAllShifts(event: React.MouseEvent): void {
      onExpandList(event);
      gaEventTracking("VIEW ALL SHIFTS");
    }

    return (
      <div
        ref={ref}
        style={style}
        className={clsx(
          "border-neutral-300 border-2 rounded-xl bg-shade-50",
          "text-neutral-700 text-left",
          "relative origin-top-left transition-all",
          {
            "mb-2 md:mb-4 last:mb-0": isGroupExpanded,
            "z-50": stackOrder === 1,
            "opacity-0 z-[-999]": stackOrder > 1 && !isGroupExpanded,
            "opacity-1": isGroupExpanded,
          }
        )}
      >
        <div className='h-full grid grid-cols-11 items-center p-4 md:px-6 md:py-4 gap-2 xl:gap-x-2.5 2xl:gap-x-6'>
          <div className='col-span-full justify-self-end md:justify-self-auto -mt-11 md:mt-0 md:col-span-1'>
            {isGroupExpanded && (
              <Checkbox
                checked={checkedAttendances.includes(attendance.id)}
                onChange={() => {
                  onAttendanceToggle(attendance.id);
                }}
              />
            )}
          </div>
          {isMobile && (
            <div className='col-span-full'>
              {renderChips({
                minimumEngagementRequired,
                hasPublicHoliday,
                isResubmitted,
                workedThroughBreak,
              })}
            </div>
          )}
          <div className='col-span-full text-button-md text-primary-900 font-bold order-first md:text-neutral-700 md:font-semibold md:col-span-2 md:order-none'>
            {attendance.workedOnDate}
          </div>
          <div className={cellClassNames}>
            <span className='md:hidden font-semibold'>Hours: </span>
            <span className='font-normal md:font-semibold'>
              {`${attendance.startTime} - ${attendance.finishTime}`}
            </span>
          </div>
          <div className={cellClassNames}>
            <span className='md:hidden font-semibold'>Breaks: </span>
            <span className='font-normal md:font-semibold'>
              {getBreakTimes(attendance.breaks)}
            </span>
          </div>
          <div className={cellClassNames}>
            <span className='md:hidden font-semibold'>Daily Total: </span>
            <span
              className={clsx(
                "font-normal md:font-semibold",
                minimumEngagementRequired && "text-error-600"
              )}
            >
              {getDailyTotal(attendance.duration, attendance.breaks)}
            </span>
          </div>
          {isMobile && !!humanReadableMinimumEngagementDuration && (
            <div className={cellClassNames}>
              <span className='md:hidden font-semibold'>
                Minimum Engagement Hours:{" "}
              </span>
              <span className='font-normal md:font-semibold text-error-600'>
                {humanReadableMinimumEngagementDuration}
              </span>
            </div>
          )}
          <div className='col-span-2 hidden md:block'>
            {attendancesCount > 1 && !isGroupExpanded && (
              <button
                className='flex font-semibold text-primary-900'
                onClick={handleViewAllShifts}
              >
                <span className='flex items-center'>
                  VIEW ALL SHIFTS
                  <SVG
                    src={DownArrow}
                    className='icon fill-current mr-4 cursor-pointer'
                  />
                </span>
              </button>
            )}
          </div>
        </div>
        {(minimumEngagementRequired ||
          hasPublicHoliday ||
          isResubmitted ||
          workedThroughBreak ||
          !!attendance.additionalNotes) && (
          <>
            <hr className='border-neutral-100 hidden md:block md:mx-6' />
            <div className='p-4 md:px-6 md:py-4'>
              {minimumEngagementRequired && !isMobile && (
                <div className='grid grid-cols-11 gap-2 xl:gap-x-2.5 2xl:gap-x-6 mb-2'>
                  <div className='col-span-7'>
                    <div className='paragraph-1'>
                      {minimumEngagementChip}
                      <div>
                        <strong className='font-semibold'>
                          Minimum engagment reason:{" "}
                        </strong>
                        {belowMinimumEngagementReasonToHuman}
                      </div>
                    </div>
                  </div>
                  <div className='col-span-2 text-center font-normal text-error-600 md:font-semibold'>
                    {humanReadableMinimumEngagementDuration}
                  </div>
                </div>
              )}
              {hasPublicHoliday && !isMobile && (
                <div className='mb-2'>{publicHolidayChip}</div>
              )}
              {isResubmitted && !isMobile && (
                <div className='mb-2'>{updatedTimesheetChip}</div>
              )}
              {workedThroughBreak && !isMobile && (
                <div className='mb-2'>{workedThroughBreakChip}</div>
              )}
              {minimumEngagementRequired && isMobile && (
                <div className='paragraph-1 mb-2'>
                  <strong className='font-semibold'>
                    Minimum engagment reason:{" "}
                  </strong>
                  {belowMinimumEngagementReasonToHuman}
                </div>
              )}
              {!!attendance.additionalNotes && (
                <div className='paragraph-1 mb-2'>
                  <span className='font-semibold'>Additional Notes:</span>{" "}
                  <span>{attendance.additionalNotes}</span>{" "}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
);
