import clsx from "clsx";

import { MinimumEngagementDuration } from "@graphql/client_app/types";

import { MinimumEngagementChip } from "./MinimumEngagementChip";
import { PublicHolidayChip } from "./PublicHolidayChip";
import { UpdatedTimesheetChip } from "./UpdatedTimesheetChip";

import { useMediaQuery } from "@/client_app/hooks/useMediaQuery";

interface ChangeRequestPreviewAttendanceItemProps {
  workedOnDate: string;
  hoursFromTo: string;
  breaks: string[];
  formattedDuration: string;
  additionalNotes: string;
  minimumEngagementRequired: boolean;
  minimumEngagementDuration: MinimumEngagementDuration;
  belowMinimumEngagementReasonToHuman: string;
  hasPublicHoliday: boolean;
  isResubmitted: boolean;
}

function ChangeRequestPreviewAttendanceItem({
  workedOnDate,
  hoursFromTo,
  breaks,
  formattedDuration,
  additionalNotes,
  minimumEngagementRequired,
  minimumEngagementDuration,
  belowMinimumEngagementReasonToHuman,
  hasPublicHoliday,
  isResubmitted,
}: ChangeRequestPreviewAttendanceItemProps): JSX.Element {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const cellClassNames = "col-span-full md:col-span-1 text-left md:text-center";

  return (
    <div
      className={clsx(
        "border-neutral-300 border-2 rounded-xl bg-shade-50",
        "text-neutral-700 font-semibold",
        "p-2"
      )}
    >
      <div className='h-full grid grid-cols-4 items-center p-4 gap-2 md:p-6 xl:gap-x-2.5 2xl:gap-x-6'>
        {isMobile && (
          <div className='col-span-full'>
            {minimumEngagementRequired && (
              <div className='mb-1'>
                <MinimumEngagementChip />
              </div>
            )}
            {hasPublicHoliday && (
              <div className='mb-1'>
                <PublicHolidayChip />
              </div>
            )}
            {isResubmitted && (
              <div className='mb-1'>
                <UpdatedTimesheetChip />
              </div>
            )}
          </div>
        )}
        <div
          className={clsx(
            cellClassNames,
            "text-button-md text-primary-900 font-bold order-first md:text-neutral-700 md:font-semibold md:order-none"
          )}
        >
          {workedOnDate}
        </div>
        <div className={cellClassNames}>
          <span className='md:hidden'>Hours: </span>
          <span className='font-normal md:font-semibold'>{hoursFromTo}</span>
        </div>
        <div className={clsx(cellClassNames, "flex gap-1 md:block")}>
          <span className='md:hidden'>Breaks: </span>
          <span className='font-normal md:font-semibold'>
            {breaks.map((breakTime, index) => (
              <div key={index}>{breakTime}</div>
            ))}
          </span>
        </div>
        <div className={cellClassNames}>
          <span className='md:hidden'>Daily Total: </span>
          <span
            className={clsx(
              "font-normal md:font-semibold",
              minimumEngagementRequired && "text-error-600"
            )}
          >
            {formattedDuration}
          </span>
        </div>
        {isMobile && !!minimumEngagementDuration.formatted && (
          <div className={cellClassNames}>
            <span className='md:hidden font-semibold'>
              Minimum Engagement Hours:{" "}
            </span>
            <span className='font-normal md:font-semibold text-error-600'>
              {minimumEngagementDuration.formatted}
            </span>
          </div>
        )}
      </div>
      {(minimumEngagementRequired ||
        hasPublicHoliday ||
        isResubmitted ||
        !!additionalNotes) && (
        <>
          <hr className='border-neutral-100 hidden md:block md:mx-6' />
          <div className='p-4 md:px-6 md:py-4'>
            {minimumEngagementRequired && !isMobile && (
              <div className='grid grid-cols-4 gap-2 xl:gap-x-2.5 2xl:gap-x-6 mb-2'>
                <div className='col-span-3'>
                  <div className='paragraph-1'>
                    <MinimumEngagementChip />
                    <div>
                      <strong className='font-semibold'>
                        Minimum engagment reason:{" "}
                      </strong>
                      {belowMinimumEngagementReasonToHuman}
                    </div>
                  </div>
                </div>
                <div className='text-center font-normal text-error-600 md:font-semibold'>
                  {minimumEngagementDuration.formatted}
                </div>
              </div>
            )}
            {hasPublicHoliday && !isMobile && (
              <div className='mb-2'>
                <PublicHolidayChip />
              </div>
            )}
            {isResubmitted && !isMobile && (
              <div className='mb-2'>
                <UpdatedTimesheetChip />
              </div>
            )}
            {minimumEngagementRequired && isMobile && (
              <div className='paragraph-1 mb-2'>
                <strong className='font-semibold'>
                  Minimum engagment reason:{" "}
                </strong>
                {belowMinimumEngagementReasonToHuman}
              </div>
            )}
            {!!additionalNotes && (
              <div className='paragraph-1 mb-2'>
                <span className='font-semibold'>Additional Notes:</span>{" "}
                <span>{additionalNotes}</span>{" "}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export { ChangeRequestPreviewAttendanceItem };
