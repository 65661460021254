import { useFormContext } from "react-hook-form";

interface InputProps {
  onChange?: (event: InputEvent) => void;
  defaultValue?: string;
  name: string;
  type?: string;
  id?: string;
  placeholder?: string;
}

export const Input = ({
  defaultValue = "",
  type = "text",
  id = "",
  placeholder = "",
  name,
  onChange,
}: InputProps): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={errors[name] && "field_with_errors"}>
      <input
        defaultValue={defaultValue}
        id={id || name}
        className='form-control'
        type={type}
        placeholder={placeholder}
        {...register(name, { onChange: onChange })}
      />
    </div>
  );
};
