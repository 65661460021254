import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["navigation"];

  declare navigationTarget: HTMLInputElement;

  disconnect(): void {
    this.shrink();
  }
  openMobile() {
    this.navigationTarget.classList.remove("main-nav-inactive", "md:hidden");
    this.navigationTarget.classList.add("main-nav-active");
  }

  closeMobile(event) {
    event.stopPropagation();

    this.navigationTarget.classList.remove("main-nav-active");
    this.navigationTarget.classList.add("main-nav-inactive", "md:hidden");
  }

  shrink() {
    const pageContentBody = document.getElementById("page-content-body");

    this.navigationTarget.classList.add("is-shrink");
    pageContentBody.classList.remove("w-screen");
  }

  show() {
    const pageContentBody = document.getElementById("page-content-body");

    this.navigationTarget.classList.remove("is-shrink");
    pageContentBody.classList.add("w-screen");
  }
}
