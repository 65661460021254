import { gaClickTrack } from "helpers/ga_event_tracking";
import { tenantPath } from "helpers/tenant_path";
import { pick, first } from "lodash";
import { useState, Dispatch } from "react";

import graphqlClient from "@graphql/client_app/client";
import { useFetchSubmittedAttendancesQuery } from "@graphql/client_app/types";

import { SUBMITTED_ATTENDANCES_REPORT_PATH } from "@/client_app/config";

import { ApproveAttendancesPreview } from "./ApproveAttendancesPreview";
import { ChangeRequestPreview } from "./ChangeRequestPreview";
import { NoAttendancesMessage } from "./NoAttendancesMessage";
import { SubmittedAttendancesGroupContainer } from "./SubmittedAttendancesGroupContainer";
import { SubmittedAttendancesGroupHeading } from "./SubmittedAttendancesGroupHeading";

import {
  getTotalHoursAndMinutesWorked,
  groupAttendancesByPosition,
  groupAttendancesIdsByPosition,
  SelectionActionType,
  SelectionReducerAction,
} from "@/client_app/features/timesheetApproval/utils/operations";
import { PrimaryButton, SecondaryButton, DownloadButton } from "@/ui/buttons";
import { Checkbox } from "@/ui/Checkbox";

interface SubmittedAttendancesContainerProps {
  communityMemberName: string;
  managerId: string;
  selectedAttendancesIds: string[];
  allSelectedDispatch: Dispatch<SelectionReducerAction>;
  refetchAllSubmittedAttendances: () => void;
  noTimesheetsImageUrl: string;
}

export type ActionsType = "APPROVE" | "CHANGE_REQUEST" | null;

export const SubmittedAttendancesContainer = ({
  communityMemberName,
  managerId,
  selectedAttendancesIds,
  allSelectedDispatch,
  refetchAllSubmittedAttendances,
  noTimesheetsImageUrl,
}: SubmittedAttendancesContainerProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [action, setAction] = useState<ActionsType>(null);

  const { isFetching, data, error, refetch } =
    useFetchSubmittedAttendancesQuery(graphqlClient, {
      communityMemberName,
      managerId,
    });

  if (error) {
    return <div>Error!</div>;
  }

  if (isFetching) {
    const screenHeight = screen.height;
    const loaderStyle = { height: `${screenHeight}px` };
    return <div style={loaderStyle}>Loading...</div>;
  }

  const groupedAttendancesByPosition = groupAttendancesByPosition(
    data.submittedAttendances
  );

  const selectedAttendances = data.submittedAttendances.filter((att) => {
    return selectedAttendancesIds.includes(att.id);
  });

  const groupedSelectedAttendancesIdsByPosition =
    groupAttendancesIdsByPosition(selectedAttendances);

  const disableChangeRequestButton =
    selectedAttendancesIds.length === 0 ||
    selectedAttendances.some(
      (attendance) =>
        attendance.workedBy.id !== first(selectedAttendances).workedBy.id
    );

  function renderAttendances(): JSX.Element {
    return (
      <>
        <SubmittedAttendancesGroupHeading />
        {Object.entries(groupedAttendancesByPosition).map(
          ([positionId, attendances]): JSX.Element => {
            const {
              position: { title },
              workedBy: { profileImageUrl, fullName },
            } = attendances[0];

            return (
              <SubmittedAttendancesGroupContainer
                positionTitle={title}
                profileImageUrl={profileImageUrl}
                fullName={fullName}
                attendances={attendances}
                totalHoursWorked={getTotalHoursAndMinutesWorked(attendances)}
                key={positionId}
                allSelectedDispatch={allSelectedDispatch}
                selectedAttendancesIds={
                  groupedSelectedAttendancesIdsByPosition[positionId]
                }
              />
            );
          }
        )}
      </>
    );
  }

  const handleApproval = () => {
    setIsModalOpen(true);
    setAction("APPROVE");
  };

  const handleChangeRequest = () => {
    setIsModalOpen(true);
    setAction("CHANGE_REQUEST");
  };

  const handleBack = () => setIsModalOpen(false);

  function handleFormSubmissionSuccess() {
    setIsModalOpen(false);
    allSelectedDispatch({ type: SelectionActionType.REMOVE_ALL_ATTENDANCES });
    refetch();
    refetchAllSubmittedAttendances();
  }

  const handleSelectAllToggle = (): void => {
    if (selectedAttendancesIds.length < data.submittedAttendances.length) {
      const idsToAdd = data.submittedAttendances.map((att) => {
        return att.id;
      });
      allSelectedDispatch({
        type: SelectionActionType.ADD_ALL_ATTENDANCES,
        attendanceIds: idsToAdd,
      });

      gaClickCheckboxEventTracking(SelectionActionType.ADD_ALL_ATTENDANCES);
    } else {
      allSelectedDispatch({
        type: SelectionActionType.REMOVE_ALL_ATTENDANCES,
      });

      gaClickCheckboxEventTracking(SelectionActionType.REMOVE_ALL_ATTENDANCES);
    }
  };

  const handleDownloadCSV = () => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    fetch(tenantPath(SUBMITTED_ATTENDANCES_REPORT_PATH), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({ attendance_ids: selectedAttendancesIds }),
    }).then((response) => {
      if (response.ok) {
        response.blob().then((body) => {
          const csvData = new Blob([body], { type: "text/csv" });
          const a = document.createElement("a");
          document.body.appendChild(a);
          const csvUrl = URL.createObjectURL(csvData);
          a.href = csvUrl;
          a.download = `selected_attendances_report_${new Date().toISOString()}.csv`;
          a.click();
          URL.revokeObjectURL(a.href);
          a.remove();
        });
      }
    });

    gaClickEventTracking("DOWNLOAD CSV");
  };

  const gaClickEventTracking = (text: string) => {
    gaClickTrack({
      click_text: text,
    });
  };

  const gaClickCheckboxEventTracking = (checkedValue: string) => {
    gaClickTrack({
      checkbox_label: "Select All Submitted Weployees",
      checkbox_name: "selectAllSubmittedWeployees",
      checked: checkedValue,
    });
  };

  {
    return (
      <>
        {isModalOpen && action === "APPROVE" && (
          <ApproveAttendancesPreview
            isOpen={isModalOpen}
            onBack={handleBack}
            attendanceIds={selectedAttendancesIds}
          />
        )}
        {isModalOpen && action === "CHANGE_REQUEST" && (
          <ChangeRequestPreview
            isOpen={isModalOpen}
            onBack={handleBack}
            onFormSubmissionSuccess={handleFormSubmissionSuccess}
            communityMember={pick(selectedAttendances[0].workedBy, [
              "fullName",
              "profileImageUrl",
            ])}
            attendances={selectedAttendances.map((attendance) =>
              pick(attendance, [
                "id",
                "additionalNotes",
                "belowMinimumEngagementReasonToHuman",
                "breaks",
                "duration",
                "finishTime",
                "hasPublicHoliday",
                "minimumEngagementDuration",
                "minimumEngagementRequired",
                "resubmitted",
                "startTime",
                "workedOnDate",
              ])
            )}
          />
        )}
        <div className='my-8'>
          <div className='flex flex-col md:items-center md:flex-row'>
            <div className='flex items-center'>
              <Checkbox
                checked={
                  data.submittedAttendances.length ===
                    selectedAttendancesIds.length &&
                  data.submittedAttendances.length !== 0
                }
                disabled={data.submittedAttendances.length === 0}
                onChange={handleSelectAllToggle}
              />
              <p className='m-2 text-secondary-700 font-semibold'>
                {selectedAttendances.length} Selected
              </p>
              <p className='m-2 text-neutral-800 font-semibold'>
                Total hours selected:{" "}
                {getTotalHoursAndMinutesWorked(selectedAttendances)}
              </p>
            </div>
            <div className='flex gap-2 mt-3 md:mt-0 flex-wrap'>
              <PrimaryButton
                disabled={selectedAttendancesIds.length === 0}
                label='APPROVE'
                size='SMALL'
                onClick={handleApproval}
              />
              <SecondaryButton
                disabled={disableChangeRequestButton}
                label='Request Changes'
                size='SMALL'
                onClick={handleChangeRequest}
              />
              <DownloadButton
                label='DOWNLOAD CSV'
                size='SMALL'
                disabled={selectedAttendancesIds.length === 0}
                onClick={handleDownloadCSV}
              />
            </div>
          </div>
          <div className='my-10'>
            {data.submittedAttendances.length > 0 ? (
              renderAttendances()
            ) : (
              <NoAttendancesMessage
                noSearchResults={!!communityMemberName}
                noTimesheetsImageUrl={noTimesheetsImageUrl}
              />
            )}
          </div>
        </div>
      </>
    );
  }
};
