import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "checkbox"];

  declare formTargets: HTMLFormElement[];
  declare checkboxTargets: [HTMLInputElement];

  setFormsParams(): void {
    this.formTargets.forEach((form) => {
      const candidateIdsInput = form.querySelector(
        "[name='job_opening_candidate_ids']"
      ) as HTMLInputElement;
      candidateIdsInput.value = this.#selectIds().join(",");
    });
  }

  #selectIds(): string[] {
    return this.checkboxTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);
  }
}
