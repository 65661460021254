import { useEffect } from "react";

export const usePageTitle = (title: string, titlePage: string = null): void => {
  const pageTitle = [titlePage || "WeployCX", title]
    .filter(Boolean)
    .join(" - ");
  useEffect(() => {
    document.title = pageTitle;
  }, [title]);
};
