import { format } from "date-fns";
import pluralize from "pluralize";

import { AttendanceCostItem as AttendanceCostItemType } from "@graphql/organization_app/types";

import { PreviewInvoicesSummaryRow } from "./PreviewInvoicesSummaryRow";

import { formatCurrency } from "@/organization_app/features/invoicings/utils/CurrencyFormatterUtils";

interface InvoicesTableProps {
  attendanceCostItems: AttendanceCostItemType[];
  totalHoursWorked: number;
  paymentTermSurcharge: number;
  grandTotal: number;
  removeAttendanceCostItem: (id: string) => void;
}

export const PreviewInvoicesTable = ({
  attendanceCostItems,
  totalHoursWorked,
  paymentTermSurcharge,
  grandTotal,
  removeAttendanceCostItem,
}: InvoicesTableProps): JSX.Element => {
  const humanizeHoursWorked = (hoursWorked: number): string => {
    const hours = Math.floor(hoursWorked);
    const minutes = Math.floor(hoursWorked * 60) % 60;

    const humanizeHours = pluralize("hr", hours, true);
    const humanizeMinutes = pluralize("min", minutes, true);
    return `${humanizeHours} ${minutes ? humanizeMinutes : ""}`;
  };

  const getDateFromStartTime = (startTime: string): string => {
    return format(new Date(startTime), "yyyy-MM-dd");
  };

  const handleRemove = (attendanceId: string) => {
    if (window.confirm("Are you sure you want to remove the attendance?")) {
      removeAttendanceCostItem(attendanceId);
    }
  };

  return (
    <table className='table-list'>
      <thead>
        <tr>
          <th>{window.terminologyEmployee}</th>
          <th>WEPLOYEER</th>
          <th>JOB</th>
          <th>ATTENDANCE DATE</th>
          <th>HOURS</th>
          <th>TOTAL COST</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {attendanceCostItems.map((attendanceCostItem) => (
          <tr key={attendanceCostItem.attendanceId}>
            <td>{attendanceCostItem.weployeeName}</td>
            <td>{attendanceCostItem.weployerName}</td>
            <td>{attendanceCostItem.positionTitle}</td>
            <td>{getDateFromStartTime(attendanceCostItem.startTime)}</td>
            <td>{humanizeHoursWorked(attendanceCostItem.hoursWorked)}</td>
            <td>{formatCurrency(attendanceCostItem.cost)}</td>
            <td className='text-right'>
              <label
                className='font-bold active:text-legacyColor-semantic-alert-200'
                onClick={() => {
                  handleRemove(attendanceCostItem.attendanceId);
                }}
              >
                Remove
              </label>
            </td>
          </tr>
        ))}
        <PreviewInvoicesSummaryRow
          totalHoursWorked={humanizeHoursWorked(totalHoursWorked)}
          totalPaymentTermSurcharge={paymentTermSurcharge}
          totalCost={grandTotal}
        />
      </tbody>
    </table>
  );
};
