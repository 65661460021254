import clsx from "clsx";
import { NavLink } from "react-router-dom";

interface AttendancesApprovalTabProps {
  to: string;
  count: number;
  label: string;
}

function AttendancesApprovalTab({
  to,
  count,
  label,
}: AttendancesApprovalTabProps): JSX.Element {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        clsx(
          "flex gap-1 items-center font-bold uppercase border-b-2 pb-1",
          isActive ? "border-primary-300" : "border-transparent"
        )
      }
    >
      {({ isActive }) => (
        <>
          <div
            className={clsx(
              "px-2 font-bold inline-block rounded-full",
              isActive
                ? "text-secondary-700 bg-primary-50"
                : "text-neutral-500 bg-neutral-100"
            )}
          >
            {count}
          </div>
          <div
            className={clsx(
              isActive ? "text-secondary-700" : "text-neutral-500"
            )}
          >
            {label}
          </div>
        </>
      )}
    </NavLink>
  );
}

export { AttendancesApprovalTab };
