import { tenantPath } from "helpers/tenant_path";
import { useEffect } from "react";
import SVG from "react-inlinesvg";
import { useLocation, useNavigate } from "react-router-dom";

import approvedImageUrl from "@assets/illustrations/timesheet_approved_image.svg";

import { ATTENDANCES_APPROVAL_PATH } from "@/client_app/config";

import { COMPONENT_NAME as ApproveAttendancesPreviewComponentName } from "./ApproveAttendancesPreview";
import { FullLayoutModal } from "./FullLayoutModal";

const ApprovalFailure = (): JSX.Element => {
  const navigate = useNavigate();

  type locationStateType = {
    from: string;
  };

  const location = useLocation();

  const handleBack = () => {
    navigate(tenantPath(ATTENDANCES_APPROVAL_PATH), {
      state: { from: ApprovalFailure.name },
    });
  };

  useEffect(() => {
    if (!location.state) return handleBack();
    const { from } = location.state as locationStateType;

    if (from !== ApproveAttendancesPreviewComponentName) {
      handleBack();
    }
  });

  return (
    <FullLayoutModal
      isOpen={true}
      title='Back to Timesheets'
      closeModal={handleBack}
    >
      <div className='flex flex-col items-center mt-3.5 md:mt-10'>
        <h1 className='h4-sm font-bold text-shade-100 md:h1'>
          Timesheet were not Approved
        </h1>
        <h4 className='p1 mt-2 mb-2 md:mb-16 md:mt-6 text-shade-100 md:h4 w-4/5'>
          No timesheets have been approved at this time. Some timesheets may not
          be up to date with the latest information. Try reloading the timesheet
          page and approving again, otherwise, contact your account manager.
        </h4>
        <button
          className='bg-shade-100 text-shade-50 btn-md'
          onClick={handleBack}
        >
          RETURN TO TIMESHEET
        </button>

        <div className='mt-6 w-4/5'>
          <SVG className='w-full' src={approvedImageUrl} />
        </div>
      </div>
    </FullLayoutModal>
  );
};

export { ApprovalFailure };
