import { Controller } from "stimulus";

export default class extends Controller {
  blankOptionColor = "selected-blank";

  connect(): void {
    this.toggleBlankValueStyle();
  }

  toggleBlankValueStyle(): void {
    const target = this.element as HTMLSelectElement;
    !target.value
      ? target.classList.add(this.blankOptionColor)
      : target.classList.remove(this.blankOptionColor);
  }
}
