import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "codeInput"];

  declare formTarget: HTMLFormElement;
  declare codeInputTarget: HTMLInputElement;

  NEXT_RANGE_KEY = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  ARROW_RIGHT_KEY = "ArrowRight";
  ARROW_LEFT_KEY = "ArrowLeft";
  BACKSPACE_KEY = "Backspace";

  connect() {
    const digitGroup = this.formTarget;
    const inputs = digitGroup.getElementsByClassName(
      "input-code-digit"
    ) as HTMLCollection;
    Array.from(inputs).forEach((input: HTMLInputElement) => {
      input.setAttribute("maxlength", "1");
      input.addEventListener("keyup", (e) => {
        if (e.key === this.BACKSPACE_KEY || e.key === this.ARROW_LEFT_KEY) {
          const prev = document.getElementById(
            input.dataset.previous
          ) as HTMLInputElement;

          if (prev) {
            prev.select();
          }
        } else if (
          this.NEXT_RANGE_KEY.includes(e.key) ||
          e.key === this.ARROW_RIGHT_KEY
        ) {
          const next = document.getElementById(
            input.dataset.next
          ) as HTMLInputElement;

          if (next) {
            next.select();
          } else {
            const inputNumbers = Array.from(
              this.formTarget.getElementsByClassName("input-code-digit")
            ).map((input: HTMLInputElement) => input.value);
            this.codeInputTarget.value = inputNumbers.join("");
            if (this.formTarget.dataset.autosubmit) {
              this.formTarget.submit();
            }
          }
        }
      });
    });
  }
}
