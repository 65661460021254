import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    getMessageUrl: String,
  };

  declare getMessageUrlValue: string;

  showMessage(e: React.FormEvent<HTMLFormElement>) {
    this.toggleSelectedMessageStyle(e);

    Rails.ajax({
      type: "get",
      url: this.getMessageUrlValue,
      dataType: "json",
      success: ({ replace_content }) => {
        const messageContainer = document.getElementById("messages_container");

        if (messageContainer) {
          document.getElementById("messages_container").innerHTML =
            replace_content.messages_container;
        }
      },
    });
  }

  toggleSelectedMessageStyle(e: React.FormEvent<HTMLFormElement>) {
    const activeElement =
      document.getElementsByClassName("message-row active")[0];
    if (activeElement) {
      activeElement.classList.remove("bg-neutral-200", "active");
    }

    e.currentTarget.classList.add("bg-neutral-200", "active");
  }
}
