import React from "react";

import { PrimaryButton } from "@/ui/buttons";

interface AttendanceSelectedProps {
  selectedAttendanceIds: string[];
  openModal: () => void;
}

export const AttendancesForInvoicingSelected = ({
  selectedAttendanceIds,
  openModal,
}: AttendanceSelectedProps): JSX.Element => {
  return (
    <div className='flex items-center p-4'>
      <p className='m-2 font-bold text-caption'>
        <span>{selectedAttendanceIds.length}</span> selected
      </p>
      <PrimaryButton
        label='Preview Invoice'
        disabled={selectedAttendanceIds.length === 0}
        onClick={openModal}
      />
    </div>
  );
};
