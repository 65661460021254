import CustomNestedForm from "../../../custom_nested_form_controller";

export default class extends CustomNestedForm {
  add(event) {
    super.add(event);
    this.reorderNestedFormIndex();
  }

  remove(event) {
    super.remove(event);
    this.reorderNestedFormIndex();
  }

  private reorderNestedFormIndex(): void {
    const childNodes = this.containerTarget.children;

    for (let i = 0; i < childNodes.length; ++i) {
      const indexElement = childNodes[i].querySelector('[data-target="index"]');
      indexElement.innerHTML = `#${i + 1}`;
    }
  }
}
