import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["template", "container"];
  declare templateTarget: HTMLTemplateElement;
  declare containerTarget: HTMLElement;

  add(event) {
    event.preventDefault();

    const template = this.templateTarget.innerHTML;
    const container = this.containerTarget;
    const newIndex = container.children.length;
    const newForm = template
      .replace(/NEW_RECORD/g, new Date().getTime().toString())
      .replace(/FORM_UI_INDEX/g, `${newIndex + 1}`);

    container.insertAdjacentHTML("beforeend", newForm);
  }

  remove(event) {
    event.preventDefault();

    const wrapper = event.target.closest(".nested-form-wrapper");
    wrapper.remove();
  }
}
