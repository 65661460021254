import { AppRoutes } from "./routes";

import { AppProvider } from "@/client_app/providers/app";

interface AppProps {
  backendContext: unknown;
}

export const App = ({ backendContext }: AppProps): JSX.Element => {
  return (
    <AppProvider backendContext={backendContext}>
      <AppRoutes />
    </AppProvider>
  );
};
