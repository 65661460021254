import { Controller } from "stimulus";

export default class extends Controller {
  absoluteValue(event: InputEvent): void {
    const target = event.target as HTMLInputElement;
    const value = Number(target.value);
    const newValue = Math.abs(value);
    target.value = newValue ? newValue.toString() : null;
  }
}
