import { useFormState } from "react-hook-form";

interface ErrorMessageProps {
  name: string;
}

export const ErrorMessage = ({ name }: ErrorMessageProps): JSX.Element => {
  const { errors } = useFormState();
  return (
    <>
      {errors[name] && (
        <div className='form-error first-letter:capitalize'>
          {errors[name].message}
        </div>
      )}
    </>
  );
};
