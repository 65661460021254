import Pluralize from "pluralize";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fileInput", "template", "filenamesList", "details"];

  declare fileInputTarget: HTMLInputElement;
  declare templateTarget: HTMLTemplateElement;
  declare filenamesListTarget: HTMLElement;
  declare detailsTarget: HTMLElement;

  connect(): void {
    this.detailsTarget.innerHTML = "No files chosen";
  }

  handleSelection(): void {
    const files = this.fileInputTarget.files;
    this.updateDetails(files.length);
    this.listFilenames(files);
  }

  private clearFileList(): void {
    this.filenamesListTarget.innerHTML = "";
  }

  private updateDetails(filesLength): void {
    if (filesLength === 0) {
      this.detailsTarget.innerHTML = "No files chosen";
    } else {
      this.detailsTarget.innerHTML = Pluralize("files", filesLength, true);
    }
  }

  private listFilenames(files): void {
    this.clearFileList();
    Array.from(files)
      .map(({ name }) => name)
      .sort()
      .forEach((filename) => {
        const listItem = this.getListItemElementFromTemplate();
        listItem.innerText = filename;
        if (this.getIconCheckCircle())
          listItem.prepend(this.getIconCheckCircle());
        if (this.getIconBin()) listItem.append(this.getIconBin());
        this.filenamesListTarget.appendChild(listItem);
      });
  }

  private getListItemElementFromTemplate(): HTMLElement {
    return this.templateTarget.content
      .querySelector("li")
      .cloneNode(true) as HTMLElement;
  }

  private getIconCheckCircle(): HTMLElement {
    return this.templateTarget.content
      .querySelector("svg.icon-check-circle")
      ?.cloneNode(true) as HTMLElement;
  }

  private getIconBin(): HTMLElement {
    return this.templateTarget.content
      .querySelector("a.link-icon-bin")
      ?.cloneNode(true) as HTMLElement;
  }
}
