import { CommunityMemberAvatar } from "@/client_app/components/CommunityMemberAvatar";

interface ChangeRequestCommunityMemberProfileProps {
  communityMemberName: string;
  profileImageUrl?: string;
}

function ChangeRequestCommunityMemberProfile({
  communityMemberName,
  profileImageUrl,
}: ChangeRequestCommunityMemberProfileProps): JSX.Element {
  return (
    <div className='flex items-center my-4'>
      <CommunityMemberAvatar
        profileImageUrl={profileImageUrl}
        name={communityMemberName}
        size='LARGE'
      />
      <h4 className='h4-sm font-bold text-secondary-900 pl-4'>
        Timesheets for {communityMemberName}
      </h4>
    </div>
  );
}

export { ChangeRequestCommunityMemberProfile };
