import * as React from "react";
import { Toaster } from "react-hot-toast";
import { QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";

import { queryClient } from "@/organization_app/lib/react-query";
import { BackendContextProvider } from "@/organization_app/stores/backend-context";

interface AppProviderProps {
  backendContext: unknown;
  children: React.ReactNode;
}

export const AppProvider = ({
  backendContext,
  children,
}: AppProviderProps): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <BackendContextProvider value={backendContext}>
        <Router>{children}</Router>
        <Toaster
          position='top-center'
          toastOptions={{
            duration: 5000,
          }}
        />
      </BackendContextProvider>
    </QueryClientProvider>
  );
};
