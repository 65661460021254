import { GraphQLClient } from "graphql-request";
import { RequestInit } from "graphql-request/dist/types.dom";
import {
  useMutation,
  useQuery,
  UseMutationOptions,
  UseQueryOptions,
} from "react-query";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};

function fetcher<TData, TVariables>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  headers?: RequestInit["headers"]
) {
  return async (): Promise<TData> =>
    client.request<TData, TVariables>(query, variables, headers);
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};

/** Input for approving attendances */
export type ApproveAttendancesInput = {
  approvalConsent: Scalars["Boolean"];
  attendanceIds: Array<Scalars["String"]>;
};

/** Autogenerated input type of ApproveAttendancesMutation */
export type ApproveAttendancesMutationInput = {
  attendancesToApprove: ApproveAttendancesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of ApproveAttendancesMutation. */
export type ApproveAttendancesMutationPayload = {
  __typename?: "ApproveAttendancesMutationPayload";
  /** Return an array of attendance IDs after approving */
  attendanceIds?: Maybe<Array<Scalars["String"]>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<FormError>;
};

export type Attendance = {
  __typename?: "Attendance";
  additionalNotes?: Maybe<Scalars["String"]>;
  approvedBy?: Maybe<ClientUser>;
  belowMinimumEngagementReasonToHuman?: Maybe<Scalars["String"]>;
  breaks?: Maybe<Array<Break>>;
  duration: Scalars["Int"];
  finishAt: Scalars["ISO8601DateTime"];
  finishTime: Scalars["String"];
  forReview: Scalars["Boolean"];
  hasPublicHoliday: Scalars["Boolean"];
  humanReadableMinimumEngagementDuration?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  minimumEngagementDuration: MinimumEngagementDuration;
  minimumEngagementRequired: Scalars["Boolean"];
  position: Position;
  resubmitted: Scalars["Boolean"];
  workedThroughBreak: Scalars["Boolean"];
  startAt: Scalars["ISO8601DateTime"];
  startTime: Scalars["String"];
  state: AttendanceStateEnum;
  timeZone: Scalars["String"];
  workedBy: CommunityMember;
  workedOnDate: Scalars["String"];
};

/** Status of an attendance in the approval process */
export enum AttendanceStateEnum {
  /** Attendance is approved by the client user */
  Approved = "approved",
  /** Attendance is requested to be changed */
  ChangesRequested = "changes_requested",
  /** Attendance is finalized and ready for pay run */
  PayRunFinalized = "pay_run_finalized",
  /** Attendance is resubmitted after being changed */
  Resubmitted = "resubmitted",
  /** Attendance is submitted the first time */
  Submitted = "submitted",
}

export type AttendancesCostSummary = {
  __typename?: "AttendancesCostSummary";
  casualLoadingFeeInAud: Scalars["String"];
  communityMembers: Array<CommunityMember>;
  grandTotalInAud: Scalars["String"];
  gstInAud: Scalars["String"];
  paymentTermSurchargeInAud: Scalars["String"];
  payrollComplianceFeeInAud: Scalars["String"];
  primaryRates: Array<WageBreakdown>;
  secondaryRates?: Maybe<Array<WageBreakdown>>;
  serviceFeeInAud: Scalars["String"];
  workedDates: Scalars["String"];
};

export type Break = {
  __typename?: "Break";
  duration: Scalars["Int"];
  finishTime: Scalars["String"];
  id: Scalars["ID"];
  startTime: Scalars["String"];
};

export type BuyoutRequest = {
  __typename?: "BuyoutRequest";
  acceptedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  additionalComments?: Maybe<Scalars["String"]>;
  clientUserId: Scalars["ID"];
  id: Scalars["ID"];
  isPending: Scalars["Boolean"];
  positionId: Scalars["ID"];
  rejectedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  startOn: Scalars["ISO8601DateTime"];
};

/** Input for creating a buyout request */
export type BuyoutRequestInput = {
  additionalComments: Scalars["String"];
  positionIds: Array<Scalars["String"]>;
  startOn: Scalars["String"];
};

/** Input for creating a change request */
export type ChangeRequestInput = {
  attendanceIds: Array<Scalars["String"]>;
  details: Scalars["String"];
  reason: Scalars["String"];
};

export type ChangeRequestReason = {
  __typename?: "ChangeRequestReason";
  label: Scalars["String"];
  value: Scalars["String"];
};

export type ClientCompanyUser = {
  __typename?: "ClientCompanyUser";
  fullName: Scalars["String"];
  id: Scalars["ID"];
};

export type ClientUser = {
  __typename?: "ClientUser";
  email: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["ID"];
  profileImageUrl?: Maybe<Scalars["String"]>;
};

export type CommunityMember = {
  __typename?: "CommunityMember";
  email: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["ID"];
  mobileNumber?: Maybe<Scalars["String"]>;
  preferredName: Scalars["String"];
  profileImageUrl?: Maybe<Scalars["String"]>;
  timeZone: Scalars["String"];
};

/** Autogenerated input type of CreateBuyoutRequestMutation */
export type CreateBuyoutRequestMutationInput = {
  buyoutRequests: BuyoutRequestInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of CreateBuyoutRequestMutation. */
export type CreateBuyoutRequestMutationPayload = {
  __typename?: "CreateBuyoutRequestMutationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<FormError>;
  /** Return an array of position IDs after creation */
  positionIds?: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of CreateChangeRequestMutation */
export type CreateChangeRequestMutationInput = {
  changeRequest: ChangeRequestInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of CreateChangeRequestMutation. */
export type CreateChangeRequestMutationPayload = {
  __typename?: "CreateChangeRequestMutationPayload";
  /** Return the attendance ID after creation */
  attendanceIds?: Maybe<Array<Scalars["String"]>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<FormError>;
};

/** Autogenerated input type of CreateExtensionRequestsMutation */
export type CreateExtensionRequestsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  extensionRequests: ExtensionRequestsInput;
};

/** Autogenerated return type of CreateExtensionRequestsMutation. */
export type CreateExtensionRequestsMutationPayload = {
  __typename?: "CreateExtensionRequestsMutationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<FormError>;
  /** Return an array of position IDs after creation */
  positionIds?: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated input type of CreateRehireRequestsMutation */
export type CreateRehireRequestsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  rehireRequests: RehireRequestsInput;
};

/** Autogenerated return type of CreateRehireRequestsMutation. */
export type CreateRehireRequestsMutationPayload = {
  __typename?: "CreateRehireRequestsMutationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Return an array of community_member IDs after creation */
  communityMemberIds?: Maybe<Array<Scalars["String"]>>;
  errors: Array<FormError>;
};

export type ExtensionRequest = {
  __typename?: "ExtensionRequest";
  acceptedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  additionalRejectionDetails?: Maybe<Scalars["String"]>;
  finishOn: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  isPending: Scalars["Boolean"];
  positionId: Scalars["ID"];
  reason: Scalars["String"];
  rejectedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  rejectionReason?: Maybe<Scalars["String"]>;
  startOn: Scalars["ISO8601DateTime"];
  timeRequirements?: Maybe<Scalars["String"]>;
};

export type ExtensionRequestReason = {
  __typename?: "ExtensionRequestReason";
  label: Scalars["String"];
  value: Scalars["String"];
};

/** Input for creating extension requests */
export type ExtensionRequestsInput = {
  newFinishDate: Scalars["String"];
  positionIds: Array<Scalars["String"]>;
  reason: Scalars["String"];
  timeRequirements: Scalars["String"];
};

/** A user-readable error */
export type FormError = {
  __typename?: "FormError";
  /** A description of the error */
  message: Scalars["String"];
  /** Which input value this error came from */
  path?: Maybe<Scalars["String"]>;
};

export type MinimumEngagementDuration = {
  __typename?: "MinimumEngagementDuration";
  /** Duration of applied ME in human readable formatt */
  formatted: Scalars["String"];
  /** Duration of applied ME time in seconds */
  valueInSeconds: Scalars["Int"];
};

/** The mutation root of client app */
export type Mutation = {
  __typename?: "Mutation";
  approveAttendances?: Maybe<ApproveAttendancesMutationPayload>;
  createBuyoutRequests?: Maybe<CreateBuyoutRequestMutationPayload>;
  createChangeRequest?: Maybe<CreateChangeRequestMutationPayload>;
  createExtensionRequests?: Maybe<CreateExtensionRequestsMutationPayload>;
  createRehireRequests?: Maybe<CreateRehireRequestsMutationPayload>;
};

/** The mutation root of client app */
export type MutationApproveAttendancesArgs = {
  input: ApproveAttendancesMutationInput;
};

/** The mutation root of client app */
export type MutationCreateBuyoutRequestsArgs = {
  input: CreateBuyoutRequestMutationInput;
};

/** The mutation root of client app */
export type MutationCreateChangeRequestArgs = {
  input: CreateChangeRequestMutationInput;
};

/** The mutation root of client app */
export type MutationCreateExtensionRequestsArgs = {
  input: CreateExtensionRequestsMutationInput;
};

/** The mutation root of client app */
export type MutationCreateRehireRequestsArgs = {
  input: CreateRehireRequestsMutationInput;
};

export type Position = {
  __typename?: "Position";
  buyoutRequests?: Maybe<Array<BuyoutRequest>>;
  communityMember: CommunityMember;
  extensionRequests?: Maybe<Array<ExtensionRequest>>;
  finishOn: Scalars["ISO8601Date"];
  id: Scalars["ID"];
  jobOpeningId?: Maybe<Scalars["String"]>;
  jobOpeningName?: Maybe<Scalars["String"]>;
  newJobAcceptance?: Maybe<Scalars["Boolean"]>;
  startOn: Scalars["ISO8601Date"];
  title: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  /** Fetch positions that have not ended */
  activePositions: Array<Position>;
  /** Fetch attendances cost summary */
  attendancesCostSummary: AttendancesCostSummary;
  /** Fetch change request reasons */
  changeRequestReasons: Array<ChangeRequestReason>;
  /** Fetch client company users */
  clientCompanyUsers: Array<ClientCompanyUser>;
  /** Fetch extension request reasons */
  extensionRequestReasons: Array<ExtensionRequestReason>;
  /** Get attendances that need to be reviewed */
  flaggedAttendances: Array<Attendance>;
  /** Fetch positions that have ended */
  pastPositions: Array<Position>;
  /** Fetch rehire request employment types */
  rehireRequestEmploymentTypes: Array<RehireRequestEmploymentType>;
  /** Get submitted attendances from either one or all community members */
  submittedAttendances: Array<Attendance>;
};

export type QueryActivePositionsArgs = {
  communityMemberName?: InputMaybe<Scalars["String"]>;
  managerId?: InputMaybe<Scalars["String"]>;
};

export type QueryAttendancesCostSummaryArgs = {
  attendanceIds: Array<Scalars["ID"]>;
};

export type QueryFlaggedAttendancesArgs = {
  communityMemberName?: InputMaybe<Scalars["String"]>;
  managerId?: InputMaybe<Scalars["ID"]>;
};

export type QueryPastPositionsArgs = {
  communityMemberName?: InputMaybe<Scalars["String"]>;
  managerId?: InputMaybe<Scalars["String"]>;
};

export type QuerySubmittedAttendancesArgs = {
  communityMemberName?: InputMaybe<Scalars["String"]>;
  managerId?: InputMaybe<Scalars["ID"]>;
};

export type RehireRequestEmploymentType = {
  __typename?: "RehireRequestEmploymentType";
  label: Scalars["String"];
  value: Scalars["String"];
};

/** Input for creating rehire requests */
export type RehireRequestsInput = {
  additionalComments?: InputMaybe<Scalars["String"]>;
  communityMemberIds: Array<Scalars["String"]>;
  employmentType: Scalars["String"];
  endDate?: InputMaybe<Scalars["String"]>;
  salary?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["String"]>;
};

export type WageBreakdown = {
  __typename?: "WageBreakdown";
  description: Scalars["String"];
  hoursWorked: Scalars["String"];
  wage: Scalars["String"];
};

export type ApproveAttendancesMutationVariables = Exact<{
  attendanceIds: Array<Scalars["String"]> | Scalars["String"];
  approvalConsent: Scalars["Boolean"];
}>;

export type ApproveAttendancesMutation = {
  __typename?: "Mutation";
  approveAttendances?: {
    __typename?: "ApproveAttendancesMutationPayload";
    attendanceIds?: Array<string> | null;
    errors: Array<{
      __typename?: "FormError";
      path?: string | null;
      message: string;
    }>;
  } | null;
};

export type CreateBuyoutRequestsMutationVariables = Exact<{
  positionIds: Array<Scalars["String"]> | Scalars["String"];
  startOn: Scalars["String"];
  additionalComments: Scalars["String"];
}>;

export type CreateBuyoutRequestsMutation = {
  __typename?: "Mutation";
  createBuyoutRequests?: {
    __typename?: "CreateBuyoutRequestMutationPayload";
    positionIds?: Array<string> | null;
    errors: Array<{
      __typename?: "FormError";
      path?: string | null;
      message: string;
    }>;
  } | null;
};

export type CreateChangeRequestMutationVariables = Exact<{
  attendanceIds: Array<Scalars["String"]> | Scalars["String"];
  reason: Scalars["String"];
  details: Scalars["String"];
}>;

export type CreateChangeRequestMutation = {
  __typename?: "Mutation";
  createChangeRequest?: {
    __typename?: "CreateChangeRequestMutationPayload";
    attendanceIds?: Array<string> | null;
    errors: Array<{
      __typename?: "FormError";
      path?: string | null;
      message: string;
    }>;
  } | null;
};

export type CreateExtensionRequestsMutationVariables = Exact<{
  positionIds: Array<Scalars["String"]> | Scalars["String"];
  reason: Scalars["String"];
  newFinishDate: Scalars["String"];
  timeRequirements: Scalars["String"];
}>;

export type CreateExtensionRequestsMutation = {
  __typename?: "Mutation";
  createExtensionRequests?: {
    __typename?: "CreateExtensionRequestsMutationPayload";
    positionIds?: Array<string> | null;
    errors: Array<{
      __typename?: "FormError";
      path?: string | null;
      message: string;
    }>;
  } | null;
};

export type CreateRehireRequestsMutationVariables = Exact<{
  communityMemberIds: Array<Scalars["String"]> | Scalars["String"];
  employmentType: Scalars["String"];
  startDate?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["String"]>;
  salary?: InputMaybe<Scalars["String"]>;
  additionalComments?: InputMaybe<Scalars["String"]>;
}>;

export type CreateRehireRequestsMutation = {
  __typename?: "Mutation";
  createRehireRequests?: {
    __typename?: "CreateRehireRequestsMutationPayload";
    communityMemberIds?: Array<string> | null;
    errors: Array<{
      __typename?: "FormError";
      path?: string | null;
      message: string;
    }>;
  } | null;
};

export type FetchAllActivePositionsQueryVariables = Exact<{
  communityMemberName?: InputMaybe<Scalars["String"]>;
  managerId?: InputMaybe<Scalars["String"]>;
}>;

export type FetchAllActivePositionsQuery = {
  __typename?: "Query";
  activePositions: Array<{
    __typename?: "Position";
    id: string;
    startOn: any;
    finishOn: any;
    title: string;
    newJobAcceptance?: boolean | null;
    jobOpeningId?: string | null;
    jobOpeningName?: string | null;
    communityMember: {
      __typename?: "CommunityMember";
      id: string;
      fullName: string;
      profileImageUrl?: string | null;
      mobileNumber?: string | null;
    };
    extensionRequests?: Array<{
      __typename?: "ExtensionRequest";
      isPending: boolean;
    }> | null;
    buyoutRequests?: Array<{
      __typename?: "BuyoutRequest";
      isPending: boolean;
    }> | null;
  }>;
};

export type FetchAllFlaggedAttendancesQueryVariables = Exact<{
  managerId?: InputMaybe<Scalars["ID"]>;
}>;

export type FetchAllFlaggedAttendancesQuery = {
  __typename?: "Query";
  flaggedAttendances: Array<{ __typename?: "Attendance"; id: string }>;
};

export type FetchAllPastPositionsQueryVariables = Exact<{
  communityMemberName?: InputMaybe<Scalars["String"]>;
  managerId?: InputMaybe<Scalars["String"]>;
}>;

export type FetchAllPastPositionsQuery = {
  __typename?: "Query";
  pastPositions: Array<{
    __typename?: "Position";
    id: string;
    startOn: any;
    finishOn: any;
    title: string;
    newJobAcceptance?: boolean | null;
    jobOpeningId?: string | null;
    jobOpeningName?: string | null;
    communityMember: {
      __typename?: "CommunityMember";
      id: string;
      fullName: string;
      profileImageUrl?: string | null;
      mobileNumber?: string | null;
    };
    extensionRequests?: Array<{
      __typename?: "ExtensionRequest";
      isPending: boolean;
    }> | null;
  }>;
};

export type FetchAllSubmittedAttendancesQueryVariables = Exact<{
  managerId?: InputMaybe<Scalars["ID"]>;
}>;

export type FetchAllSubmittedAttendancesQuery = {
  __typename?: "Query";
  submittedAttendances: Array<{ __typename?: "Attendance"; id: string }>;
};

export type FetchAttendancesCostSummaryQueryVariables = Exact<{
  attendanceIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type FetchAttendancesCostSummaryQuery = {
  __typename?: "Query";
  attendancesCostSummary: {
    __typename?: "AttendancesCostSummary";
    workedDates: string;
    payrollComplianceFeeInAud: string;
    casualLoadingFeeInAud: string;
    serviceFeeInAud: string;
    gstInAud: string;
    grandTotalInAud: string;
    paymentTermSurchargeInAud: string;
    communityMembers: Array<{
      __typename?: "CommunityMember";
      fullName: string;
      preferredName: string;
      profileImageUrl?: string | null;
    }>;
    primaryRates: Array<{
      __typename?: "WageBreakdown";
      description: string;
      hoursWorked: string;
      wage: string;
    }>;
    secondaryRates?: Array<{
      __typename?: "WageBreakdown";
      description: string;
      hoursWorked: string;
      wage: string;
    }> | null;
  };
};

export type FetchChangeRequestReasonsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchChangeRequestReasonsQuery = {
  __typename?: "Query";
  changeRequestReasons: Array<{
    __typename?: "ChangeRequestReason";
    value: string;
    label: string;
  }>;
};

export type FetchClientCompanyUsersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchClientCompanyUsersQuery = {
  __typename?: "Query";
  clientCompanyUsers: Array<{
    __typename?: "ClientCompanyUser";
    id: string;
    fullName: string;
  }>;
};

export type FetchExtensionRequestReasonsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchExtensionRequestReasonsQuery = {
  __typename?: "Query";
  extensionRequestReasons: Array<{
    __typename?: "ExtensionRequestReason";
    value: string;
    label: string;
  }>;
};

export type FetchFlaggedAttendancesQueryVariables = Exact<{
  communityMemberName?: InputMaybe<Scalars["String"]>;
  managerId?: InputMaybe<Scalars["ID"]>;
}>;

export type FetchFlaggedAttendancesQuery = {
  __typename?: "Query";
  flaggedAttendances: Array<{
    __typename?: "Attendance";
    id: string;
    state: AttendanceStateEnum;
    startAt: any;
    finishAt: any;
    workedOnDate: string;
    startTime: string;
    finishTime: string;
    timeZone: string;
    duration: number;
    minimumEngagementRequired: boolean;
    belowMinimumEngagementReasonToHuman?: string | null;
    hasPublicHoliday: boolean;
    resubmitted: boolean;
    workedThroughBreak: boolean;
    additionalNotes?: string | null;
    minimumEngagementDuration: {
      __typename?: "MinimumEngagementDuration";
      valueInSeconds: number;
      formatted: string;
    };
    workedBy: {
      __typename?: "CommunityMember";
      id: string;
      fullName: string;
      profileImageUrl?: string | null;
      preferredName: string;
    };
    position: { __typename?: "Position"; id: string; title: string };
    breaks?: Array<{
      __typename?: "Break";
      id: string;
      startTime: string;
      finishTime: string;
      duration: number;
    }> | null;
  }>;
};

export type FetchRehireRequestEmploymentTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchRehireRequestEmploymentTypesQuery = {
  __typename?: "Query";
  rehireRequestEmploymentTypes: Array<{
    __typename?: "RehireRequestEmploymentType";
    value: string;
    label: string;
  }>;
};

export type FetchSubmittedAttendancesQueryVariables = Exact<{
  communityMemberName?: InputMaybe<Scalars["String"]>;
  managerId?: InputMaybe<Scalars["ID"]>;
}>;

export type FetchSubmittedAttendancesQuery = {
  __typename?: "Query";
  submittedAttendances: Array<{
    __typename?: "Attendance";
    id: string;
    state: AttendanceStateEnum;
    startAt: any;
    finishAt: any;
    workedOnDate: string;
    startTime: string;
    finishTime: string;
    timeZone: string;
    duration: number;
    minimumEngagementRequired: boolean;
    belowMinimumEngagementReasonToHuman?: string | null;
    hasPublicHoliday: boolean;
    resubmitted: boolean;
    workedThroughBreak: boolean;
    additionalNotes?: string | null;
    minimumEngagementDuration: {
      __typename?: "MinimumEngagementDuration";
      valueInSeconds: number;
      formatted: string;
    };
    workedBy: {
      __typename?: "CommunityMember";
      id: string;
      fullName: string;
      profileImageUrl?: string | null;
      preferredName: string;
    };
    position: { __typename?: "Position"; id: string; title: string };
    breaks?: Array<{
      __typename?: "Break";
      id: string;
      startTime: string;
      finishTime: string;
      duration: number;
    }> | null;
  }>;
};

export const ApproveAttendancesDocument = `
    mutation approveAttendances($attendanceIds: [String!]!, $approvalConsent: Boolean!) {
  approveAttendances(
    input: {attendancesToApprove: {attendanceIds: $attendanceIds, approvalConsent: $approvalConsent}}
  ) {
    attendanceIds
    errors {
      path
      message
    }
  }
}
    `;
export const useApproveAttendancesMutation = <
  TError = unknown,
  TContext = unknown
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    ApproveAttendancesMutation,
    TError,
    ApproveAttendancesMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) =>
  useMutation<
    ApproveAttendancesMutation,
    TError,
    ApproveAttendancesMutationVariables,
    TContext
  >(
    ["approveAttendances"],
    (variables?: ApproveAttendancesMutationVariables) =>
      fetcher<ApproveAttendancesMutation, ApproveAttendancesMutationVariables>(
        client,
        ApproveAttendancesDocument,
        variables,
        headers
      )(),
    options
  );
export const CreateBuyoutRequestsDocument = `
    mutation createBuyoutRequests($positionIds: [String!]!, $startOn: String!, $additionalComments: String!) {
  createBuyoutRequests(
    input: {buyoutRequests: {positionIds: $positionIds, startOn: $startOn, additionalComments: $additionalComments}}
  ) {
    positionIds
    errors {
      path
      message
    }
  }
}
    `;
export const useCreateBuyoutRequestsMutation = <
  TError = unknown,
  TContext = unknown
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateBuyoutRequestsMutation,
    TError,
    CreateBuyoutRequestsMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) =>
  useMutation<
    CreateBuyoutRequestsMutation,
    TError,
    CreateBuyoutRequestsMutationVariables,
    TContext
  >(
    ["createBuyoutRequests"],
    (variables?: CreateBuyoutRequestsMutationVariables) =>
      fetcher<
        CreateBuyoutRequestsMutation,
        CreateBuyoutRequestsMutationVariables
      >(client, CreateBuyoutRequestsDocument, variables, headers)(),
    options
  );
export const CreateChangeRequestDocument = `
    mutation createChangeRequest($attendanceIds: [String!]!, $reason: String!, $details: String!) {
  createChangeRequest(
    input: {changeRequest: {attendanceIds: $attendanceIds, reason: $reason, details: $details}}
  ) {
    attendanceIds
    errors {
      path
      message
    }
  }
}
    `;
export const useCreateChangeRequestMutation = <
  TError = unknown,
  TContext = unknown
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateChangeRequestMutation,
    TError,
    CreateChangeRequestMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) =>
  useMutation<
    CreateChangeRequestMutation,
    TError,
    CreateChangeRequestMutationVariables,
    TContext
  >(
    ["createChangeRequest"],
    (variables?: CreateChangeRequestMutationVariables) =>
      fetcher<
        CreateChangeRequestMutation,
        CreateChangeRequestMutationVariables
      >(client, CreateChangeRequestDocument, variables, headers)(),
    options
  );
export const CreateExtensionRequestsDocument = `
    mutation createExtensionRequests($positionIds: [String!]!, $reason: String!, $newFinishDate: String!, $timeRequirements: String!) {
  createExtensionRequests(
    input: {extensionRequests: {positionIds: $positionIds, reason: $reason, newFinishDate: $newFinishDate, timeRequirements: $timeRequirements}}
  ) {
    positionIds
    errors {
      path
      message
    }
  }
}
    `;
export const useCreateExtensionRequestsMutation = <
  TError = unknown,
  TContext = unknown
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateExtensionRequestsMutation,
    TError,
    CreateExtensionRequestsMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) =>
  useMutation<
    CreateExtensionRequestsMutation,
    TError,
    CreateExtensionRequestsMutationVariables,
    TContext
  >(
    ["createExtensionRequests"],
    (variables?: CreateExtensionRequestsMutationVariables) =>
      fetcher<
        CreateExtensionRequestsMutation,
        CreateExtensionRequestsMutationVariables
      >(client, CreateExtensionRequestsDocument, variables, headers)(),
    options
  );
export const CreateRehireRequestsDocument = `
    mutation createRehireRequests($communityMemberIds: [String!]!, $employmentType: String!, $startDate: String, $endDate: String, $salary: String, $additionalComments: String) {
  createRehireRequests(
    input: {rehireRequests: {communityMemberIds: $communityMemberIds, employmentType: $employmentType, startDate: $startDate, endDate: $endDate, salary: $salary, additionalComments: $additionalComments}}
  ) {
    communityMemberIds
    errors {
      path
      message
    }
  }
}
    `;
export const useCreateRehireRequestsMutation = <
  TError = unknown,
  TContext = unknown
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateRehireRequestsMutation,
    TError,
    CreateRehireRequestsMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) =>
  useMutation<
    CreateRehireRequestsMutation,
    TError,
    CreateRehireRequestsMutationVariables,
    TContext
  >(
    ["createRehireRequests"],
    (variables?: CreateRehireRequestsMutationVariables) =>
      fetcher<
        CreateRehireRequestsMutation,
        CreateRehireRequestsMutationVariables
      >(client, CreateRehireRequestsDocument, variables, headers)(),
    options
  );
export const FetchAllActivePositionsDocument = `
    query fetchAllActivePositions($communityMemberName: String, $managerId: String) {
  activePositions(
    communityMemberName: $communityMemberName
    managerId: $managerId
  ) {
    id
    startOn
    finishOn
    title
    newJobAcceptance
    jobOpeningId
    jobOpeningName
    communityMember {
      id
      fullName
      profileImageUrl
      mobileNumber
    }
    extensionRequests {
      isPending
    }
    buyoutRequests {
      isPending
    }
  }
}
    `;
export const useFetchAllActivePositionsQuery = <
  TData = FetchAllActivePositionsQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: FetchAllActivePositionsQueryVariables,
  options?: UseQueryOptions<FetchAllActivePositionsQuery, TError, TData>,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchAllActivePositionsQuery, TError, TData>(
    variables === undefined
      ? ["fetchAllActivePositions"]
      : ["fetchAllActivePositions", variables],
    fetcher<
      FetchAllActivePositionsQuery,
      FetchAllActivePositionsQueryVariables
    >(client, FetchAllActivePositionsDocument, variables, headers),
    options
  );
export const FetchAllFlaggedAttendancesDocument = `
    query fetchAllFlaggedAttendances($managerId: ID) {
  flaggedAttendances(managerId: $managerId) {
    id
  }
}
    `;
export const useFetchAllFlaggedAttendancesQuery = <
  TData = FetchAllFlaggedAttendancesQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: FetchAllFlaggedAttendancesQueryVariables,
  options?: UseQueryOptions<FetchAllFlaggedAttendancesQuery, TError, TData>,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchAllFlaggedAttendancesQuery, TError, TData>(
    variables === undefined
      ? ["fetchAllFlaggedAttendances"]
      : ["fetchAllFlaggedAttendances", variables],
    fetcher<
      FetchAllFlaggedAttendancesQuery,
      FetchAllFlaggedAttendancesQueryVariables
    >(client, FetchAllFlaggedAttendancesDocument, variables, headers),
    options
  );
export const FetchAllPastPositionsDocument = `
    query fetchAllPastPositions($communityMemberName: String, $managerId: String) {
  pastPositions(communityMemberName: $communityMemberName, managerId: $managerId) {
    id
    startOn
    finishOn
    title
    newJobAcceptance
    jobOpeningId
    jobOpeningName
    communityMember {
      id
      fullName
      profileImageUrl
      mobileNumber
    }
    extensionRequests {
      isPending
    }
  }
}
    `;
export const useFetchAllPastPositionsQuery = <
  TData = FetchAllPastPositionsQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: FetchAllPastPositionsQueryVariables,
  options?: UseQueryOptions<FetchAllPastPositionsQuery, TError, TData>,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchAllPastPositionsQuery, TError, TData>(
    variables === undefined
      ? ["fetchAllPastPositions"]
      : ["fetchAllPastPositions", variables],
    fetcher<FetchAllPastPositionsQuery, FetchAllPastPositionsQueryVariables>(
      client,
      FetchAllPastPositionsDocument,
      variables,
      headers
    ),
    options
  );
export const FetchAllSubmittedAttendancesDocument = `
    query fetchAllSubmittedAttendances($managerId: ID) {
  submittedAttendances(managerId: $managerId) {
    id
  }
}
    `;
export const useFetchAllSubmittedAttendancesQuery = <
  TData = FetchAllSubmittedAttendancesQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: FetchAllSubmittedAttendancesQueryVariables,
  options?: UseQueryOptions<FetchAllSubmittedAttendancesQuery, TError, TData>,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchAllSubmittedAttendancesQuery, TError, TData>(
    variables === undefined
      ? ["fetchAllSubmittedAttendances"]
      : ["fetchAllSubmittedAttendances", variables],
    fetcher<
      FetchAllSubmittedAttendancesQuery,
      FetchAllSubmittedAttendancesQueryVariables
    >(client, FetchAllSubmittedAttendancesDocument, variables, headers),
    options
  );
export const FetchAttendancesCostSummaryDocument = `
    query fetchAttendancesCostSummary($attendanceIds: [ID!]!) {
  attendancesCostSummary(attendanceIds: $attendanceIds) {
    workedDates
    communityMembers {
      fullName
      preferredName
      profileImageUrl
    }
    primaryRates {
      description
      hoursWorked
      wage
    }
    secondaryRates {
      description
      hoursWorked
      wage
    }
    payrollComplianceFeeInAud
    casualLoadingFeeInAud
    serviceFeeInAud
    gstInAud
    grandTotalInAud
    paymentTermSurchargeInAud
  }
}
    `;
export const useFetchAttendancesCostSummaryQuery = <
  TData = FetchAttendancesCostSummaryQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables: FetchAttendancesCostSummaryQueryVariables,
  options?: UseQueryOptions<FetchAttendancesCostSummaryQuery, TError, TData>,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchAttendancesCostSummaryQuery, TError, TData>(
    ["fetchAttendancesCostSummary", variables],
    fetcher<
      FetchAttendancesCostSummaryQuery,
      FetchAttendancesCostSummaryQueryVariables
    >(client, FetchAttendancesCostSummaryDocument, variables, headers),
    options
  );
export const FetchChangeRequestReasonsDocument = `
    query fetchChangeRequestReasons {
  changeRequestReasons {
    value
    label
  }
}
    `;
export const useFetchChangeRequestReasonsQuery = <
  TData = FetchChangeRequestReasonsQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: FetchChangeRequestReasonsQueryVariables,
  options?: UseQueryOptions<FetchChangeRequestReasonsQuery, TError, TData>,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchChangeRequestReasonsQuery, TError, TData>(
    variables === undefined
      ? ["fetchChangeRequestReasons"]
      : ["fetchChangeRequestReasons", variables],
    fetcher<
      FetchChangeRequestReasonsQuery,
      FetchChangeRequestReasonsQueryVariables
    >(client, FetchChangeRequestReasonsDocument, variables, headers),
    options
  );
export const FetchClientCompanyUsersDocument = `
    query fetchClientCompanyUsers {
  clientCompanyUsers {
    id
    fullName
  }
}
    `;
export const useFetchClientCompanyUsersQuery = <
  TData = FetchClientCompanyUsersQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: FetchClientCompanyUsersQueryVariables,
  options?: UseQueryOptions<FetchClientCompanyUsersQuery, TError, TData>,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchClientCompanyUsersQuery, TError, TData>(
    variables === undefined
      ? ["fetchClientCompanyUsers"]
      : ["fetchClientCompanyUsers", variables],
    fetcher<
      FetchClientCompanyUsersQuery,
      FetchClientCompanyUsersQueryVariables
    >(client, FetchClientCompanyUsersDocument, variables, headers),
    options
  );
export const FetchExtensionRequestReasonsDocument = `
    query fetchExtensionRequestReasons {
  extensionRequestReasons {
    value
    label
  }
}
    `;
export const useFetchExtensionRequestReasonsQuery = <
  TData = FetchExtensionRequestReasonsQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: FetchExtensionRequestReasonsQueryVariables,
  options?: UseQueryOptions<FetchExtensionRequestReasonsQuery, TError, TData>,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchExtensionRequestReasonsQuery, TError, TData>(
    variables === undefined
      ? ["fetchExtensionRequestReasons"]
      : ["fetchExtensionRequestReasons", variables],
    fetcher<
      FetchExtensionRequestReasonsQuery,
      FetchExtensionRequestReasonsQueryVariables
    >(client, FetchExtensionRequestReasonsDocument, variables, headers),
    options
  );
export const FetchFlaggedAttendancesDocument = `
    query fetchFlaggedAttendances($communityMemberName: String, $managerId: ID) {
  flaggedAttendances(
    communityMemberName: $communityMemberName
    managerId: $managerId
  ) {
    id
    state
    startAt
    finishAt
    workedOnDate
    startTime
    finishTime
    timeZone
    duration
    minimumEngagementRequired
    belowMinimumEngagementReasonToHuman
    minimumEngagementDuration {
      valueInSeconds
      formatted
    }
    hasPublicHoliday
    resubmitted
    workedThroughBreak
    workedBy {
      id
      fullName
      profileImageUrl
      preferredName
    }
    position {
      id
      title
    }
    breaks {
      id
      startTime
      finishTime
      duration
    }
    additionalNotes
  }
}
    `;
export const useFetchFlaggedAttendancesQuery = <
  TData = FetchFlaggedAttendancesQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: FetchFlaggedAttendancesQueryVariables,
  options?: UseQueryOptions<FetchFlaggedAttendancesQuery, TError, TData>,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchFlaggedAttendancesQuery, TError, TData>(
    variables === undefined
      ? ["fetchFlaggedAttendances"]
      : ["fetchFlaggedAttendances", variables],
    fetcher<
      FetchFlaggedAttendancesQuery,
      FetchFlaggedAttendancesQueryVariables
    >(client, FetchFlaggedAttendancesDocument, variables, headers),
    options
  );
export const FetchRehireRequestEmploymentTypesDocument = `
    query fetchRehireRequestEmploymentTypes {
  rehireRequestEmploymentTypes {
    value
    label
  }
}
    `;
export const useFetchRehireRequestEmploymentTypesQuery = <
  TData = FetchRehireRequestEmploymentTypesQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: FetchRehireRequestEmploymentTypesQueryVariables,
  options?: UseQueryOptions<
    FetchRehireRequestEmploymentTypesQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchRehireRequestEmploymentTypesQuery, TError, TData>(
    variables === undefined
      ? ["fetchRehireRequestEmploymentTypes"]
      : ["fetchRehireRequestEmploymentTypes", variables],
    fetcher<
      FetchRehireRequestEmploymentTypesQuery,
      FetchRehireRequestEmploymentTypesQueryVariables
    >(client, FetchRehireRequestEmploymentTypesDocument, variables, headers),
    options
  );
export const FetchSubmittedAttendancesDocument = `
    query fetchSubmittedAttendances($communityMemberName: String, $managerId: ID) {
  submittedAttendances(
    communityMemberName: $communityMemberName
    managerId: $managerId
  ) {
    id
    state
    startAt
    finishAt
    workedOnDate
    startTime
    finishTime
    timeZone
    duration
    minimumEngagementRequired
    belowMinimumEngagementReasonToHuman
    hasPublicHoliday
    resubmitted
    workedThroughBreak
    minimumEngagementDuration {
      valueInSeconds
      formatted
    }
    workedBy {
      id
      fullName
      profileImageUrl
      preferredName
    }
    position {
      id
      title
    }
    breaks {
      id
      startTime
      finishTime
      duration
    }
    additionalNotes
  }
}
    `;
export const useFetchSubmittedAttendancesQuery = <
  TData = FetchSubmittedAttendancesQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: FetchSubmittedAttendancesQueryVariables,
  options?: UseQueryOptions<FetchSubmittedAttendancesQuery, TError, TData>,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchSubmittedAttendancesQuery, TError, TData>(
    variables === undefined
      ? ["fetchSubmittedAttendances"]
      : ["fetchSubmittedAttendances", variables],
    fetcher<
      FetchSubmittedAttendancesQuery,
      FetchSubmittedAttendancesQueryVariables
    >(client, FetchSubmittedAttendancesDocument, variables, headers),
    options
  );
