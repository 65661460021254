import { differenceInMinutes, parse } from "date-fns";
import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    minimumEngagementMinutes: Number,
    mustHaveBreakMinutes: Number,
    minimumBreakMinutes: Number,
  };

  static targets = [
    "startAt",
    "finishAt",
    "totalTimeWorked",
    "breakStartTime",
    "breakFinishTime",
    "minimumEngagementGroup",
    "warningBreak",
  ];
  declare startAtTarget: HTMLInputElement;
  declare finishAtTarget: HTMLInputElement;
  declare totalTimeWorkedTarget: HTMLElement;
  declare breakStartTimeTargets: Array<HTMLInputElement>;
  declare breakFinishTimeTargets: Array<HTMLInputElement>;
  declare hasBreakStartTimeTarget: boolean;
  declare hasBreakFinishTimeTarget: boolean;
  declare minimumEngagementGroupTarget: HTMLElement;
  declare warningBreakTarget: HTMLElement;
  declare minimumEngagementMinutesValue: number;
  declare mustHaveBreakMinutesValue: number;
  declare minimumBreakMinutesValue: number;

  connect(): void {
    this.waitForFlatpickrDateValues().then(() => {
      this.processingForm();
    });
  }

  processingForm(): void {
    const hasBreaks =
      this.hasBreakFinishTimeTarget && this.hasBreakStartTimeTarget;

    const totalBreakMinutes = this.getTotalBreakMinutes(
      this.breakStartTimeTargets,
      this.breakFinishTimeTargets,
      hasBreaks
    );
    const totalAttendanceMinutes = this.getElapsedMinutes(
      this.startAtTarget.value,
      this.finishAtTarget.value
    );

    this.handleHideShowWarnBreak(totalAttendanceMinutes, totalBreakMinutes);

    const totalMinutesWorked = totalAttendanceMinutes - totalBreakMinutes;
    this.displayTotalTimeWorkedMessage(totalMinutesWorked, totalBreakMinutes);

    if (
      this.minimumEngagementMinutesValue > 0 &&
      totalMinutesWorked < this.minimumEngagementMinutesValue
    ) {
      this.showMinimumEngagementGroup();
    } else {
      this.hideMinimumEngagementGroup();
    }
  }

  handleHideShowWarnBreak(
    totalAttendanceMinutes: number,
    totalBreakMinutes: number
  ): void {
    const mustHaveBreak =
      this.mustHaveBreakMinutesValue < totalAttendanceMinutes;
    const enoughBreakMinutes =
      totalBreakMinutes >= this.minimumBreakMinutesValue;

    if (mustHaveBreak && !enoughBreakMinutes) this.showWarningBreak();
    else this.hideWarningBreak();
  }

  displayTotalTimeWorkedMessage(
    totalMinutesWorked: number,
    totalBreakMinutes: number
  ): void {
    const displayMessage = this.getDisplayMessage(
      totalMinutesWorked,
      totalBreakMinutes
    );
    this.totalTimeWorkedTarget.innerText = displayMessage;
  }

  getDisplayMessage(
    totalMinutesWorked: number,
    totalBreakMinutes: number
  ): string {
    if (totalMinutesWorked <= 0) return "Total hours must be greater than 0";
    if (totalBreakMinutes == -1) return "Invalid break present";
    if (Number.isNaN(totalMinutesWorked) || Number.isNaN(totalBreakMinutes))
      return "Invalid break present";
    const hoursWorked = Math.floor(totalMinutesWorked / 60);
    const minutesWorked = Math.floor(totalMinutesWorked % 60);
    return `${hoursWorked} hrs ${minutesWorked} mins`;
  }

  getTotalBreakMinutes(
    startTimeElements: Array<HTMLInputElement>,
    finishTimeElements: Array<HTMLInputElement>,
    hasBreaks: boolean
  ): number {
    if (!hasBreaks) return 0;

    const minutesList = startTimeElements.map(
      this.getDifferenceInMinutes(finishTimeElements)
    );

    if (minutesList.some((minutes) => minutes <= 0)) return -1;

    return minutesList.reduce(
      (totalMinutes, minutes): number => totalMinutes + minutes
    );
  }

  getDifferenceInMinutes(finishTimeInputList: Array<HTMLInputElement>) {
    return (startTimeInput: HTMLInputElement, index: number): number =>
      this.getElapsedMinutes(
        startTimeInput.value,
        finishTimeInputList[index].value
      );
  }

  getElapsedMinutes(startTimeString: string, finishTimeString: string): number {
    const referenceDate = new Date();
    const startTime = parse(startTimeString, "HH:mm", referenceDate);
    const finishTime = parse(finishTimeString, "HH:mm", referenceDate);
    return differenceInMinutes(finishTime, startTime);
  }

  waitForFlatpickrDateValues(): Promise<void> {
    return Promise.resolve();
  }

  showMinimumEngagementGroup(): void {
    this.minimumEngagementGroupTarget.classList.remove("hidden");
  }

  hideMinimumEngagementGroup(): void {
    this.minimumEngagementGroupTarget.classList.add("hidden");
  }

  showWarningBreak(): void {
    this.warningBreakTarget.classList.remove("hidden");
  }

  hideWarningBreak(): void {
    this.warningBreakTarget.classList.add("hidden");
  }
}
