import { gaClickTrack, gaSelectTrack } from "helpers/ga_event_tracking";
import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    clickText: String,
    checkboxName: String,
    checkboxLabel: String,
    selectLabel: String,
    selectName: String,
    selectedValue: String,
  };

  declare clickTextValue: string;
  declare checkboxNameValue: string;
  declare checkboxLabelValue: string;
  declare selectLabelValue: string;
  declare selectNameValue: string;
  declare selectedValueValue: string;

  click(e: Event): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    gaClickTrack({
      click_text: this.clickTextValue || target.textContent,
    });
  }

  clickCheckbox(e: Event): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    gaClickTrack({
      checkbox_name: this.checkboxNameValue,
      checkbox_label: this.checkboxLabelValue,
      checked: target.checked.toString(),
    });
  }

  select(e: Event): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;

    gaSelectTrack({
      select_label: this.selectLabelValue,
      selected_name: this.checkboxNameValue,
      selected_value: this.selectedValueValue || target.value,
    });
  }
}
