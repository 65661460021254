import React from "react";
import SVG from "react-inlinesvg";
import Modal from "react-modal";

import LeftURL from "@assets/icons/left.svg";
import BeefHotpotURL from "@assets/illustrations/beef_hotpot.svg";
import "./fullLayoutModalStyle.scss";

interface FullLayoutModalProps {
  isOpen: boolean;
  title: string;
  subTitle?: string;
  closeModal: () => void;
  children: React.ReactElement | React.ReactElement[];
}

Modal.setAppElement("body");

export const FullLayoutModal = ({
  isOpen,
  title,
  subTitle,
  children,
  closeModal,
}: FullLayoutModalProps): JSX.Element => {
  const customStyles = {
    overlay: {
      zIndex: 9999,
    },
    content: {
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      padding: "0",
    },
  };

  return (
    <Modal isOpen={isOpen} contentLabel='Example Modal' style={customStyles}>
      <section className='meander text-legacyColor-neutral-1-800'>
        <header>
          <div className='flex items-center mx-[0.938rem] py-[1.813rem] md:mx-[7.625rem] md:pb-8 md:pt-[3.18rem]'>
            <div className='flex items-center relative z-[999] text-primary-900'>
              <SVG
                src={LeftURL}
                className='icon-lg fill-current mr-4 cursor-pointer'
                onClick={closeModal}
              />
              <div>
                <p className='h6 font-bold md:h3'>{title}</p>
                <div className='mt-2'>{subTitle}</div>
              </div>
            </div>
            <div className='absolute bottom-0 right-[15%] md:right-40 w-[99px] md:w-[153px]'>
              <SVG src={BeefHotpotURL} width={100} className='w-full' />
            </div>
          </div>
        </header>
        <main className='mx-[0.938rem] md:mx-[7.625rem]'>{children}</main>
      </section>
    </Modal>
  );
};
