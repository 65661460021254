import { useState, Dispatch } from "react";
import SVG from "react-inlinesvg";

import UpArrow from "@assets/icons/up.svg";
import { Attendance as AttendanceType } from "@graphql/client_app/types";

import { SubmittedAttendancesGroupList } from "./SubmittedAttendancesGroupList";
import { SubmittedAttendancesGroupProfile } from "./SubmittedAttendancesGroupProfile";

import {
  SelectionReducerAction,
  SelectionActionType,
} from "@/client_app/features/timesheetApproval/utils/operations";
import { useMediaQuery } from "@/client_app/hooks/useMediaQuery";

interface SubmittedAttendancesGroupContainerProps {
  positionTitle: string;
  profileImageUrl: string;
  fullName: string;
  totalHoursWorked: string;
  attendances: AttendanceType[];
  allSelectedDispatch: Dispatch<SelectionReducerAction>;
  selectedAttendancesIds?: string[];
}

export const SubmittedAttendancesGroupContainer = ({
  positionTitle,
  profileImageUrl,
  fullName,
  attendances,
  totalHoursWorked,
  allSelectedDispatch,
  selectedAttendancesIds = [],
}: SubmittedAttendancesGroupContainerProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  function handleExpandList(event: React.MouseEvent): void {
    event.preventDefault();
    setIsExpanded(!isExpanded);
  }

  function handleSelectAllGroupedToggle(): void {
    if (selectedAttendancesIds.length < attendances.length) {
      const unCheckedAttendancesIds = attendances.reduce(
        (acc: string[], attendance: AttendanceType) => {
          if (!selectedAttendancesIds.includes(attendance.id)) {
            acc.push(attendance.id);
          }

          return acc;
        },
        []
      );
      allSelectedDispatch({
        type: SelectionActionType.ADD_TO_SELECTED,
        attendanceIds: unCheckedAttendancesIds,
      });
    } else {
      const attendancesIds = attendances.map((att) => {
        return att.id;
      });
      allSelectedDispatch({
        type: SelectionActionType.REMOVE_FROM_SELECTED,
        attendanceIds: attendancesIds,
      });
    }
  }

  function handleAttendanceToggle(attendanceId): void {
    if (selectedAttendancesIds.includes(attendanceId)) {
      allSelectedDispatch({
        type: SelectionActionType.REMOVE_FROM_SELECTED,
        attendanceIds: [attendanceId],
      });
    } else {
      allSelectedDispatch({
        type: SelectionActionType.ADD_TO_SELECTED,
        attendanceIds: [attendanceId],
      });
    }
  }

  return (
    <div className='mb-6 md:mb-12 last:mb-0'>
      <div className='grid grid-cols-7 p-3 border-2 border-neutral-200 rounded-xl md:p-0 md:border-0'>
        <SubmittedAttendancesGroupProfile
          positionTitle={positionTitle}
          profileImageUrl={profileImageUrl}
          fullName={fullName}
          attendanceCount={attendances.length}
          onExpandList={handleExpandList}
          totalHoursWorked={totalHoursWorked}
          isExpanded={isExpanded}
          isMobile={isMobile}
          selectAllToggle={handleSelectAllGroupedToggle}
          selectedAttendanceCount={selectedAttendancesIds.length}
        />
        <div className='col-span-full md:col-span-5 md:block'>
          {isExpanded && !isMobile && (
            <div className='text-right'>
              <button className='font-semibold' onClick={handleExpandList}>
                <span className='flex items-center'>
                  VIEW LESS
                  <SVG
                    src={UpArrow}
                    className='icon fill-current mr-4 cursor-pointer'
                  />
                </span>
              </button>
            </div>
          )}
          <SubmittedAttendancesGroupList
            attendances={attendances}
            isExpanded={isExpanded}
            isMobile={isMobile}
            onExpandList={handleExpandList}
            onAttendanceToggle={handleAttendanceToggle}
            checkedAttendances={selectedAttendancesIds}
          />
        </div>
      </div>
    </div>
  );
};
