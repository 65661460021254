import Remote from "stimulus-remote-rails";

export default class extends Remote {
  static targets = ["modalContent"];

  declare modalContentTarget: HTMLElement;

  connect() {
    super.connect();
  }

  append(event: CustomEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const [data, ,] = event.detail;

    this.modalContentTarget.innerHTML = data.replace_content;
  }
}
