import NestedForm from "stimulus-rails-nested-form";

export default class extends NestedForm {
  remove(event: Event): void {
    super.remove(event);

    // This enables us perform actions with other stimulus controller.
    // e.g. nested-form-remove@window->attendance-form#processingForm.
    const customEvent = new CustomEvent("nested-form-remove");
    window.dispatchEvent(customEvent);
  }
}
