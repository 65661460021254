import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "total"];
  declare totalTarget: HTMLInputElement;
  declare inputTargets: Array<HTMLInputElement>;

  connect(): void {
    this.updateTotal();
  }

  updateTotal(): void {
    const total = this.inputTargets
      .map(this.parseInputValue)
      .filter(this.valueIsNotNan)
      .reduce(this.sumValues)
      .toString();
    this.totalTarget.value = total;
  }

  private parseInputValue(input: HTMLInputElement): number {
    return parseInt(input.value);
  }
  private valueIsNotNan(value: number): boolean {
    return !isNaN(value);
  }
  private sumValues(total: number, value: number): number {
    return total + value;
  }
}
