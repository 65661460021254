import { gaClickTrack } from "helpers/ga_event_tracking";
import React, { ChangeEvent } from "react";
import SVG from "react-inlinesvg";

import DownArrow from "@assets/icons/down.svg";

import { CommunityMemberAvatar } from "@/client_app/components/CommunityMemberAvatar";
import { CheckboxWithIndeterminateState } from "@/ui/CheckboxWithIndeterminateState";

interface FlaggedAttendancesGroupProfileProps {
  positionTitle: string;
  profileImageUrl: string;
  fullName: string;
  totalHoursWorked: string;
  attendanceCount: number;
  isExpanded: boolean;
  isMobile: boolean;
  onExpandList: (event: React.MouseEvent) => void;
  selectAllToggle: () => void;
  selectedAttendanceCount: number;
}

export const FlaggedAttendancesGroupProfile = ({
  positionTitle,
  profileImageUrl,
  fullName,
  totalHoursWorked,
  attendanceCount,
  isExpanded,
  isMobile,
  onExpandList,
  selectAllToggle,
  selectedAttendanceCount,
}: FlaggedAttendancesGroupProfileProps): JSX.Element => {
  let checkboxState;
  if (selectedAttendanceCount === attendanceCount) {
    checkboxState = "CHECKED";
  } else if (selectedAttendanceCount > 0) {
    checkboxState = "INDETERMINATE";
  } else {
    checkboxState = "EMPTY";
  }

  function gaEventTracking(event: ChangeEvent) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    gaClickTrack({
      checkbox_label: "Select Individual Flagged Weployees",
      checkbox_name: "selectIndividualFlaggedWeployees",
      checked: target.checked.toString(),
    });
  }

  function handleChange(event: ChangeEvent) {
    gaEventTracking(event);
    selectAllToggle();
  }

  return (
    <div className='flex items-start col-span-full md:col-span-2'>
      <div className='flex items-start md:items-center'>
        <div className='mr-3.5'>
          <CheckboxWithIndeterminateState
            state={checkboxState}
            onChange={handleChange}
          />
        </div>
        <CommunityMemberAvatar
          profileImageUrl={profileImageUrl}
          name={fullName}
          size='SMALL'
        />
      </div>
      <div className='ml-3 p2 md:p3'>
        <div className='font-semibold mb-1'>
          {fullName} ({attendanceCount})
        </div>
        <div className='mb-1'>{positionTitle}</div>
        <div className='text-neutral-600 mb-1'>
          Total Hours Worked: {totalHoursWorked}
        </div>
        {isMobile && attendanceCount > 1 && (
          <div>
            <button className='font-semibold mb-2' onClick={onExpandList}>
              <span className='flex items-center'>
                {isExpanded ? "VIEW LESS" : "VIEW ALL SHIFTS"}
                <SVG
                  src={DownArrow}
                  className='icon fill-current mr-4 cursor-pointer'
                />
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
