import { Route, Navigate, Outlet } from "react-router-dom";

import {
  STUDENT_ACTIVE_POSITIONS_PATH,
  STUDENT_PAST_POSITIONS_PATH,
  STUDENTS_EXTENSION_REQUEST_SUCCESS_PATH,
  STUDENTS_BUYOUT_REQUEST_SUCCESS_PATH,
  STUDENTS_PATH,
  WEPLOYEE_ACTIVE_POSITIONS_PATH,
  WEPLOYEE_PAST_POSITIONS_PATH,
  WEPLOYEES_EXTENSION_REQUEST_SUCCESS_PATH,
  WEPLOYEES_BUYOUT_REQUEST_SUCCESS_PATH,
  WEPLOYEES_PATH,
} from "@/client_app/config";

import { ActivePositions } from "@/client_app/features/weployees/components/ActivePositions";
import { BuyoutRequestSuccess } from "@/client_app/features/weployees/components/BuyoutRequestSuccess";
import { ExtensionsRequestSuccess } from "@/client_app/features/weployees/components/ExtensionsRequestSuccess";
import { PastPositions } from "@/client_app/features/weployees/components/PastPositions";

export const weployeesRoutes = (
  <Route path={WEPLOYEES_PATH} element={<Outlet />}>
    <Route
      index
      element={<Navigate to={WEPLOYEE_ACTIVE_POSITIONS_PATH} replace />}
    />
    <Route
      path={WEPLOYEE_ACTIVE_POSITIONS_PATH}
      element={<ActivePositions />}
      key='weployeeRoute'
    />
    <Route
      path={WEPLOYEE_PAST_POSITIONS_PATH}
      element={<PastPositions />}
      key='weployeeRoute'
    />
    <Route
      path={WEPLOYEES_EXTENSION_REQUEST_SUCCESS_PATH}
      element={<ExtensionsRequestSuccess />}
      key='extensionsRequestSuccessRoute'
    />

    <Route
      path={WEPLOYEES_BUYOUT_REQUEST_SUCCESS_PATH}
      element={<BuyoutRequestSuccess />}
      key='BuyoutRequestSuccessRoute'
    />
  </Route>
);

export const studentsRoutes = (
  <Route path={STUDENTS_PATH} element={<Outlet />}>
    <Route
      index
      element={<Navigate to={STUDENT_ACTIVE_POSITIONS_PATH} replace />}
    />
    <Route
      path={STUDENT_ACTIVE_POSITIONS_PATH}
      element={<ActivePositions />}
      key='weployeeRoute'
    />
    <Route
      path={STUDENT_PAST_POSITIONS_PATH}
      element={<PastPositions />}
      key='weployeeRoute'
    />
    <Route
      path={STUDENTS_EXTENSION_REQUEST_SUCCESS_PATH}
      element={<ExtensionsRequestSuccess />}
      key='extensionsRequestSuccessRoute'
    />
    <Route
      path={STUDENTS_BUYOUT_REQUEST_SUCCESS_PATH}
      element={<BuyoutRequestSuccess />}
      key='BuyoutRequestSuccessRoute'
    />
  </Route>
);
