import clsx from "clsx";
import i18next from "i18next";
import "@/i18n/config";
import { useState } from "react";
import Flatpickr from "react-flatpickr";
import { useForm, useWatch } from "react-hook-form";
import SVG from "react-inlinesvg";

import DownArrow from "@assets/icons/down.svg";
import Info from "@assets/icons/info-primary-900.svg";
import UpArrow from "@assets/icons/up.svg";
import PeopleInLivingRoomImageUrl from "@assets/illustrations/people_in_living_room.svg";
import graphqlClient from "@graphql/client_app/client";
import { Position } from "@graphql/client_app/types";
import {
  useFetchRehireRequestEmploymentTypesQuery,
  useCreateRehireRequestsMutation,
} from "@graphql/client_app/types";

import { CommunityMemberAvatar } from "@/client_app/components/CommunityMemberAvatar";
import { PrimaryButton } from "@/ui/buttons";
import { flash } from "@/ui/Flash";
import { FullLayoutModal } from "@/ui/FullLayoutModal";

interface RehireRequestProp {
  onBack: () => void;
  setSelectedPositionIds: (positionIds: Array<string>) => void;
  isOpen: boolean;
  selectedPositions?: Array<PositionType>;
}

type ChangeFields<T, R> = Omit<T, keyof R> & R;
export type PositionType = ChangeFields<
  Position,
  {
    communityMember: Omit<
      Position["communityMember"],
      "email" | "preferredName" | "timeZone"
    >;
    extensionRequests?: { isPending: boolean }[];
  }
>;

function RehireRequestPreview({
  onBack,
  setSelectedPositionIds,
  selectedPositions = [],
}: RehireRequestProp): JSX.Element {
  const employees = window.terminologyEmployee + "s";

  const {
    register,
    control,
    handleSubmit: reactHookFormHandleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const employmentTypeValue = useWatch({
    control,
    name: "rehire_request.employmentType",
  });

  const mutation = useCreateRehireRequestsMutation(graphqlClient);
  const [listExpanded, setListExpanded] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: rehireRequestEmploymentTypesData,
    isLoading: isLoadingRehireRequestEmploymentTypes,
    isSuccess: isSuccessRehireRequestEmploymentTypes,
  } = useFetchRehireRequestEmploymentTypesQuery(graphqlClient);

  function getHeading() {
    return selectedPositions.length > 1
      ? `${selectedPositions.length} ${employees} selected`
      : `${selectedPositions[0].communityMember.fullName} selected`;
  }

  function handleSubmit(data): void {
    setIsSubmitting(true);

    const variables = {
      communityMemberIds: selectedPositions.map(
        (position) => position.communityMember.id
      ),
      ...data.rehire_request,
    };

    mutation.mutateAsync(variables).then((data) => {
      const errors = data.createRehireRequests.errors;
      setIsSubmitting(false);

      if (errors.length > 0) {
        handleFailure(errors);
      } else {
        handleSuccess();
      }
    });
  }

  function handleFailure(errors): void {
    errors.forEach((error) => {
      setError(`rehire_request.${error.path}`, {
        type: "custom",
        message: error.message,
      });
    });
  }

  function handleSuccess() {
    flash.notice(i18next.t("clientApp.rehireRequestPreview.successNotice"));
    onBack();
    setSelectedPositionIds([]);
  }

  function onSelectStartDate(date: Date): void {
    setValue("rehire_request.startDate", date.toString());
  }

  function onSelectEndDate(date: Date): void {
    setValue("rehire_request.endDate", date.toString());
  }

  return (
    <FullLayoutModal
      isOpen={true}
      title={i18next.t("clientApp.rehireRequestPreview.title")}
      closeModal={onBack}
      subTitle=''
    >
      <div className='flex my-7 text-newColor-neutral-900'>
        <div>
          <div className='flex justify-between items-center mb-6'>
            <h6 className='font-bold md:font-semibold text-newColor-neutral-900 md:h4-sm'>
              {getHeading()}
            </h6>
            {selectedPositions.length > 1 && (
              <button
                className='font-semibold'
                onClick={() => setListExpanded(!listExpanded)}
              >
                <span className='flex items-center text-newColor-primary-900'>
                  {listExpanded ? "HIDE" : "VIEW"} ALL
                  <SVG
                    src={listExpanded ? UpArrow : DownArrow}
                    className='icon fill-current cursor-pointer'
                  />
                </span>
              </button>
            )}
          </div>
          <div>
            {selectedPositions.map(({ id, communityMember }) => {
              return (
                <div
                  className={clsx(
                    "odd:bg-neutral-50 even:bg-neutral-100 flex items-center last:mb-6 p-3",
                    listExpanded ? "block" : "hidden"
                  )}
                  key={id}
                >
                  <CommunityMemberAvatar
                    size='EXTRA_SMALL'
                    profileImageUrl={communityMember.profileImageUrl}
                    name={communityMember.fullName}
                  />
                  <div className='ml-4'>{communityMember.fullName}</div>
                </div>
              );
            })}
          </div>
          <form
            onSubmit={reactHookFormHandleSubmit(handleSubmit)}
            className='mb-2 w-11/12 md:w-96'
          >
            <div className='mb-5'>
              {isLoadingRehireRequestEmploymentTypes &&
                "Loading rehire request employment types"}
              {isSuccessRehireRequestEmploymentTypes && (
                <>
                  <label htmlFor='employment_type' className='form-label'>
                    {i18next.t(
                      "clientApp.rehireRequestPreview.form.employmentType.label"
                    )}
                  </label>
                  <div className='flex gap-2 justify-between'>
                    {rehireRequestEmploymentTypesData.rehireRequestEmploymentTypes.map(
                      (option) => {
                        return (
                          <div className='flex gap-1' key={option.value}>
                            <input
                              className='form-radio'
                              type='radio'
                              value={option.value}
                              id={option.value}
                              {...register("rehire_request.employmentType")}
                              defaultChecked={
                                option.value ===
                                rehireRequestEmploymentTypesData
                                  .rehireRequestEmploymentTypes[0].value
                              }
                            />
                            <label
                              className='form-label'
                              htmlFor={option.value}
                            >
                              {option.label}
                            </label>
                          </div>
                        );
                      }
                    )}
                  </div>
                </>
              )}
              <div className='form-error first-letter:capitalize mt-2'>
                {errors?.rehire_request?.employmentType?.message}
              </div>
            </div>

            <div className='mb-7'>
              <label
                htmlFor='start_date'
                className={clsx(
                  "form-label",
                  !!errors?.rehire_request?.startDate?.message &&
                    "text-error-600"
                )}
              >
                {i18next.t(
                  "clientApp.rehireRequestPreview.form.startDate.label"
                )}
              </label>
              <Flatpickr
                className={clsx(
                  "form-control",
                  !!errors?.rehire_request?.startDate?.message &&
                    "border-error-600"
                )}
                onClose={onSelectStartDate}
                name='start_date_picker'
                options={{
                  dateFormat: "d F Y",
                }}
                placeholder={i18next.t(
                  "clientApp.rehireRequestPreview.form.startDate.placeholder"
                )}
              />
              <input type='hidden' {...register("rehire_request.startDate")} />
              <div className='form-error first-letter:capitalize mt-2'>
                {errors?.rehire_request?.startDate?.message}
              </div>
            </div>

            {employmentTypeValue != "permanent" ? (
              <>
                <div className='mb-7'>
                  <label
                    htmlFor='end_date'
                    className={clsx(
                      "form-label",
                      !!errors?.rehire_request?.endDate?.message &&
                        "text-error-600"
                    )}
                  >
                    {i18next.t(
                      "clientApp.rehireRequestPreview.form.endDate.label"
                    )}
                  </label>
                  <Flatpickr
                    className={clsx(
                      "form-control",
                      !!errors?.rehire_request?.endDate?.message &&
                        "border-error-600"
                    )}
                    onClose={onSelectEndDate}
                    name='end_date_picker'
                    options={{
                      dateFormat: "d F Y",
                    }}
                    placeholder={i18next.t(
                      "clientApp.rehireRequestPreview.form.endDate.placeholder"
                    )}
                  />
                  <input
                    type='hidden'
                    {...register("rehire_request.endDate")}
                  />
                  <div className='form-error first-letter:capitalize mt-2'>
                    {errors?.rehire_request?.endDate?.message}
                  </div>
                </div>
              </>
            ) : (
              <div className='mb-7'>
                <label htmlFor='salary' className='form-label'>
                  {i18next.t(
                    "clientApp.rehireRequestPreview.form.salary.label"
                  )}
                </label>
                <input
                  type='text'
                  id='salary'
                  className={clsx(
                    "form-control",
                    !!errors?.rehire_request?.salary?.message &&
                      "border-error-600"
                  )}
                  placeholder={i18next.t(
                    "clientApp.rehireRequestPreview.form.salary.placeholder"
                  )}
                  {...register("rehire_request.salary")}
                />
                <div className='form-error first-letter:capitalize mt-2'>
                  {errors?.rehire_request?.salary?.message}
                </div>
              </div>
            )}

            <div className='mb-7'>
              <label htmlFor='additionalComments' className='form-label'>
                {i18next.t(
                  "clientApp.rehireRequestPreview.form.additionalComments.label"
                )}
              </label>
              <textarea
                id='additionalComments'
                className='form-control mb-2 h-28'
                placeholder={i18next.t(
                  "clientApp.rehireRequestPreview.form.additionalComments.placeholder"
                )}
                {...register("rehire_request.additionalComments")}
              />
            </div>
            <div className='flex items-center mb-7'>
              <SVG
                src={Info}
                className='w-[20px] h-[20px] min-w-[20px] min-h-[20px] mr-5 text-newColor-primary-900'
              />
              <span className='p2'>
                {i18next.t("clientApp.rehireRequestPreview.form.infoMessage", {
                  terminologyEmployee: window.terminologyEmployee,
                })}
              </span>
            </div>
            <PrimaryButton
              loading={isSubmitting}
              size='MEDIUM'
              type='submit'
              label={i18next.t(
                "clientApp.rehireRequestPreview.form.submitButton"
              )}
            />
          </form>
        </div>
        <div className='w-full justify-center items-start hidden md:flex'>
          <SVG src={PeopleInLivingRoomImageUrl} className='w-[44rem]' />
        </div>
      </div>
    </FullLayoutModal>
  );
}

export { RehireRequestPreview };
