import clsx from "clsx";
import i18next from "i18next";
import "@/i18n/config";
import { useForm } from "react-hook-form";

import graphqlClient from "@graphql/client_app/client";
import { useCreateChangeRequestMutation } from "@graphql/client_app/types";
import { MinimumEngagementDuration as MinimumEngagementDurationType } from "@graphql/client_app/types";
import { useFetchChangeRequestReasonsQuery } from "@graphql/client_app/types";

import { ChangeRequestCommunityMemberProfile } from "./ChangeRequestCommunityMemberProfile";
import { ChangeRequestPreviewAttendanceItem } from "./ChangeRequestPreviewAttendanceItem";
import { FullLayoutModal } from "./FullLayoutModal";

import {
  getBreakTimes,
  getDailyTotal,
} from "@/client_app/features/timesheetApproval/utils/operations";
import { PrimaryButton } from "@/ui/buttons";
import { flash } from "@/ui/Flash";

interface BreakType {
  id: string;
  startTime: string;
  finishTime: string;
  duration: number;
}

interface CommunityMemberType {
  profileImageUrl?: string;
  fullName: string;
}

interface FlaggedAttendanceType {
  id: string;
  additionalNotes?: string;
  belowMinimumEngagementReasonToHuman?: string;
  breaks?: BreakType[];
  duration: number;
  hasPublicHoliday: boolean;
  minimumEngagementDuration: MinimumEngagementDurationType;
  minimumEngagementRequired: boolean;
  resubmitted: boolean;
  workedOnDate: string;
  startTime: string;
  finishTime: string;
}

interface ChangeRequestPreviewProp {
  attendances: FlaggedAttendanceType[];
  communityMember: CommunityMemberType;
  onBack: () => unknown;
  onFormSubmissionSuccess: () => unknown;
  isOpen: boolean;
}

function ChangeRequestPreview({
  attendances,
  communityMember,
  onBack,
  onFormSubmissionSuccess,
  isOpen,
}: ChangeRequestPreviewProp): JSX.Element {
  const {
    register,
    handleSubmit: reactHookFormHandleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const mutation = useCreateChangeRequestMutation(graphqlClient);
  const {
    data: changeRequestReasonsData,
    isLoading: isLoadingChangeRequestReasons,
    isSuccess: isSuccessChangeRequestReasons,
  } = useFetchChangeRequestReasonsQuery(graphqlClient);

  function handleSuccess() {
    onFormSubmissionSuccess();
    flash.notice(
      i18next.t("clientApp.weployeeNotifiedOfRequestedChange", {
        terminologyEmployee: window.terminologyEmployee,
      })
    );
  }

  function handleFailure(errors): void {
    errors.forEach((error) => {
      setError(`change_request.${error.path}`, {
        type: "custom",
        message: error.message,
      });
    });
  }

  function handleSubmit(data): void {
    const variables = {
      attendanceIds: attendances.map((attendance) => attendance.id),
      reason: data.change_request.reason,
      details: data.change_request.details,
    };

    mutation.mutateAsync(variables).then((data) => {
      const errors = data.createChangeRequest.errors;

      if (errors.length > 0) {
        handleFailure(errors);
      } else {
        handleSuccess();
      }
    });
  }

  return (
    <FullLayoutModal
      isOpen={isOpen}
      title='Request Timesheet Changes'
      closeModal={onBack}
    >
      <div>
        <ChangeRequestCommunityMemberProfile
          profileImageUrl={communityMember.profileImageUrl}
          communityMemberName={communityMember.fullName}
        />
      </div>
      <div className='mb-4'>
        <p className='text-subheading'>
          In order to request changes we need a few more details from you
        </p>
        <p className='p2'>
          {i18next.t("clientApp.pleaseEnsureCheckedNotesOnYourAttendances", {
            terminologyEmployee: window.terminologyEmployee,
          })}
        </p>
      </div>
      <div className='mb-4'>
        {attendances.map((attendance) => {
          return (
            <div key={attendance.id} className='mb-4'>
              <ChangeRequestPreviewAttendanceItem
                workedOnDate={attendance.workedOnDate}
                hoursFromTo={`${attendance.startTime} - ${attendance.finishTime}`}
                breaks={getBreakTimes(attendance.breaks)}
                formattedDuration={getDailyTotal(
                  attendance.duration,
                  attendance.breaks
                )}
                additionalNotes={attendance.additionalNotes}
                minimumEngagementRequired={attendance.minimumEngagementRequired}
                minimumEngagementDuration={attendance.minimumEngagementDuration}
                belowMinimumEngagementReasonToHuman={
                  attendance.belowMinimumEngagementReasonToHuman
                }
                hasPublicHoliday={attendance.hasPublicHoliday}
                isResubmitted={attendance.resubmitted}
              />
            </div>
          );
        })}
      </div>
      <p className='p2 font-semibold'>
        When adding in further details please be specific as possible including
        times and dates.
      </p>
      <form onSubmit={reactHookFormHandleSubmit(handleSubmit)}>
        <div className='w-96 my-4'>
          {isLoadingChangeRequestReasons && "Loading change request reasons..."}
          {isSuccessChangeRequestReasons && (
            <>
              <label
                className={clsx(
                  "form-label",
                  !!errors?.change_request?.reason?.message && "text-error-600"
                )}
                htmlFor='change_request_reasons'
              >
                Reason for Request
              </label>
              <select
                className={clsx(
                  "form-select down-icon",
                  !!errors?.change_request?.reason?.message &&
                    "border-error-600"
                )}
                id='change_request_reasons'
                defaultValue=''
                {...register("change_request.reason")}
              >
                <option hidden disabled value=''>
                  Please select a reason
                </option>
                {changeRequestReasonsData.changeRequestReasons.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className='form-error first-letter:capitalize'>
                {errors?.change_request?.reason?.message}
              </div>
            </>
          )}
        </div>
        <div className='w-96 my-4'>
          <label
            className={clsx(
              "form-label",
              !!errors?.change_request?.details?.message && "text-error-600"
            )}
            htmlFor='change_request_details'
          >
            Further Details
          </label>
          <textarea
            placeholder='Daniel finished at 4pm on the 23rd and 24th, rather than 5pm.'
            className={clsx(
              "form-control",
              !!errors?.change_request?.details?.message && "border-error-600"
            )}
            rows={6}
            id='change_request_details'
            {...register("change_request.details")}
          />
          <div className='form-error first-letter:capitalize'>
            {errors?.change_request?.details?.message}
          </div>
        </div>
        <div className='mb-10'>
          <PrimaryButton
            loading={mutation.isLoading}
            size='SMALL'
            type='submit'
            label='Submit Request'
          />
        </div>
      </form>
    </FullLayoutModal>
  );
}

export { ChangeRequestPreview };
