import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "attendanceGroup",
    "attendance",
    "check",
    "attendanceDetail",
    "viewLessButton",
    "notesToggleButton",
    "shiftsToggleButton",
    "shiftsToggleButtonMobile",
    "alertChip",
    "mobileAlertChip",
    "notes",
  ];

  declare attendanceDetailTargets: Array<HTMLElement>;
  declare attendanceTargets: Array<HTMLElement>;
  declare attendanceTarget: HTMLElement;
  declare attendanceGroupTarget: HTMLElement;

  declare viewLessButtonTarget: HTMLElement;
  declare shiftsToggleButtonMobileTargets: Array<HTMLElement>;
  declare shiftsToggleButtonTarget: HTMLElement;
  declare hasShiftsToggleButtonTarget: boolean;

  declare notesToggleButtonTargets: Array<HTMLElement>;
  declare notesToggleButtonTarget: HTMLElement;
  declare hasNotesToggleButtonTarget: boolean;

  declare notesTargets: Array<HTMLElement>;
  declare notesTarget: HTMLElement;
  declare hasNotesTarget: boolean;

  declare checkTargets: Array<HTMLElement>;
  declare hasCheckTarget: boolean;

  declare alertChipTargets: Array<HTMLElement>;
  declare hasAlertChipTarget: boolean;
  declare mobileAlertChipTargets: Array<HTMLElement>;
  declare hasMobileAlertChipTarget: boolean;

  connect(): void {
    this.transformAttendances(this.attendanceTargets);
  }

  transformAttendances(attendances: Element[]): void {
    this.toggleAttendanceDetail();

    attendances.forEach((attendance: HTMLElement, index: number): void => {
      const verticalPosition = this.verticalPosition(index);
      const zIndex = this.zIndex(attendances.length, index);
      attendance.style.zIndex = `${zIndex}`;

      this.translateAttendance(attendance, verticalPosition);
      this.adjustAttendanceGroupHeight(attendance, index, "stack");
    });

    if (attendances.length === 1 && this.hasAlertChipTarget) {
      this.toggleAlertChip();
      this.toggleNotes();
    }
  }

  expand(): void {
    this.toggleNotesAndShiftsButton();
    this.viewLessButtonTarget.classList.replace("hidden", "flex");

    if (this.hasAlertChipTarget) this.toggleNotes();

    this.toggleAlertChip();
    this.toggleAttendanceDetail();
    this.toggleAttendanceCheckbox();

    this.attendanceTargets.forEach(
      (attendance: HTMLElement, index: number): void => {
        this.translateAttendance(attendance, 0);
        this.adjustAttendanceGroupHeight(attendance, index, "expand");
        this.toggleAttendanceMargins(attendance);
      }
    );
  }

  stack(): void {
    this.hideNotes();
    this.viewLessButtonTarget.classList.replace("flex", "hidden");

    this.toggleAlertChip();
    this.toggleAttendanceDetail();
    this.toggleAttendanceCheckbox();

    this.attendanceTargets.forEach(
      (attendance: HTMLElement, index: number): void => {
        const verticalPosition = this.verticalPosition(index);

        this.translateAttendance(attendance, verticalPosition);
        this.adjustAttendanceGroupHeight(attendance, index, "stack");
        this.toggleAttendanceMargins(attendance);

        if (index === 0) this.toggleNotesAndShiftsButton();
      }
    );
  }

  toggleShiftsMobile(): void {
    this.expand();
    this.attendanceGroupTarget.classList.toggle("hidden");
    if (
      !this.hasMobileAlertChipTarget ||
      (this.attendanceTargets.length == 1 && this.hasMobileAlertChipTarget)
    ) {
      this.toggleNotes();
      this.toggleAlertChip();
    }

    this.toggleMobileAlertChip();

    this.shiftsToggleButtonMobileTargets.forEach((shiftsToggleButton) =>
      shiftsToggleButton.classList.toggle("hidden")
    );
  }

  toggleNotesAndShiftsButton(): void {
    if (this.hasShiftsToggleButtonTarget)
      this.shiftsToggleButtonTarget.classList.toggle("hidden");

    if (!this.hasAlertChipTarget && this.hasNotesToggleButtonTarget)
      this.notesToggleButtonTarget.classList.toggle("hidden");
  }

  toggleAlertChip(): void {
    if (this.hasAlertChipTarget) {
      this.alertChipTargets.forEach((alertChip): void => {
        alertChip.classList.toggle("hidden");
      });
    }
  }

  toggleMobileAlertChip(): void {
    if (this.hasMobileAlertChipTarget) {
      this.mobileAlertChipTargets.forEach((mobileAlertChip): void => {
        mobileAlertChip.classList.toggle("hidden");
      });
    }
  }

  toggleAttendanceMargins(element: HTMLElement): void {
    element.classList.toggle("mb-2");
    element.classList.toggle("md:mb-6");
    element.classList.toggle("last:mb-0");
  }

  toggleAttendanceDetail(): void {
    this.attendanceDetailTargets.forEach(
      (attendanceDetail, index: number): void => {
        if (index > 0) attendanceDetail.classList.toggle("hidden");
      }
    );
  }

  toggleAttendanceCheckbox(): void {
    if (this.hasCheckTarget) {
      this.checkTargets.forEach((check): boolean =>
        check.classList.toggle("hidden")
      );
    }
  }

  hideNotes(): void {
    if (this.hasNotesTarget) {
      this.notesTargets.forEach((notes): void => notes.classList.add("hidden"));
    }
  }

  toggleNotes(): void {
    if (this.hasNotesTarget) {
      this.notesTargets.forEach((notes): boolean =>
        notes.classList.toggle("hidden")
      );
    }
  }

  translateAttendance(element: HTMLElement, verticalPosition: number): void {
    element.style.transform = `translate(0px, ${verticalPosition}%)`;
  }

  adjustAttendanceGroupHeight(
    element: HTMLElement,
    index: number,
    action: string
  ): void {
    this.setHeight(element, this.attendanceGroupTarget, "initial", "initial");

    if (
      this.attendanceGroupTarget.childElementCount > 1 &&
      action === "stack"
    ) {
      const offset = index < 2 ? 0.2 : 0.4;
      const rect = this.attendanceTarget.getBoundingClientRect();
      const maxHeight = rect.height + rect.height * offset;

      this.setHeight(
        element,
        this.attendanceGroupTarget,
        `${rect.height}px`,
        `${maxHeight}px`
      );
    }
  }

  setHeight(
    attendance: HTMLElement,
    attendanceGroup: HTMLElement,
    attendanceHeight: string,
    attendanceGroupHeight: string
  ): void {
    attendance.style.height = attendanceHeight;
    attendanceGroup.style.maxHeight = attendanceGroupHeight;
  }

  verticalPosition(index: number): number {
    const offset = index < 3 ? 80 : 100;
    return index * offset * -1;
  }

  zIndex(count: number, index: number): number {
    return count - index - 1;
  }
}
