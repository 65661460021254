import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["vevo", "residence"];
  static values = { type: String, visaRequired: Boolean };

  declare vevoTarget: HTMLDivElement;
  declare residenceTarget: HTMLDivElement;
  declare typeValue: string;
  declare visaRequiredValue: boolean;
  declare hasResidenceTarget: boolean;

  connect(): void {
    if (this.typeValue == "citizen") {
      this.hideUploadFor(this.vevoTarget);
      if (this.hasResidenceTarget) this.showUploadFor(this.residenceTarget);
    } else if (this.typeValue == "visa_holder") {
      if (this.hasResidenceTarget) this.hideUploadFor(this.residenceTarget);
      this.showUploadFor(this.vevoTarget);
    }
  }

  toggleUploadForm(e): void {
    if (e.currentTarget.value == "citizen") {
      this.hideUploadFor(this.vevoTarget);
      if (this.hasResidenceTarget) this.showUploadFor(this.residenceTarget);
    } else {
      if (this.hasResidenceTarget) this.hideUploadFor(this.residenceTarget);
      this.showUploadFor(this.vevoTarget);
    }
  }

  showUploadFor(target): void {
    target.classList.remove("hidden");
  }

  hideUploadFor(target): void {
    target.classList.add("hidden");
  }
}
