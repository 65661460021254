import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleModalButton", "hideModalButton"];
  declare toggleModalButtonTarget: HTMLElement;
  declare hideModalButtonTarget: HTMLElement;

  private toggleClass = "hidden";

  toggleModal(event: Event): void {
    event.preventDefault();
    const element = event.currentTarget as HTMLElement;
    const modalId = element.dataset.modalId;
    const modal = document.querySelector(`[id='${modalId}']`);

    modal.classList.toggle(this.toggleClass);
  }

  toggleAndSetDataModal(event: Event): void {
    const job = document.querySelector("#job_opening") as HTMLSelectElement;
    const time = document.querySelector("#shift_time") as HTMLInputElement;
    const note = document.querySelector("#additional_note") as HTMLInputElement;
    const selectedIndex = job.selectedIndex;
    const selectedOption = job.options[selectedIndex];
    const selectedValue = selectedOption.value;
    const selectedText = selectedOption.text;
    const location = selectedOption.getAttribute("data-location");
    const wage = selectedOption.getAttribute("data-wage");
    if (!selectedValue || !time) {
      return;
    }
    this.toggleModal(event);
    (document.querySelector("#job_name_message") as HTMLElement).innerText =
      selectedText;
    (document.querySelector("#shift_time_message") as HTMLElement).innerText =
      time.value;
    (document.querySelector("#location_message") as HTMLElement).innerText =
      this.getLocation(location);
    (document.querySelector("#wage_message") as HTMLElement).innerText = wage;
    if (note.value) {
      (document.querySelector("#note_message") as HTMLElement).innerText =
        "Note: " + note.value;
    }
  }

  getLocation(location: string) {
    if (location == "remote") {
      return "Work form home";
    } else if (location == "in_office") {
      return "In office";
    } else {
      return "Hybrid";
    }
  }

  hideModal(event: Event): void {
    event.preventDefault();
    const element = event.currentTarget as HTMLElement;
    const modalId = element.dataset.modalId;
    const modal = document.querySelector(`[id='${modalId}']`);

    modal.classList.add(this.toggleClass);
  }
}
