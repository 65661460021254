import { Controller } from "stimulus";

export default class BaseController extends Controller {
  connect() {
    this.scrollToTheBottom();
  }

  scrollToTheBottom() {
    const scrollObject = document.getElementById("scroll-container");

    if (scrollObject) scrollObject.scrollTop = scrollObject.scrollHeight;
  }
}
