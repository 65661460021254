import { format } from "date-fns";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["display"];

  declare displayTarget: HTMLElement;

  initialize(): void {
    this.displayTarget.innerText = format(new Date(), "dd LLLL yyyy");
  }
}
