import { Controller } from "stimulus";
import Swiper from "swiper";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

type ContainerName = {
  value: string;
};
/*
  1. Attach the controller into view: data-controller="swiper"
  2. Attach the container names right next to data-controller: data-swiper-container-names-value='["job_offer"]'
    2.1 You need to do this to let swiper knows which are the carousel containers that you need to attach swiper to, separated by comma
    2.2 Multiple container names example: data-swiper-container-names-value='["containerA", "conatinerB"]'

  For more options/configs: https://swiperjs.com/swiper-api
*/
export default class extends Controller {
  static values = {
    containerNames: Array,
  };
  declare containerNamesValue: ContainerName[];

  connect() {
    this.containerNamesValue.forEach((containerId) =>
      this.initializeCarousel(containerId)
    );
  }

  initializeCarousel(containerId) {
    new Swiper(`#${containerId}`, {
      modules: [Pagination],
      direction: "horizontal",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        dynamicBullets: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }
}
