import confetti from "canvas-confetti";
import { Controller } from "stimulus";

interface ConfettiInterface {
  particleCount?: number;
  particleSpread?: number;
  particleOrigin: { y?: number; x?: number };
  particleColors: string[];
  particleScalar: number;
  particleTicks: number;
}

export default class extends Controller {
  private animationEnd = Date.now() + 1000;
  private confettiFrequency = 40;
  private particleAngles = [225, 270, 315];
  private confettiOptions = {
    particleCount: 2,
    particleSpread: 80,
    particleOrigin: { y: 0 },
    particleColors: ["#FF7F00", "#8B00FF"],
    particleScalar: 0.7,
    particleTicks: 50,
  };

  static values = { render: String };
  declare renderValue: string;

  connect(): void {
    if (this.renderValue) this.initConfettiAnimation();
  }

  initConfettiAnimation(): void {
    const confettiInterval = setInterval(() => {
      requestAnimationFrame(() => this.renderConfetti(this.particleAngles));
      if (Date.now() > this.animationEnd) clearInterval(confettiInterval);
    }, this.confettiFrequency);
  }

  renderConfetti(particleAngles: number[]): void {
    particleAngles.forEach((particleAngle) => {
      this.generateConfettiParticles(particleAngle, this.confettiOptions);
    });
  }

  generateConfettiParticles(
    particleAngle: number,
    options: ConfettiInterface
  ): void {
    confetti({
      particleCount: options.particleCount,
      spread: options.particleSpread,
      origin: options.particleOrigin,
      colors: options.particleColors,
      angle: particleAngle,
      scalar: options.particleScalar,
      ticks: options.particleTicks,
    });
  }
}
