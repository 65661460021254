import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["vevo"];
  static values = { visaRequired: Boolean };

  declare vevoTarget: HTMLDivElement;
  declare visaRequiredValue: boolean;

  connect(): void {
    this.visaRequiredValue ? this.showVevoUpload() : this.hideVevoUpload();
  }

  showVevoUpload(): void {
    this.vevoTarget.classList.remove("hidden");
  }

  hideVevoUpload(): void {
    this.vevoTarget.classList.add("hidden");
  }
}
