import * as React from "react";
import * as ReactDOM from "react-dom";

import { App } from "./App";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.getElementById("context-data");
  if (!node) return;

  const context = JSON.parse(node.getAttribute("data"));

  ReactDOM.render(
    <React.StrictMode>
      <App backendContext={context} />
    </React.StrictMode>,
    document.getElementById("app")
  );
});
