import { gaSelectTrack } from "helpers/ga_event_tracking";
import { useFormContext } from "react-hook-form";

import graphqlClient from "@graphql/client_app/client";
import { useFetchClientCompanyUsersQuery } from "@graphql/client_app/types";

import { useBackendContext } from "@/client_app/stores/backend-context";

interface setManagerIdProps {
  selectedManagerId: string;
  setSelectedManager: ({ id, name }) => void;
}

function FilterByManager({
  selectedManagerId,
  setSelectedManager,
}: setManagerIdProps): JSX.Element {
  const { register } = useFormContext();
  const { currentClientCompanyUserId } = useBackendContext();

  const { isFetching, data, error } =
    useFetchClientCompanyUsersQuery(graphqlClient);

  if (error) {
    return <div>Error!</div>;
  }

  if (isFetching) {
    return <span>Loading...</span>;
  }

  const clientCompanyUsers = data.clientCompanyUsers.map((user) => [
    user.id,
    user.fullName,
  ]);

  const options = [...clientCompanyUsers, ["all-managers", "Everyone"]];

  const getSelectedManagerName = ({
    selectedManagerId,
    clientCompanyUsers,
    currentClientCompanyUserId,
  }: {
    selectedManagerId: string;
    clientCompanyUsers: string[][];
    currentClientCompanyUserId: string;
  }) => {
    if (selectedManagerId === "all-managers") return "Everyone";
    if (selectedManagerId === currentClientCompanyUserId) return "Me";

    return clientCompanyUsers.find(
      ([userId]) => userId === selectedManagerId
    )[1];
  };

  const onChange = (event: InputEvent): void => {
    const target = event.target as HTMLSelectElement;
    const seletcedManagerIdx = target.selectedIndex;
    const selectedManagerName = target.options[seletcedManagerIdx].text;
    const selectedManagerValue = target.options[seletcedManagerIdx].value;

    setSelectedManager({ id: target.value, name: selectedManagerName });

    gaEventTracking(selectedManagerName, selectedManagerValue);
  };

  const gaEventTracking = (name: string, value: string) => {
    gaSelectTrack({
      select_label: "searchByManager",
      selected_name: name,
      selected_value: value,
    });
  };

  const selectClass =
    "form-select down-icon w-full h-0 border-0 ring-0 bg-transparent absolute cursor-pointer md:block";
  const labelClass = "form-label text-neutral-700 mr-10 mt-2";

  return (
    <div className='inline-flex items-center relative md:mb-0 mb-3'>
      <label className={labelClass} htmlFor='searchByManager'>
        MANAGED BY{" "}
        {getSelectedManagerName({
          selectedManagerId,
          clientCompanyUsers,
          currentClientCompanyUserId,
        }).toUpperCase()}
      </label>
      <select
        defaultValue={selectedManagerId}
        className={selectClass}
        {...register("searchByManager", { onChange: onChange })}
      >
        {options.map(([key, value]) => (
          <option key={key} value={key}>
            {key === currentClientCompanyUserId ? "Me" : value}
          </option>
        ))}
      </select>
    </div>
  );
}

export { FilterByManager };
