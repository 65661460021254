import { useRef, useEffect, ChangeEvent } from "react";

interface CheckboxProps {
  name?: string;
  onChange?: (event: ChangeEvent) => void;
  form?: string;
  state: "CHECKED" | "INDETERMINATE" | "EMPTY";
}

export const CheckboxWithIndeterminateState = ({
  name,
  state = "EMPTY",
  onChange,
  form,
}: CheckboxProps): JSX.Element => {
  const handleChange = (event: ChangeEvent) => {
    if (onChange) {
      onChange(event);
    }
  };

  const checkRef = useRef(null);

  useEffect(() => {
    if (checkRef) {
      checkRef.current.checked = state === "CHECKED";
      checkRef.current.indeterminate = state === "INDETERMINATE";
    }
  });

  return (
    <input
      type='checkbox'
      form={form}
      name={name}
      onChange={handleChange}
      ref={checkRef}
    />
  );
};
