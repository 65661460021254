import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["otherCompanyBenefitContainer", "otherCompanyBenefitInput"];

  static values = {
    otherCompanyBenefitOption: String,
  };

  declare otherCompanyBenefitOptionValue: string;

  declare otherCompanyBenefitContainerTarget: HTMLElement;
  declare otherCompanyBenefitInputTarget: HTMLInputElement;

  private hiddenClass = "hidden";

  handleCompanyBenefitsChange(event): void {
    const selectedCompanyBenefits = event.target.values || [event.target.value];

    if (selectedCompanyBenefits.includes(this.otherCompanyBenefitOptionValue)) {
      this.otherCompanyBenefitContainerTarget.classList.remove(
        this.hiddenClass
      );
    } else {
      this.otherCompanyBenefitContainerTarget.classList.add(this.hiddenClass);
      this.otherCompanyBenefitInputTarget.value = "";
    }
  }
}
