import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "element"];

  declare inputTargets: Array<HTMLInputElement>;
  declare elementTargets: Array<HTMLInputElement>;

  connect(): void {
    const checkedInput = this.inputTargets.find((input) => input.checked);

    if (checkedInput) this.toggleElement(checkedInput.value);
    else this.hideElementTargets();
  }

  handleChange(event): void {
    this.toggleElement(event.target.value);
  }

  toggleElement(value): void {
    this.hideElementTargets();
    const showElements = this.elementTargets.filter((element) => {
      const values = element.dataset.showValues.split(",");
      return values.includes(value);
    });

    showElements.forEach((element) => {
      element.classList.remove("hidden");
      if (element.querySelector("input"))
        element
          .querySelectorAll("input")
          .forEach((input) => (input.disabled = false));
    });
  }

  hideElementTargets(): void {
    this.elementTargets.forEach((element) => {
      element.classList.add("hidden");
      if (element.querySelector("input"))
        element
          .querySelectorAll("input")
          .forEach((input) => (input.disabled = true));
    });
  }
}
