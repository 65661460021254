import { Dispatch } from "react";

import { Input } from "@/client_app/components/Form";
import {
  SelectionReducerAction,
  SelectionActionType,
} from "@/client_app/features/timesheetApproval/utils/operations";
import { useBackendContext } from "@/client_app/stores/backend-context";

interface FilterByWeployeeProps {
  communityMemberName: string;
  setCommunityMemberName: (name: string) => void;
  allSelectedDispatch: Dispatch<SelectionReducerAction>;
}

function FilterByWeployee({
  communityMemberName,
  setCommunityMemberName,
  allSelectedDispatch,
}: FilterByWeployeeProps): JSX.Element {
  const { terminologyEmployee } = useBackendContext();

  const onChange = (event: InputEvent): void => {
    const target = event.target as HTMLInputElement;
    const communityMemberSearchTerm = target.value;
    const pgTrigramMinimumCharacter = 3;

    if (communityMemberSearchTerm.length >= pgTrigramMinimumCharacter) {
      setCommunityMemberName(communityMemberSearchTerm);
      allSelectedDispatch({ type: SelectionActionType.REMOVE_ALL_ATTENDANCES });
    } else {
      setCommunityMemberName("");
    }
  };

  return (
    <div className='my-6'>
      <div className='flex'>
        <div className='w-full md:w-80'>
          <Input
            defaultValue={communityMemberName}
            label={terminologyEmployee + "s Search"}
            name='searchByCommunityMember'
            placeholder={
              "Start typing " + terminologyEmployee + "s name here..."
            }
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
}

export { FilterByWeployee };
