import { show, hide } from "helpers/show_hide";
import { Controller } from "stimulus";
// import { show, hide } from "./helpers/show_hide"

export default class extends Controller {
  static targets = ["otherTopSkillContainer", "otherSkill"];

  declare otherTopSkillContainerTarget: HTMLDivElement;
  declare otherSkillTarget: HTMLInputElement;

  private otherTopSkillValue = "other";

  connect(): void {
    hide(this.otherTopSkillContainerTarget);
  }

  handleTopSkillsChange(event) {
    const topSkills = event.target.values || [event.target.value];

    if (topSkills.includes(this.otherTopSkillValue)) {
      show(this.otherTopSkillContainerTarget);
    } else {
      hide(this.otherTopSkillContainerTarget);
      this.otherSkillTarget.value = "";
    }
  }
}
