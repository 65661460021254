import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "vevoFileThumbnail",
    "vevoFileName",
    "vevoFileUpload",
    "vevoFileField",
    "vevoFileError",
  ];

  static values = {
    vevoFileName: String,
    maximumDocumentFileSize: Number,
    exceedMaxSizeError: String,
  };

  declare vevoFileThumbnailTarget: HTMLDivElement;
  declare vevoFileNameTarget: HTMLSpanElement;
  declare vevoFileUploadTarget: HTMLDivElement;
  declare vevoFileFieldTarget: HTMLInputElement;
  declare vevoFileErrorTarget: HTMLDivElement;
  declare vevoFileNameValue: string;
  declare maximumDocumentFileSizeValue: number;
  declare maxFileSize: number;
  declare exceedMaxSizeErrorValue: string;
  declare exceedMaxSizeError: string;
  private vevoMaximumFileSize = 50_000 as const; // 50kb in bytes
  private exceedMaximumFileSizeErrorMessage =
    "Vevo document too large, please upload a file 50kb or smaller." as const;

  initialize(): void {
    this.maxFileSize =
      this.maximumDocumentFileSizeValue || this.vevoMaximumFileSize;
    this.exceedMaxSizeError =
      this.exceedMaxSizeErrorValue || this.exceedMaximumFileSizeErrorMessage;
  }
  connect(): void {
    this.vevoFileNameValue
      ? this.showVevoFileThumbnail()
      : this.showVevoFileUpload();
  }

  handleSelection(): void {
    const file = this.vevoFileFieldTarget.files.item(0);

    if (file.size < this.maxFileSize) {
      this.vevoFileNameTarget.innerText = file.name;
      this.clearVevoErrorMessage();
      this.showVevoFileThumbnail();
    } else {
      this.vevoFileFieldTarget.value = null;
      this.vevoFileErrorTarget.innerText = this.exceedMaxSizeError;
    }
  }

  handleDelete(): void {
    this.vevoFileFieldTarget.value = null;
    this.clearVevoHiddenFields();
    this.clearVevoErrorMessage();
    this.showVevoFileUpload();
  }

  showVevoFileThumbnail(): void {
    this.vevoFileThumbnailTarget.classList.remove("hidden");
    this.vevoFileUploadTarget.classList.add("hidden");
  }

  showVevoFileUpload(): void {
    this.vevoFileUploadTarget.classList.remove("hidden");
    this.vevoFileThumbnailTarget.classList.add("hidden");
  }

  clearVevoHiddenFields(): void {
    this.vevoFileUploadTarget
      .querySelectorAll("[type='hidden']")
      .forEach((hiddenEl: HTMLInputElement) => {
        hiddenEl.value = null;
      });
  }

  clearVevoErrorMessage(): void {
    this.vevoFileErrorTarget.innerText = null;
  }
}
