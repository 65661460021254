import React, { useState, useEffect, useRef } from "react";

import { Attendance as AttendanceType } from "@graphql/client_app/types";

import { FlaggedAttendancesGroupListItem } from "./FlaggedAttendancesGroupListItem";

interface FlaggedAttendancesGroupListProps {
  attendances: AttendanceType[];
  isGroupExpanded: boolean;
  isMobile: boolean;
  onExpandList: (event: React.MouseEvent) => void;
  onAttendanceToggle: (attendanceId: string) => void;
  checkedAttendances: string[];
}

const FAKE_STACK_VISIBLE_HEIGHT = 10; // 10px

function FlaggedAttendancesGroupList({
  attendances,
  isGroupExpanded,
  isMobile,
  onExpandList,
  onAttendanceToggle,
  checkedAttendances,
}: FlaggedAttendancesGroupListProps): JSX.Element {
  const attendanceItemsRef = useRef([]);

  const [listItemHeight, setListItemHeight] = useState(0);

  useEffect(() => {
    getAndSetListItemHeight();

    window.addEventListener("resize", getAndSetListItemHeight);

    return () => {
      window.removeEventListener("resize", getAndSetListItemHeight);
    };
  });

  function getAndSetListItemHeight(): void {
    const firstListItemtHeight = getFirstItemHeight(attendanceItemsRef.current);

    setListItemHeight(firstListItemtHeight);
  }

  function getFirstItemHeight(attendanceItemsRef: HTMLDivElement[]): number {
    return attendanceItemsRef[0].offsetHeight;
  }

  const renderFakeStack = (numberOfAttendanceItems: number): JSX.Element => {
    if (isMobile || numberOfAttendanceItems === 1 || isGroupExpanded) return;

    const cardClassName =
      "absolute w-full top-0 left-0 border-neutral-300 border-2 rounded-xl bg-shade-50 origin-top-left";

    const style = {
      height: `${listItemHeight}px`,
    };

    const stack = [
      <div
        key='fake-item-1'
        style={style}
        className={`${cardClassName} translate-y-[10px] z-20`}
      ></div>,
      <div
        key='fake-item-2'
        style={style}
        className={`${cardClassName} translate-y-[20px] z-10`}
      ></div>,
    ];

    if (numberOfAttendanceItems === 2) {
      return stack[0];
    }

    return <>{stack}</>;
  };

  const listContainerHeight =
    attendances.length === 1
      ? listItemHeight + FAKE_STACK_VISIBLE_HEIGHT
      : listItemHeight + FAKE_STACK_VISIBLE_HEIGHT * 2;
  const desktopStyle = {
    height:
      isGroupExpanded || attendances.length === 1
        ? "auto"
        : `${listContainerHeight}px`,
  };
  const mobileStyle = {
    height: isGroupExpanded ? "auto" : `${listItemHeight}px`,
  };

  return (
    <div style={isMobile ? mobileStyle : desktopStyle} className='relative'>
      {renderFakeStack(attendances.length)}
      {attendances.map((attendance: AttendanceType, index) => (
        <FlaggedAttendancesGroupListItem
          attendance={attendance}
          attendancesCount={attendances.length}
          stackOrder={index + 1}
          isGroupExpanded={isGroupExpanded}
          isMobile={isMobile}
          minimumEngagementRequired={attendance.minimumEngagementRequired}
          minimumEngagementDurationInseconds={
            attendance.minimumEngagementDuration.valueInSeconds
          }
          humanReadableMinimumEngagementDuration={
            attendance.minimumEngagementDuration.formatted
          }
          belowMinimumEngagementReasonToHuman={
            attendance.belowMinimumEngagementReasonToHuman
          }
          hasPublicHoliday={attendance.hasPublicHoliday}
          isResubmitted={attendance.resubmitted}
          key={attendance.id}
          ref={(el) => (attendanceItemsRef.current[index] = el)}
          onExpandList={onExpandList}
          onAttendanceToggle={onAttendanceToggle}
          checkedAttendances={checkedAttendances}
          workedThroughBreak={attendance.workedThroughBreak}
        />
      ))}
    </div>
  );
}

export { FlaggedAttendancesGroupList };
