import React from "react";

interface CheckboxProps {
  name?: string;
  checked?: boolean;
  id?: string;
  disabled?: boolean;
  form?: string;
  onChange?: (e) => void;
}

export const Checkbox = ({
  name,
  checked,
  disabled,
  form,
  id,
  onChange,
}: CheckboxProps): JSX.Element => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <input
      type='checkbox'
      form={form}
      name={name}
      onChange={handleChange}
      checked={checked}
      id={id}
      disabled={disabled}
    />
  );
};
