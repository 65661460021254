import { CommunityMemberAvatar } from "@/client_app/components/CommunityMemberAvatar";

interface CommunityMemberType {
  fullName: string;
  preferredName: string;
  profileImageUrl?: string;
}

interface ApproveAttendancesProfileProps {
  workedDates: string;
  communityMember: CommunityMemberType;
}

function ApproveAttendancesProfile({
  workedDates,
  communityMember,
}: ApproveAttendancesProfileProps): JSX.Element {
  return (
    <div className='flex flex-col gap-y-4 mt-8 justify-between md:mt-10 md:flex-row'>
      <div className='flex gap-x-4'>
        <div className='hidden md:block'>
          <CommunityMemberAvatar
            profileImageUrl={communityMember.profileImageUrl}
            name={communityMember.fullName}
            size='LARGE'
          />
        </div>
        <div className='md:hidden'>
          <CommunityMemberAvatar
            profileImageUrl={communityMember.profileImageUrl}
            name={communityMember.fullName}
            size='SMALL'
          />
        </div>
        <div className='text-primary-900'>
          <h4 className='p1 font-semibold mb-1 md:text-subheading'>
            {communityMember.fullName}
          </h4>
          <p className='p1 font-normal text-neutral-500 md:p3'>{workedDates}</p>
        </div>
      </div>
      <div className='p1 order-first font-semibold text-primary-900 md:text-subheading md:order-last'>
        Time Period: {workedDates}
      </div>
    </div>
  );
}

export { ApproveAttendancesProfile };
