import { GraphQLClient } from "graphql-request";
import { tenantPath } from "helpers/tenant_path";

const client = new GraphQLClient(tenantPath("/weployer/graphql"), {
  headers: {
    "Content-Type": "application/json",
  },
});

export default client;
