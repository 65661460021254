import { useState, useEffect } from "react";

import graphqlClient from "@graphql/organization_app/client";
import {
  AttendanceCostItem as AttendanceCostItemType,
  useFetchAttendanceCostItemsSummaryQuery,
} from "@graphql/organization_app/types";

import { PreviewInvoicesBillingForm } from "./PreviewInvoicesBillingForm";
import { PreviewInvoicesTable } from "./PreviewInvoicesTable";

export const PreviewInvoices = ({
  clientCompany,
  attendanceIds,
  closeModal,
}): JSX.Element => {
  const [attendanceCostItems, setAttendanceCostItems] = useState(
    [] as AttendanceCostItemType[]
  );
  const [selectedAttendanceIds, setSelectedAttendanceIds] =
    useState(attendanceIds);
  const [paymentTermSurcharge, setPaymentTermSurcharge] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalHoursWorked, setTotalHoursWorked] = useState(0);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(true);

  const {
    isFetching: attendanceCostItemsSummaryFetching,
    isSuccess: attendanceCostItemsSummarySuccess,
    data: attendanceCostItemsSummaryData,
    error: attendanceCostItemsSummaryError,
    refetch: refetchAttendanceCostItemsSummary,
  } = useFetchAttendanceCostItemsSummaryQuery(
    graphqlClient,
    {
      attendanceIds: selectedAttendanceIds,
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (attendanceCostItemsSummarySuccess && attendanceCostItemsSummaryData) {
      setAttendanceCostItems(
        attendanceCostItemsSummaryData.attendanceCostItemsSummary
          .attendanceCostItems
      );
      setTotalHoursWorked(
        attendanceCostItemsSummaryData.attendanceCostItemsSummary
          .totalHoursWorked
      );
      setPaymentTermSurcharge(
        attendanceCostItemsSummaryData.attendanceCostItemsSummary
          .paymentTermSurcharge
      );
      setGrandTotal(
        attendanceCostItemsSummaryData.attendanceCostItemsSummary.grandTotal
      );
    }
  }, [attendanceCostItemsSummaryData]);

  const removeAttendanceCostItem = (id: string) => {
    const newSelectedAttendanceIds = attendanceCostItems.flatMap(
      (attendanceItem) =>
        attendanceItem.attendanceId === id ? [] : attendanceItem.attendanceId
    );
    setSelectedAttendanceIds(newSelectedAttendanceIds);
    setShowLoadingIndicator(false);
    refetchAttendanceCostItemsSummary();
  };

  const handleCloseModal = () => {
    setShowLoadingIndicator(true);
    closeModal();
  };

  if (attendanceCostItemsSummaryError) {
    return <div>Error!</div>;
  }

  if (attendanceCostItemsSummaryFetching && showLoadingIndicator) {
    return <span>Loading...</span>;
  }

  return (
    <div>
      <div className='my-8 text-xl'>Confirm Attendances to be Invoiced</div>
      <div className='flex items-start space-x-20'>
        <PreviewInvoicesTable
          attendanceCostItems={attendanceCostItems}
          totalHoursWorked={totalHoursWorked}
          paymentTermSurcharge={paymentTermSurcharge}
          grandTotal={grandTotal}
          removeAttendanceCostItem={removeAttendanceCostItem}
        />
        <PreviewInvoicesBillingForm
          clientCompany={clientCompany}
          closeModal={handleCloseModal}
          attendanceCostItems={attendanceCostItems}
        />
      </div>
    </div>
  );
};
