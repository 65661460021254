import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    conversationUrl: String,
  };

  declare conversationUrlValue: string;

  showConversation(e: React.FormEvent<HTMLFormElement>) {
    this.toggleSelectedMessageStyle(e);

    Rails.ajax({
      type: "get",
      url: this.conversationUrlValue,
      dataType: "json",
      success: ({ replace_content }) => {
        const messageContainer = document.getElementById(
          "conversations_container"
        );

        if (messageContainer) {
          document.getElementById("conversations_container").innerHTML =
            replace_content.conversations_container;
        }
      },
    });
  }

  toggleSelectedMessageStyle(e: React.FormEvent<HTMLFormElement>) {
    const activeElement =
      document.getElementsByClassName("message-row active")[0];
    if (activeElement) {
      activeElement.classList.remove("bg-neutral-200", "active");
    }

    e.currentTarget.classList.add("bg-neutral-200", "active");
  }
}
