import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["container", "parentContainer"];

  declare isOpen: boolean;
  declare openClass: string;
  declare currentCallerId: string;
  declare unactiveClasses: Array<string>;
  declare activeClasses: Array<string>;
  declare containerTarget: HTMLElement;
  declare parentContainerTarget: HTMLElement;
  declare bgActiveClass: Array<string>;

  initialize() {
    this.isOpen = false;
    this.openClass = "is-open";
    this.currentCallerId = null;
    this.unactiveClasses = ["hover:bg-ui-25", "bg-surface"];
    this.activeClasses = [
      "active-target",
      "bg-brand-tertiary-50",
      "hover:bg-brand-tertiary-100",
    ];
    this.bgActiveClass = [
      "fixed",
      "top-0",
      "left-0",
      "w-screen",
      "h-screen",
      "bg-opacity-50",
      "bg-legacyColor-neutral-1-900",
      "flex",
      "justify-center",
      "items-center",
      "z-[999]",
      "overflow-y-auto",
    ];
  }

  disconnect() {
    this.close();
  }

  open(callerId) {
    this.containerTarget.classList.add(this.openClass);
    this.currentCallerId = callerId;
    this.isOpen = true;
    this.parentContainerTarget.classList.add(...this.bgActiveClass);
  }

  close() {
    this.containerTarget.classList.remove(this.openClass);
    this.currentCallerId = null;
    this.isOpen = false;
    this.parentContainerTarget.classList.remove(...this.bgActiveClass);
  }

  closePanel() {
    this.close();
  }

  toggle(e) {
    e.preventDefault();
    const callerId = e.currentTarget.id;

    if (!this.currentCallerId) {
      this.open(callerId);
      return;
    } else if (callerId === this.currentCallerId) {
      this.close();
      return;
    }
    this.currentCallerId = callerId;
  }
}
