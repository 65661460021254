import i18next from "i18next";
import "@/i18n/config";
import { useCallback } from "react";
import SVG from "react-inlinesvg";

import ComputerAndPlantImageUrl from "@assets/illustrations/computer_and_plant.svg";

interface NoActivePositionsProps {
  noSearchResults: boolean;
  noActivePositionImageURL: string;
}

const NoActivePositions = ({
  noSearchResults,
  noActivePositionImageURL,
}: NoActivePositionsProps): JSX.Element => {
  let heading;
  let textContent;

  if (noSearchResults) {
    heading = "No matching positions found";
    textContent = "Try searching for someone different";
  } else {
    heading = "No active positions";
    textContent = i18next.t("clientApp.noActivePositionsMessage", {
      terminologyEmployee: window.terminologyEmployee,
    });
  }
  const customStyles = {
    height: "200px",
    width: "100%",
  };
  const renderImage = useCallback(() => {
    if (noActivePositionImageURL) {
      return (
        <img
          src={noActivePositionImageURL}
          alt='Empty Stage Image'
          style={customStyles}
        />
      );
    } else {
      return <SVG src={ComputerAndPlantImageUrl} width='100%' height='200' />;
    }
  }, [noActivePositionImageURL]);
  return (
    <div className='mt-8 text-neutral-800 flex justify-center'>
      <div className='text-center'>
        {renderImage()}
        <h4 className='font-bold mb-3 mt-7'>{heading}</h4>
        <p className='font-semibold text-subheading'>{textContent}</p>
      </div>
    </div>
  );
};

export { NoActivePositions };
