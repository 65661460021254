import { useFormContext } from "react-hook-form";

interface OptionType {
  value: string;
  label: string;
}

interface SelectProps {
  onChange?: (event: InputEvent) => void;
  options: Array<OptionType>;
  name: string;
  id: string;
  defaultValue?: string | number;
  prompt?: string;
}

export const Select = ({
  name,
  defaultValue,
  onChange,
  options,
  id = null,
  prompt = null,
}: SelectProps): JSX.Element => {
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext();

  const promptIsVisible = getValues(name) == undefined;

  return (
    <div className={errors[name] && "field_with_errors"}>
      <select
        id={id}
        className={`form-select ${
          prompt && promptIsVisible && "text-neutral-600"
        }`}
        defaultValue={defaultValue}
        {...register(name, { onChange: onChange })}
      >
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
        {prompt && (
          <option hidden={true} disabled={true} value=''>
            {prompt}
          </option>
        )}
      </select>
    </div>
  );
};
