import { useReducer } from "react";

import { useAttendanceApprovalOutletContext } from "./AttendanceApproval";
import { FilterByWeployee } from "./FilterByWeployee";
import { FlaggedAttendancesContainer } from "./FlaggedAttendancesContainer";

import { Form } from "@/client_app/components/Form";
import { selectedAttendancesReducer } from "@/client_app/features/timesheetApproval/utils/operations";
import { useDebouncedState } from "@/hooks/useDebouncedState";

export const FlaggedAttendances = (): JSX.Element => {
  const { selectedManagerId, refetchAllFlaggedAttendances } =
    useAttendanceApprovalOutletContext();
  const [communityMemberName, setCommunityMemberName] = useDebouncedState(
    "",
    500
  );
  const [selectedAttendancesIds, selectedDispatch] = useReducer(
    selectedAttendancesReducer,
    []
  );

  return (
    <div>
      <Form>
        <FilterByWeployee
          allSelectedDispatch={selectedDispatch}
          communityMemberName={communityMemberName}
          setCommunityMemberName={(name: string) =>
            setCommunityMemberName(name)
          }
        />
      </Form>
      <FlaggedAttendancesContainer
        communityMemberName={communityMemberName}
        managerId={selectedManagerId}
        selectedAttendancesIds={selectedAttendancesIds}
        allSelectedDispatch={selectedDispatch}
        refetchAllFlaggedAttendances={refetchAllFlaggedAttendances}
        noTimesheetsImageUrl={window.noTimesheetsImageUrl}
      />
    </div>
  );
};
