import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["countdownParent", "countdown", "link"];
  static values = {
    remainingTime: String,
  };

  declare countdownParentTarget: HTMLDivElement;
  declare countdownTarget: HTMLSpanElement;
  declare linkTarget: HTMLInputElement;
  declare remainingTimeValue: string;

  connect() {
    this.startCountdown();
  }

  startCountdown() {
    let remainingTime = Number(this.remainingTimeValue) as number;
    this.updateCountdown(remainingTime);

    const countdownInterval = setInterval(() => {
      remainingTime--;
      this.updateCountdown(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(countdownInterval);
        this.hideCountdown();
        this.enableLink();
      }
    }, 1000);
  }

  updateCountdown(remainingTime) {
    this.countdownTarget.innerText = remainingTime;
  }

  hideCountdown() {
    this.countdownParentTarget.classList.add("hidden");
  }

  enableLink() {
    this.linkTarget.classList.remove("hidden");
  }
}
