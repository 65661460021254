import { format } from "date-fns";
import pluralize from "pluralize";

import {
  Attendance as AttendanceType,
  MinimumEngagementDuration,
} from "@graphql/client_app/types";

type GroupAttendancesByPositionType = {
  [index: string]: AttendanceType[];
};

interface AttendanceWithPosition {
  id: string;
  position: { id: string; title: string };
}
export const groupAttendancesByPosition = <
  TAttendance extends AttendanceWithPosition
>(
  attendances: TAttendance[]
): GroupAttendancesByPositionType => {
  return attendances.reduce(
    (accumulator, attendance: TAttendance): GroupAttendancesByPositionType => {
      const positionId = attendance.position.id;
      const existingAttendanceGrouping = accumulator[positionId];

      existingAttendanceGrouping
        ? existingAttendanceGrouping.push(attendance)
        : (accumulator[positionId] = [attendance]);

      return accumulator;
    },
    {}
  );
};

export const groupAttendancesIdsByPosition = <
  TAttendance extends AttendanceWithPosition
>(
  attendances: TAttendance[]
): { [index: string]: string[] } => {
  return attendances.reduce(
    (accumulator, attendance: TAttendance): { [index: string]: string } => {
      const positionId = attendance.position.id;
      const existingAttendanceGrouping = accumulator[positionId];

      existingAttendanceGrouping
        ? existingAttendanceGrouping.push(attendance.id)
        : (accumulator[positionId] = [attendance.id]);

      return accumulator;
    },
    {}
  );
};

export const getDateWorkedOn = (date: string): string => {
  return format(new Date(date), "EEEE d LLL yyyy");
};

export const getAttendanceTimes = (
  startAt: string,
  finishAt: string
): string => {
  const formattedStartAt = format(new Date(startAt), "h:mmaaa");
  const formattedFinishAt = format(new Date(finishAt), "h:mmaaa");
  return `${formattedStartAt} - ${formattedFinishAt}`;
};

type DurationSecond = number;
interface BreakTimesInterface {
  startTime: string;
  finishTime: string;
  duration: DurationSecond;
}

export const getBreakTimes = (breaks?: BreakTimesInterface[]): string[] => {
  if (breaks.length == 0) return ["-"];

  return breaks.reduce((accumulator, brk) => {
    const startAt = brk.startTime;
    const finishAt = brk.finishTime;
    accumulator.push(`${startAt} - ${finishAt}`);

    return accumulator;
  }, []);
};

export const getDailyTotal = (
  durationSeconds: number,
  breaks?: BreakTimesInterface[]
): string => {
  const breakDurationSeconds = getBreakDuration(breaks);
  const durationExcludingBreaks = durationSeconds - breakDurationSeconds;

  return humanizeSecondsWorked(durationExcludingBreaks);
};

interface AttendanceWithDurationAndBreaks {
  duration: number;
  breaks?: BreakTimesInterface[];
  minimumEngagementDuration: MinimumEngagementDuration;
}

export const getTotalSecondsWorked = <
  TAttendance extends AttendanceWithDurationAndBreaks
>(
  attendances: TAttendance[]
): number => {
  const totalSecondsWorked = attendances.reduce((sum, attendance) => {
    const breakDuration = getBreakDuration(attendance.breaks);
    const durationExcludingBreaks = attendance.duration - breakDuration;
    const minimumEngagementDuration =
      attendance.minimumEngagementDuration.valueInSeconds;
    return (sum += durationExcludingBreaks + minimumEngagementDuration);
  }, 0);

  return totalSecondsWorked;
};

export const getTotalHoursWorked = <
  TAttendance extends AttendanceWithDurationAndBreaks
>(
  attendances: TAttendance[]
): string => {
  const totalSecondsWorked = getTotalSecondsWorked(attendances);

  const totalHoursWorked = (totalSecondsWorked / 3600).toFixed(1);
  return pluralize("hr", Number.parseFloat(totalHoursWorked), true);
};

export const getTotalHoursAndMinutesWorked = <
  TAttendance extends AttendanceWithDurationAndBreaks
>(
  attendances: TAttendance[]
): string => {
  const totalSecondsWorked = getTotalSecondsWorked(attendances);
  return humanizeSecondsWorked(totalSecondsWorked);
};

export const humanizeSecondsWorked = (secondsWorked: number): string => {
  const hours = Math.floor(secondsWorked / 3600);
  const minutes = (secondsWorked % 3600) / 60;

  const hoursString = pluralize("hr", hours, true);
  const minutesString = pluralize("min", minutes, true);

  return `${hoursString} ${minutes ? minutesString : ""}`;
};

const getBreakDuration = (breaks: BreakTimesInterface[]): number => {
  return breaks.reduce((sum, brk): number => sum + brk.duration, 0);
};

export const enum SelectionActionType {
  ADD_TO_SELECTED = "ADD_TO_SELECTED",
  REMOVE_FROM_SELECTED = "REMOVE_FROM_SELECTED",
  ADD_ALL_ATTENDANCES = "ADD_ALL_ATTENDANCES",
  REMOVE_ALL_ATTENDANCES = "REMOVE_ALL_ATTENDANCES",
}

export type SelectionReducerAction = {
  type: SelectionActionType;
  attendanceIds?: string[];
};

export const selectedAttendancesReducer = (
  state: string[],
  action: SelectionReducerAction
): string[] => {
  switch (action.type) {
    case SelectionActionType.ADD_TO_SELECTED:
      return [...state, ...action.attendanceIds];
    case SelectionActionType.REMOVE_FROM_SELECTED:
      return state.filter((selected) => {
        return !action.attendanceIds.includes(selected);
      });
    case SelectionActionType.ADD_ALL_ATTENDANCES:
      return action.attendanceIds;
    case SelectionActionType.REMOVE_ALL_ATTENDANCES:
      return [];
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};
