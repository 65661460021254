import i18next from "i18next";
import "@/i18n/config";
import Flatpickr from "react-flatpickr";
import { useParams } from "react-router-dom";

import graphqlClient from "@graphql/organization_app/client";
import { useFetchParamsForFilterAttendanceQuery } from "@graphql/organization_app/types";

import {
  Input,
  Select,
  buildOptions,
} from "@/organization_app/components/formFields";
import { formatISO8601Date } from "@/organization_app/features/invoicings/utils/AttendanceUtils";

export const AttendancesForInvoicingFilter = ({
  communityMemberName,
  setCommunityMemberName,
  selectedManager,
  setSelectedManager,
  selectedSubdivision,
  setSubdivision,
  setDateRange,
  selectedPositionTitle,
  setSelectedPositionTitle,
}): JSX.Element => {
  const { clientCompanyId } = useParams();
  const dateFormat = "d/m/Y";
  const onChangeWeployeeName = (event: InputEvent): void => {
    const target = event.target as HTMLInputElement;
    const communityMemberSearchTerm = target.value;
    const pgTrigramMinimumCharacter = 3;
    const textToSet =
      communityMemberSearchTerm.length >= pgTrigramMinimumCharacter
        ? communityMemberSearchTerm
        : "";

    setCommunityMemberName(textToSet);
  };

  const onChangeManager = (event: InputEvent): void => {
    const target = event.target as HTMLSelectElement;
    const managerId = target.value;
    setSelectedManager(managerId);
  };
  const onChangeSubdivision = (event: InputEvent): void => {
    const target = event.target as HTMLSelectElement;
    const subdivisionId = target.value;
    setSubdivision(subdivisionId);
  };
  const onCloseDatePicker = (selectedDates: Date[]): void => {
    const startAt = formatISO8601Date(selectedDates[0]);
    const finishAt = formatISO8601Date(selectedDates[1]);

    setDateRange({ startAt: startAt, finishAt: finishAt });
  };
  const onPositionTitleSelected = (event: InputEvent): void => {
    const target = event.target as HTMLSelectElement;
    setSelectedPositionTitle(target.value);
  };
  const { isFetching, data, error } = useFetchParamsForFilterAttendanceQuery(
    graphqlClient,
    { clientCompanyId: clientCompanyId }
  );

  if (error) {
    return <div>Error!</div>;
  }

  if (isFetching) {
    return <span>Loading...</span>;
  }

  const uniqueLocations = [
    ...new Map(
      data.paramsForFilterAttendance.locations.map((item) => [
        item.subdivision,
        item,
      ])
    ).values(),
  ];

  const locationOptions = buildOptions(
    uniqueLocations,
    "subdivision",
    "subdivisionLabel"
  );

  const managerOptions = buildOptions(
    data.paramsForFilterAttendance.companyUsers,
    "id",
    "fullName"
  );

  const positionTitleOptions =
    data.paramsForFilterAttendance.positionTitles.map((title) => ({
      value: title,
      label: title,
    }));

  return (
    <>
      <div className='flex mb-8'>
        <div className='w-80 my-4 mr-4'>
          <label className='form-label' htmlFor={"searchByCommunityMember"}>
            Search
          </label>
          <Input
            defaultValue={communityMemberName}
            name='searchByCommunityMember'
            placeholder={i18next.t("organizationApp.startTypingWeployees", {
              terminologyEmployee: window.terminologyEmployee,
            })}
            onChange={onChangeWeployeeName}
          />
        </div>
        <div className='w-60 my-4 mr-4'>
          <label className='form-label' htmlFor='dates'>
            Date Period
          </label>
          <Flatpickr
            className='form-control'
            onClose={onCloseDatePicker}
            name='dates'
            options={{
              mode: "range",
              dateFormat: dateFormat,
            }}
            placeholder='Select date range'
          />
        </div>
        <div className='w-56 my-4 mr-4'>
          <label className='form-label' htmlFor={"subdivision"}>
            State
          </label>
          <Select
            name='subdivision'
            id='subdivision'
            defaultValue={selectedSubdivision}
            onChange={onChangeSubdivision}
            options={locationOptions}
            prompt={"Select state"}
          />
        </div>
        <div className='w-56 my-4 mr-4'>
          <label className='form-label' htmlFor={"manager_id_is"}>
            Manager
          </label>
          <Select
            name='manager_id_is'
            id='manager_id_is'
            defaultValue={selectedManager}
            onChange={onChangeManager}
            options={[{ value: "", label: "Everyone" }, ...managerOptions]}
          />
        </div>
        <div className='w-56 my-4 mr-4'>
          <label className='form-label' htmlFor={"position_title"}>
            Job
          </label>
          <Select
            name='position_title'
            id='position_title'
            defaultValue={selectedPositionTitle}
            onChange={onPositionTitleSelected}
            options={[{ value: "", label: "All" }, ...positionTitleOptions]}
          />
        </div>
      </div>
    </>
  );
};
