import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  declare ckEditor: any;

  declare inputTarget: HTMLAreaElement;

  initialize() {
    this.ckEditor = null;
  }

  connect(): void {
    this.ckEditor = ClassicEditor.create(this.inputTarget).catch((error) => {
      console.error(error);
    });
  }

  disconnect(): void {
    if (this.ckEditor) {
      this.ckEditor.destroy();
    }
  }
}
