// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.ts.

import { Application } from "stimulus";
import Flatpickr from "stimulus-flatpickr";
import Popover from "stimulus-popover";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import { Dropdown, Toggle } from "tailwindcss-stimulus-components";

require("flatpickr/dist/flatpickr.css");

const application = Application.start();
const context = require.context("controllers", true, /_controller\.ts$/);
application.load(definitionsFromContext(context));

application.register("flatpickr", Flatpickr);
application.register("dropdown", Dropdown);
application.register("toggle", Toggle);
application.register("popover", Popover);
