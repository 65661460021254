import { Routes, Route } from "react-router-dom";

import { NotFound } from "@/client_app/components/NotFound";
import {
  monashTimesheetApprovalRoutes,
  timesheetApprovalRoutes,
} from "@/client_app/features/timesheetApproval/routes";
import {
  studentsRoutes,
  weployeesRoutes,
} from "@/client_app/features/weployees/routes";

export const AppRoutes = (): JSX.Element => {
  return (
    <Routes>
      {timesheetApprovalRoutes}
      {monashTimesheetApprovalRoutes}
      {weployeesRoutes}
      {studentsRoutes}
      <Route path='/weployer/timesheets/*' element={<NotFound />} />
      <Route path='/weployer/weployees/*' element={<NotFound />} />
    </Routes>
  );
};
