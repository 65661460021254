import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  static values = {
    submitUrl: String,
    clientEmail: String,
  };

  declare formTarget: HTMLFormElement;
  declare submitUrlValue: string;
  declare clientEmailValue: string;

  submitForm(e): void {
    e.preventDefault();

    const formData = new FormData(this.formTarget);
    formData.append("draft_job_opening[client_email]", this.clientEmailValue);

    Rails.ajax({
      type: "post",
      url: this.submitUrlValue,
      data: formData,
      dataType: "json",
    });
  }
}
