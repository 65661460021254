import BoringAvatar from "boring-avatars";

interface CommunityMemberAvatarProps {
  profileImageUrl: string;
  name: string;
  size: "EXTRA_SMALL" | "SMALL" | "MEDIUM" | "LARGE";
}

function CommunityMemberAvatar({
  profileImageUrl,
  name,
  size,
}: CommunityMemberAvatarProps): JSX.Element {
  const customAvatarSize = {
    EXTRA_SMALL: "h-8 w-8",
    SMALL: "h-12 w-12",
    MEDIUM: "h-16 w-16",
    LARGE: "h-20 w-20",
  };
  const boringAvatarSize = {
    EXTRA_SMALL: 32,
    SMALL: 48,
    MEDIUM: 64,
    LARGE: 80,
  };
  const boringAvatarColors = [
    "#BBF0E9",
    "#3FB6A1",
    "#91E7DC",
    "#50D0BD",
    "#2C7662",
  ];

  {
    return profileImageUrl ? (
      <div className={`rounded-full overflow-hidden ${customAvatarSize[size]}`}>
        <img src={`${profileImageUrl}`} />
      </div>
    ) : (
      <BoringAvatar
        variant='beam'
        name={name}
        size={boringAvatarSize[size]}
        colors={boringAvatarColors}
      />
    );
  }
}

export { CommunityMemberAvatar };
