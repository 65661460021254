import {
  FieldValues,
  SubmitHandler,
  useForm,
  FormProvider,
} from "react-hook-form";

interface FormProps {
  defaultValues?: { [key: string]: string };
  children: React.ReactElement | React.ReactElement[];
  onSubmit?: SubmitHandler<FieldValues>;
}

export const Form = ({
  defaultValues,
  children,
  onSubmit = () => null,
}: FormProps): JSX.Element => {
  const methods = useForm({ defaultValues, shouldUnregister: true });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};
