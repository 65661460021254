export const downloadFileFromAjaxResponse = (content, filename, type) => {
  if (!filename) return;

  const blob = new Blob([content], { type: type });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const downloadElement = document.createElement("a");
  downloadElement.href = url;
  downloadElement.setAttribute("download", filename);
  downloadElement.click();
};
