import Cropper from "cropperjs";
import Dropzone from "dropzone";
import { Controller } from "stimulus";

import { CreateDirectUpload } from "../helpers/create_direct_upload";

interface ICustomFile extends File {
  isCropped?: boolean;
}

interface ICustomDropzoneFile extends Dropzone.DropzoneFile {
  isCropped?: boolean;
  existingFile?: boolean;
}

export interface CustomDropzone extends Dropzone {
  _enqueueThumbnail?: (arg: Dropzone.DropzoneFile) => void;
}

export default class extends Controller {
  static targets = [
    "upload",
    "crop",
    "uploadContainer",
    "cropContainer",
    "croppedImageHiddenInput",
    "sourceImageHiddenInput",
    "croppedCanvasDataHiddenInput",
    "editButton",
    "error",
    "errorAlert",
    "skipButton",
    "nextButton",
    "cropperCancelButton",
  ];

  static values = {
    croppedProfileImageUrl: String,
    sourceProfileImageUrl: String,
    thumbnailWidthHeight: Number,
    acceptedFiles: String,
    acceptedMaximumFiles: Number,
    acceptedMaximumFileSize: Number,
    resizeWidth: Number,
    resizeMimeType: String,
    directUploadUrl: String,
    croppedCanvasData: String,
  };

  declare uploadTarget: HTMLDivElement;
  declare cropTarget: HTMLImageElement;
  declare uploadContainerTarget: HTMLDivElement;
  declare cropContainerTarget: HTMLDivElement;
  declare croppedImageHiddenInputTarget: HTMLInputElement;
  declare sourceImageHiddenInputTarget: HTMLInputElement;
  declare croppedCanvasDataHiddenInputTarget: HTMLInputElement;
  declare errorTarget: HTMLDivElement;
  declare errorAlertTarget: HTMLDivElement;
  declare skipButtonTarget: HTMLAnchorElement;
  declare hasSkipButtonTarget: boolean;
  declare nextButtonTarget: HTMLButtonElement;
  declare editButtonTarget: HTMLDivElement;
  declare cropperCancelButtonTarget: HTMLDivElement;

  declare thumbnailWidthHeightValue: number;
  declare acceptedFilesValue: string;
  declare acceptedMaximumFilesValue: number;
  declare acceptedMaximumFileSizeValue: number;
  declare resizeWidthValue: number;
  declare resizeMimeTypeValue: string;
  declare croppedProfileImageUrlValue: string;
  declare sourceProfileImageUrlValue: string;
  declare directUploadUrlValue: string;
  declare croppedCanvasDataValue: string;
  declare hasCroppedCanvasDataValue: boolean;

  private imageHiddenInput: HTMLInputElement;
  private dropzone: CustomDropzone;
  private cropper: Cropper;
  private defaultThumbnailName = "name" as const;
  private defaultThumbnailSize = 0 as const;
  private cropperViewMode = 3 as const; // fill fit image to container
  private cropperDragMode = "move" as const;
  private cropperAspectRatio = 1 as const;
  private defaultCroppedFilename = "cropped.jpeg" as const;

  connect(): void {
    this.initDropzone();
    this.bindEvents();
    this.setDictionary();
    if (this.croppedProfileImageUrlValue) {
      this.renderUploadedImage();
      this.showEditButton();
    } else {
      if (this.hasSkipButtonTarget) {
        this.skipButtonTarget.classList.remove("hidden");
        this.nextButtonTarget.classList.add("hidden");
      }
    }
  }

  initDropzone(): void {
    this.imageHiddenInput = this.sourceImageHiddenInputTarget;

    this.dropzone = new Dropzone(this.uploadTarget, {
      headers: { "X-CSRF-Token": this.getCSRFToken() },
      url: this.directUploadUrlValue,
      acceptedFiles: this.acceptedFilesValue,
      maxFiles: this.acceptedMaximumFilesValue,
      maxFilesize: this.acceptedMaximumFileSizeValue, // in mb
      thumbnailWidth: this.thumbnailWidthHeightValue,
      thumbnailHeight: this.thumbnailWidthHeightValue,
      resizeWidth: this.resizeWidthValue,
      resizeMimeType: this.resizeMimeTypeValue,
      createImageThumbnails: false,
      autoQueue: false,
    });
  }

  bindEvents(): void {
    this.dropzone.on("addedfile", (file) => {
      Promise.resolve(file).then((file: ICustomDropzoneFile) => {
        if (!file.isCropped)
          this.imageHiddenInput = this.sourceImageHiddenInputTarget;

        if (this.dropzone.getAcceptedFiles().length === 1 && !file.existingFile)
          this.resizeImage(file);
      });
    });

    this.dropzone.on("removedfile", () => {
      if (this.hasSkipButtonTarget) {
        this.skipButtonTarget.classList.remove("hidden");
        this.nextButtonTarget.classList.add("hidden");
      }
      this.hideEditButton();
    });

    this.dropzone.on("success", (file: ICustomDropzoneFile) => {
      if (!file.isCropped) {
        this.showCropperContainer({ showCancelButton: false });
        this.initCropper(URL.createObjectURL(file));
      }

      if (this.hasSkipButtonTarget) {
        this.skipButtonTarget.classList.add("hidden");
        this.nextButtonTarget.classList.remove("hidden");
      }
      this.showEditButton();
    });

    this.dropzone.on("error", (file, message: string) => {
      this.dropzone.removeAllFiles();
      this.showErrorAlert();
      this.errorTarget.innerText = message;
    });

    this.cropTarget.addEventListener("ready", () => {
      const file: ICustomDropzoneFile = this.dropzone.getAcceptedFiles()[0];

      if (file.existingFile && this.hasCroppedCanvasDataValue) {
        this.cropper.setCanvasData(JSON.parse(this.croppedCanvasDataValue));
      }
    });
  }

  cropFile(): void {
    const file: ICustomDropzoneFile = this.dropzone.getAcceptedFiles()[0];

    if (file.existingFile && this.sourceProfileImageUrlValue) {
      if (!this.cropper) this.initCropper(this.sourceProfileImageUrlValue);
    }

    this.showCropperContainer({ showCancelButton: true });
  }

  removeFile(): void {
    this.sourceImageHiddenInputTarget.value = null;
    this.croppedImageHiddenInputTarget.value = null;
    this.croppedCanvasDataHiddenInputTarget.value = null;
    this.dropzone.removeAllFiles();
    this.dismountCropper();
  }

  resizeImage(file: Dropzone.DropzoneFile): void {
    this.dropzone.options.transformFile.call(
      this.dropzone,
      file,
      (blob: Blob) => {
        this.initDirectUpload(file, blob).start();
      }
    );
  }

  initDirectUpload(
    file: Dropzone.DropzoneFile,
    blob: Blob
  ): CreateDirectUpload {
    const resizedFile = new File([blob], file.name, {
      type: this.resizeMimeTypeValue,
    });

    return new CreateDirectUpload({
      dropzoneInstance: this.dropzone,
      file,
      resizedFile,
      imageHiddenInput: this.imageHiddenInput,
      uploadedUrl: this.directUploadUrlValue,
    });
  }

  initCropper(imageUrl: string): void {
    this.hideErrorAlert();

    this.imageHiddenInput = this.croppedImageHiddenInputTarget;
    this.cropTarget.src = imageUrl;

    this.cropper = new Cropper(this.cropTarget, {
      aspectRatio: this.cropperAspectRatio,
      viewMode: this.cropperViewMode,
      dragMode: this.cropperDragMode,
      cropBoxMovable: false,
      cropBoxResizable: false,
      minCropBoxWidth: this.thumbnailWidthHeightValue,
      minCropBoxHeight: this.thumbnailWidthHeightValue,
      guides: false,
      center: false,
    });
  }

  crop(): void {
    this.dropzone.options.createImageThumbnails = false;
    this.errorTarget.innerText = null;

    this.getCanvasData();
    this.getCroppedCanvas().toBlob((blob) => {
      this.uploadCroppedCanvas(blob);
    }, this.resizeMimeTypeValue);
  }

  cancelCropping(): void {
    this.hideCropperContainer();
  }

  getCroppedCanvas(): HTMLCanvasElement {
    return this.cropper.getCroppedCanvas({
      width: this.thumbnailWidthHeightValue,
      height: this.thumbnailWidthHeightValue,
    });
  }

  uploadCroppedCanvas(blob: Blob): void {
    const file: ICustomFile = new File([blob], this.defaultCroppedFilename, {
      type: this.resizeMimeTypeValue,
    });

    file.isCropped = true;

    this.dropzone.removeAllFiles();
    this.dropzone.addFile(file as Dropzone.DropzoneFile);
    this.hideCropperContainer();
  }

  getCanvasData(): void {
    const canvasData = this.cropper.getCanvasData();
    this.croppedCanvasDataHiddenInputTarget.value = JSON.stringify(canvasData);
  }

  renderUploadedImage(): void {
    const callback = null;
    const crossOrigin = "anonymous";
    const resizeThumbnail = true;
    const mockFile = {
      name: this.defaultThumbnailName,
      size: this.defaultThumbnailSize,
      accepted: true,
      isCropped: true,
      existingFile: true,
    };

    this.dropzone.displayExistingFile(
      mockFile,
      this.croppedProfileImageUrlValue,
      callback,
      crossOrigin,
      resizeThumbnail
    );
    this.dropzone.files.push(mockFile as ICustomDropzoneFile);
  }

  getCSRFToken(): string {
    return document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
  }

  showCropperContainer({
    showCancelButton,
  }: {
    showCancelButton: boolean;
  }): void {
    showCancelButton
      ? this.showCropperCancelButton()
      : this.hideCropperCancelButton();
    this.cropContainerTarget.classList.remove("hidden");
    this.uploadContainerTarget.classList.add("hidden");
  }

  hideCropperContainer(): void {
    this.cropContainerTarget.classList.add("hidden");
    this.uploadContainerTarget.classList.remove("hidden");
  }

  showEditButton(): void {
    this.editButtonTarget.classList.remove("hidden");
  }

  hideEditButton(): void {
    this.editButtonTarget.classList.add("hidden");
  }

  setDictionary(): void {
    this.dropzone.options.dictInvalidFileType =
      "Image format not supported. Use .jpeg, .jpg, .png, .webp instead.";
    this.dropzone.options.dictFileTooBig =
      "Image file size larger than 10MB limit. Use an image with a smaller file size.";
    this.dropzone.options.dictMaxFilesExceeded =
      "Multiple images selected. Select only one image.";
  }

  showErrorAlert(): void {
    this.errorAlertTarget.classList.replace("hidden", "flex");
  }

  hideErrorAlert(): void {
    this.errorAlertTarget.classList.replace("flex", "hidden");
  }

  showCropperCancelButton(): void {
    this.cropperCancelButtonTarget.classList.remove("hidden");
  }

  hideCropperCancelButton(): void {
    this.cropperCancelButtonTarget.classList.add("hidden");
  }

  dismountCropper(): void {
    this.cropTarget.src = "";
    if (this.cropper) {
      this.cropper.destroy();
    }
  }
}
