import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["btn", "tab"];
  static values = {
    defaultTab: String,
    activeClass: String,
  };

  declare tabTargets: Array<HTMLElement>;
  declare btnTargets: Array<HTMLElement>;
  declare defaultTabValue: string;
  declare activeClassValue: string;
  declare activeKlass: Array<string>;

  initialize() {
    this.activeKlass = this.activeClassValue
      .split(",")
      .filter((x) => x)
      .concat(["active"]);
  }

  connect() {
    this.element.classList.add("tabs");
    this.tabTargets.map((x) => (x.hidden = true)); // hide all tabs by default
    // OPEN DEFAULT TAB
    try {
      const selectedBtn = this.btnTargets.find(
        (element) => element.id === this.defaultTabValue
      );
      const selectedTab = this.tabTargets.find(
        (element) => element.id === this.defaultTabValue
      );
      selectedTab.hidden = false;
      selectedBtn.classList.add(...this.activeKlass);
    } catch (error) {
      console.log(error);
    }
  }

  select(event) {
    // find tab with same id as clicked btn
    const selectedTab = this.tabTargets.find(
      (element) => element.id === event.currentTarget.id
    );
    if (selectedTab.hidden) {
      // CLOSE CURRENT TAB
      this.tabTargets.map((x) => (x.hidden = true)); // hide all tabs
      this.btnTargets.map((x) => x.classList.remove(...this.activeKlass)); // deactive all btns
      selectedTab.hidden = false; // show current tab
      event.currentTarget.classList.add(...this.activeKlass); // active current btn
    }
  }
}
