import { tenantPath } from "helpers/tenant_path";
import i18next from "i18next";
import "@/i18n/config";
import {
  WEPLOYEE_ACTIVE_POSITIONS_PATH,
  WEPLOYEE_PAST_POSITIONS_PATH,
} from "packs/client_app/config";

import { PositionTab } from "./PositionTab";

function PositionTabs(): JSX.Element {
  return (
    <>
      <PositionTab
        to={tenantPath(WEPLOYEE_ACTIVE_POSITIONS_PATH, true)}
        label={i18next.t("clientApp.positionTabs.current")}
      />
      <PositionTab
        to={tenantPath(WEPLOYEE_PAST_POSITIONS_PATH, true)}
        label={i18next.t("clientApp.positionTabs.past")}
      />
    </>
  );
}

export { PositionTabs };
