import Reveal from "stimulus-reveal-controller";

export default class extends Reveal {
  static targets = ["showBtn", "hideBtn"];
  declare hideBtnTarget: HTMLElement;
  declare showBtnTarget: HTMLElement;

  connect() {
    super.connect();
  }

  show(): void {
    super.show();
    this.hideBtnTarget.classList.remove("hidden");
    this.showBtnTarget.classList.add("hidden");
  }

  hide(): void {
    super.hide();
    this.showBtnTarget.classList.remove("hidden");
    this.hideBtnTarget.classList.add("hidden");
  }
}
