import { GraphQLClient } from "graphql-request";
import { RequestInit } from "graphql-request/dist/types.dom";
import {
  useMutation,
  useQuery,
  UseMutationOptions,
  UseQueryOptions,
} from "react-query";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};

function fetcher<TData, TVariables>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  headers?: RequestInit["headers"]
) {
  return async (): Promise<TData> =>
    client.request<TData, TVariables>(query, variables, headers);
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};

export type Attendance = {
  __typename?: "Attendance";
  additionalNotes?: Maybe<Scalars["String"]>;
  approvedBy?: Maybe<ClientUser>;
  belowMinimumEngagementReasonToHuman?: Maybe<Scalars["String"]>;
  breaks?: Maybe<Array<Break>>;
  duration: Scalars["Int"];
  finishAt: Scalars["ISO8601DateTime"];
  forReview?: Maybe<Scalars["Boolean"]>;
  hasPublicHoliday?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  minimumEngagementDuration: Scalars["Int"];
  minimumEngagementRequired?: Maybe<Scalars["Boolean"]>;
  position: Position;
  startAt: Scalars["ISO8601DateTime"];
  state: AttendanceStateEnum;
  workedBy: CommunityMember;
};

/** An attendance cost item */
export type AttendanceCostItem = {
  __typename?: "AttendanceCostItem";
  attendanceId: Scalars["ID"];
  cost: Scalars["Float"];
  hoursWorked: Scalars["Float"];
  positionTitle: Scalars["String"];
  startTime: Scalars["ISO8601DateTime"];
  weployeeName: Scalars["String"];
  weployerName: Scalars["String"];
};

/** An attendance cost items summary */
export type AttendanceCostItemsSummary = {
  __typename?: "AttendanceCostItemsSummary";
  attendanceCostItems: Array<AttendanceCostItem>;
  grandTotal: Scalars["Float"];
  paymentTermSurcharge: Scalars["Float"];
  totalHoursWorked: Scalars["Float"];
};

/** Status of an attendance in the approval process */
export enum AttendanceStateEnum {
  /** Attendance is approved by the client user */
  Approved = "approved",
  /** Attendance is requested to be changed */
  ChangesRequested = "changes_requested",
  /** Attendance is finalized and ready for pay run */
  PayRunFinalized = "pay_run_finalized",
  /** Attendance is resubmitted after being changed */
  Resubmitted = "resubmitted",
  /** Attendance is submitted the first time */
  Submitted = "submitted",
}

export type BillingContact = {
  __typename?: "BillingContact";
  billingAddress?: Maybe<Scalars["String"]>;
  billingCountry?: Maybe<Scalars["String"]>;
  billingEmails?: Maybe<Scalars["String"]>;
  billingName: Scalars["String"];
  billingSubdivision?: Maybe<Scalars["String"]>;
  billingTaxNumber?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type Break = {
  __typename?: "Break";
  duration: Scalars["Int"];
  finishAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  startAt: Scalars["ISO8601DateTime"];
};

export type ClientCompany = {
  __typename?: "ClientCompany";
  attendanceCount?: Maybe<Scalars["Int"]>;
  billingContacts?: Maybe<Array<BillingContact>>;
  companyUsers?: Maybe<Array<ClientCompanyUser>>;
  id: Scalars["ID"];
  locations?: Maybe<Array<Location>>;
  name: Scalars["String"];
  positionTitles: Array<Scalars["String"]>;
};

export type ClientCompanyUser = {
  __typename?: "ClientCompanyUser";
  email: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["ID"];
};

export type ClientUser = {
  __typename?: "ClientUser";
  email: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["ID"];
  profileImageUrl?: Maybe<Scalars["String"]>;
};

export type CommunityMember = {
  __typename?: "CommunityMember";
  email: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["ID"];
  preferredName: Scalars["String"];
  profileImageUrl?: Maybe<Scalars["String"]>;
  timeZone: Scalars["String"];
};

/** Attributes for create a preview invoice */
export type CreatePreviewInvoiceInput = {
  /** A list of attendance id for invoicing */
  attendanceIds: Array<Scalars["String"]>;
  clientCompanyId: Scalars["String"];
  formsOrganizationAppInvoicesPreviewsCreate: InvoicePreviewScopeInput;
};

/** Autogenerated input type of CreatePreviewInvoiceMutation */
export type CreatePreviewInvoiceMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  previewInvoice: CreatePreviewInvoiceInput;
};

/** Autogenerated return type of CreatePreviewInvoiceMutation. */
export type CreatePreviewInvoiceMutationPayload = {
  __typename?: "CreatePreviewInvoiceMutationPayload";
  attendanceIds?: Maybe<Array<Scalars["String"]>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<FormError>;
};

/** A user-readable error */
export type FormError = {
  __typename?: "FormError";
  /** A description of the error */
  message: Scalars["String"];
  /** Which input value this error came from */
  path?: Maybe<Scalars["String"]>;
};

/** Attributes of preview invoice scope */
export type InvoicePreviewScopeInput = {
  billingContactId: Scalars["String"];
  invoicesConsolidation?: InputMaybe<Scalars["Boolean"]>;
  reference?: InputMaybe<Scalars["String"]>;
};

export type Location = {
  __typename?: "Location";
  id: Scalars["ID"];
  subdivision: Scalars["String"];
  subdivisionLabel: Scalars["String"];
};

/** The mutation root of organization app */
export type Mutation = {
  __typename?: "Mutation";
  createPreviewInvoice?: Maybe<CreatePreviewInvoiceMutationPayload>;
};

/** The mutation root of organization app */
export type MutationCreatePreviewInvoiceArgs = {
  input: CreatePreviewInvoiceMutationInput;
};

export type Position = {
  __typename?: "Position";
  id: Scalars["ID"];
  manager: ClientCompanyUser;
  title: Scalars["String"];
};

/** The query root of organization app */
export type Query = {
  __typename?: "Query";
  attendanceCostItemsSummary: AttendanceCostItemsSummary;
  attendancesForInvoicing: Array<Attendance>;
  clientCompanies: Array<ClientCompany>;
  clientCompany: ClientCompany;
  paramsForFilterAttendance: ClientCompany;
};

/** The query root of organization app */
export type QueryAttendanceCostItemsSummaryArgs = {
  attendanceIds: Array<Scalars["ID"]>;
};

/** The query root of organization app */
export type QueryAttendancesForInvoicingArgs = {
  clientCompanyId: Scalars["ID"];
  finishAt?: InputMaybe<Scalars["ISO8601Date"]>;
  managerIdIs?: InputMaybe<Scalars["ID"]>;
  positionTitle?: InputMaybe<Scalars["String"]>;
  searchByCommunityMember?: InputMaybe<Scalars["String"]>;
  startAt?: InputMaybe<Scalars["ISO8601Date"]>;
  subdivision?: InputMaybe<Scalars["String"]>;
};

/** The query root of organization app */
export type QueryClientCompanyArgs = {
  clientCompanyId: Scalars["ID"];
};

/** The query root of organization app */
export type QueryParamsForFilterAttendanceArgs = {
  clientCompanyId: Scalars["ID"];
};

export type CreatePreviewInvoiceMutationVariables = Exact<{
  clientCompanyId: Scalars["String"];
  attendanceIds: Array<Scalars["String"]> | Scalars["String"];
  billingContactId: Scalars["String"];
  invoicesConsolidation: Scalars["Boolean"];
  reference: Scalars["String"];
}>;

export type CreatePreviewInvoiceMutation = {
  __typename?: "Mutation";
  createPreviewInvoice?: {
    __typename?: "CreatePreviewInvoiceMutationPayload";
    attendanceIds?: Array<string> | null;
    errors: Array<{
      __typename?: "FormError";
      path?: string | null;
      message: string;
    }>;
  } | null;
};

export type FetchAttendanceCostItemsSummaryQueryVariables = Exact<{
  attendanceIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type FetchAttendanceCostItemsSummaryQuery = {
  __typename?: "Query";
  attendanceCostItemsSummary: {
    __typename?: "AttendanceCostItemsSummary";
    paymentTermSurcharge: number;
    totalHoursWorked: number;
    grandTotal: number;
    attendanceCostItems: Array<{
      __typename?: "AttendanceCostItem";
      attendanceId: string;
      weployeeName: string;
      weployerName: string;
      positionTitle: string;
      cost: number;
      startTime: any;
      hoursWorked: number;
    }>;
  };
};

export type FetchAttendancesForInvoicingQueryVariables = Exact<{
  clientCompanyId: Scalars["ID"];
  communityMemberName?: InputMaybe<Scalars["String"]>;
  subdivision?: InputMaybe<Scalars["String"]>;
  managerId?: InputMaybe<Scalars["ID"]>;
  startAt?: InputMaybe<Scalars["ISO8601Date"]>;
  finishAt?: InputMaybe<Scalars["ISO8601Date"]>;
  positionTitle?: InputMaybe<Scalars["String"]>;
}>;

export type FetchAttendancesForInvoicingQuery = {
  __typename?: "Query";
  attendancesForInvoicing: Array<{
    __typename?: "Attendance";
    id: string;
    state: AttendanceStateEnum;
    startAt: any;
    finishAt: any;
    duration: number;
    minimumEngagementDuration: number;
    minimumEngagementRequired?: boolean | null;
    belowMinimumEngagementReasonToHuman?: string | null;
    additionalNotes?: string | null;
    position: {
      __typename?: "Position";
      id: string;
      title: string;
      manager: {
        __typename?: "ClientCompanyUser";
        id: string;
        fullName: string;
        email: string;
      };
    };
    breaks?: Array<{
      __typename?: "Break";
      id: string;
      startAt: any;
      finishAt: any;
      duration: number;
    }> | null;
    workedBy: {
      __typename?: "CommunityMember";
      id: string;
      email: string;
      fullName: string;
      preferredName: string;
      timeZone: string;
    };
  }>;
};

export type FetchClientCompaniesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchClientCompaniesQuery = {
  __typename?: "Query";
  clientCompanies: Array<{
    __typename?: "ClientCompany";
    id: string;
    name: string;
    attendanceCount?: number | null;
  }>;
};

export type FetchClientCompanyQueryVariables = Exact<{
  clientCompanyId: Scalars["ID"];
}>;

export type FetchClientCompanyQuery = {
  __typename?: "Query";
  clientCompany: {
    __typename?: "ClientCompany";
    id: string;
    name: string;
    attendanceCount?: number | null;
    billingContacts?: Array<{
      __typename?: "BillingContact";
      id: string;
      billingName: string;
    }> | null;
  };
};

export type FetchParamsForFilterAttendanceQueryVariables = Exact<{
  clientCompanyId: Scalars["ID"];
}>;

export type FetchParamsForFilterAttendanceQuery = {
  __typename?: "Query";
  paramsForFilterAttendance: {
    __typename?: "ClientCompany";
    id: string;
    name: string;
    positionTitles: Array<string>;
    companyUsers?: Array<{
      __typename?: "ClientCompanyUser";
      id: string;
      fullName: string;
    }> | null;
    locations?: Array<{
      __typename?: "Location";
      subdivision: string;
      subdivisionLabel: string;
    }> | null;
  };
};

export const CreatePreviewInvoiceDocument = `
    mutation createPreviewInvoice($clientCompanyId: String!, $attendanceIds: [String!]!, $billingContactId: String!, $invoicesConsolidation: Boolean!, $reference: String!) {
  createPreviewInvoice(
    input: {previewInvoice: {attendanceIds: $attendanceIds, clientCompanyId: $clientCompanyId, formsOrganizationAppInvoicesPreviewsCreate: {billingContactId: $billingContactId, invoicesConsolidation: $invoicesConsolidation, reference: $reference}}}
  ) {
    attendanceIds
    errors {
      path
      message
    }
  }
}
    `;
export const useCreatePreviewInvoiceMutation = <
  TError = unknown,
  TContext = unknown
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreatePreviewInvoiceMutation,
    TError,
    CreatePreviewInvoiceMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) =>
  useMutation<
    CreatePreviewInvoiceMutation,
    TError,
    CreatePreviewInvoiceMutationVariables,
    TContext
  >(
    ["createPreviewInvoice"],
    (variables?: CreatePreviewInvoiceMutationVariables) =>
      fetcher<
        CreatePreviewInvoiceMutation,
        CreatePreviewInvoiceMutationVariables
      >(client, CreatePreviewInvoiceDocument, variables, headers)(),
    options
  );
export const FetchAttendanceCostItemsSummaryDocument = `
    query fetchAttendanceCostItemsSummary($attendanceIds: [ID!]!) {
  attendanceCostItemsSummary(attendanceIds: $attendanceIds) {
    attendanceCostItems {
      attendanceId
      weployeeName
      weployerName
      positionTitle
      cost
      startTime
      hoursWorked
    }
    paymentTermSurcharge
    totalHoursWorked
    grandTotal
  }
}
    `;
export const useFetchAttendanceCostItemsSummaryQuery = <
  TData = FetchAttendanceCostItemsSummaryQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables: FetchAttendanceCostItemsSummaryQueryVariables,
  options?: UseQueryOptions<
    FetchAttendanceCostItemsSummaryQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchAttendanceCostItemsSummaryQuery, TError, TData>(
    ["fetchAttendanceCostItemsSummary", variables],
    fetcher<
      FetchAttendanceCostItemsSummaryQuery,
      FetchAttendanceCostItemsSummaryQueryVariables
    >(client, FetchAttendanceCostItemsSummaryDocument, variables, headers),
    options
  );
export const FetchAttendancesForInvoicingDocument = `
    query fetchAttendancesForInvoicing($clientCompanyId: ID!, $communityMemberName: String, $subdivision: String, $managerId: ID, $startAt: ISO8601Date, $finishAt: ISO8601Date, $positionTitle: String) {
  attendancesForInvoicing(
    clientCompanyId: $clientCompanyId
    searchByCommunityMember: $communityMemberName
    subdivision: $subdivision
    managerIdIs: $managerId
    startAt: $startAt
    finishAt: $finishAt
    positionTitle: $positionTitle
  ) {
    id
    state
    startAt
    finishAt
    duration
    minimumEngagementDuration
    minimumEngagementRequired
    belowMinimumEngagementReasonToHuman
    additionalNotes
    position {
      id
      title
      manager {
        id
        fullName
        email
      }
    }
    breaks {
      id
      startAt
      finishAt
      duration
    }
    workedBy {
      id
      email
      fullName
      preferredName
      timeZone
    }
  }
}
    `;
export const useFetchAttendancesForInvoicingQuery = <
  TData = FetchAttendancesForInvoicingQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables: FetchAttendancesForInvoicingQueryVariables,
  options?: UseQueryOptions<FetchAttendancesForInvoicingQuery, TError, TData>,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchAttendancesForInvoicingQuery, TError, TData>(
    ["fetchAttendancesForInvoicing", variables],
    fetcher<
      FetchAttendancesForInvoicingQuery,
      FetchAttendancesForInvoicingQueryVariables
    >(client, FetchAttendancesForInvoicingDocument, variables, headers),
    options
  );
export const FetchClientCompaniesDocument = `
    query fetchClientCompanies {
  clientCompanies {
    id
    name
    attendanceCount
  }
}
    `;
export const useFetchClientCompaniesQuery = <
  TData = FetchClientCompaniesQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: FetchClientCompaniesQueryVariables,
  options?: UseQueryOptions<FetchClientCompaniesQuery, TError, TData>,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchClientCompaniesQuery, TError, TData>(
    variables === undefined
      ? ["fetchClientCompanies"]
      : ["fetchClientCompanies", variables],
    fetcher<FetchClientCompaniesQuery, FetchClientCompaniesQueryVariables>(
      client,
      FetchClientCompaniesDocument,
      variables,
      headers
    ),
    options
  );
export const FetchClientCompanyDocument = `
    query fetchClientCompany($clientCompanyId: ID!) {
  clientCompany(clientCompanyId: $clientCompanyId) {
    id
    name
    attendanceCount
    billingContacts {
      id
      billingName
    }
  }
}
    `;
export const useFetchClientCompanyQuery = <
  TData = FetchClientCompanyQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables: FetchClientCompanyQueryVariables,
  options?: UseQueryOptions<FetchClientCompanyQuery, TError, TData>,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchClientCompanyQuery, TError, TData>(
    ["fetchClientCompany", variables],
    fetcher<FetchClientCompanyQuery, FetchClientCompanyQueryVariables>(
      client,
      FetchClientCompanyDocument,
      variables,
      headers
    ),
    options
  );
export const FetchParamsForFilterAttendanceDocument = `
    query fetchParamsForFilterAttendance($clientCompanyId: ID!) {
  paramsForFilterAttendance(clientCompanyId: $clientCompanyId) {
    id
    name
    companyUsers {
      id
      fullName
    }
    locations {
      subdivision
      subdivisionLabel
    }
    positionTitles
  }
}
    `;
export const useFetchParamsForFilterAttendanceQuery = <
  TData = FetchParamsForFilterAttendanceQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables: FetchParamsForFilterAttendanceQueryVariables,
  options?: UseQueryOptions<FetchParamsForFilterAttendanceQuery, TError, TData>,
  headers?: RequestInit["headers"]
) =>
  useQuery<FetchParamsForFilterAttendanceQuery, TError, TData>(
    ["fetchParamsForFilterAttendance", variables],
    fetcher<
      FetchParamsForFilterAttendanceQuery,
      FetchParamsForFilterAttendanceQueryVariables
    >(client, FetchParamsForFilterAttendanceDocument, variables, headers),
    options
  );
