import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "billingAddress",
    "otherBillingAddressContainer",
    "sendInvoicesToSomeoneElse",
    "billingEmailsContainer",
  ];

  declare billingAddressTarget: HTMLInputElement;
  declare otherBillingAddressContainerTarget: HTMLDivElement;
  declare sendInvoicesToSomeoneElseTarget: HTMLInputElement;
  declare billingEmailsContainerTarget: HTMLDivElement;

  handleBillingAddressChange(): void {
    if (this.billingAddressTarget.value == "") {
      this.showOtherBillingAddress();
    } else {
      this.hideOtherBillingAddress();
    }
  }

  handleSendInvoicesToSomeoneElseChange(): void {
    if (this.sendInvoicesToSomeoneElseTarget.checked) {
      this.showBillingEmails();
    } else {
      this.hideBillingEmails();
    }
  }

  showOtherBillingAddress(): void {
    this.otherBillingAddressContainerTarget.classList.remove("hidden");
  }

  hideOtherBillingAddress(): void {
    this.otherBillingAddressContainerTarget.classList.add("hidden");
  }

  showBillingEmails(): void {
    this.billingEmailsContainerTarget.classList.remove("hidden");
  }

  hideBillingEmails(): void {
    this.billingEmailsContainerTarget.classList.add("hidden");
  }
}
