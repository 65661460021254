import { Link } from "react-router-dom";

import graphqlClient from "@graphql/organization_app/client";
import { useFetchClientCompaniesQuery } from "@graphql/organization_app/types";

export const ClientCompanies = (): JSX.Element => {
  const { isFetching, data, error } =
    useFetchClientCompaniesQuery(graphqlClient);

  if (error) {
    return <div>Error!</div>;
  }

  if (isFetching) {
    return <span>Loading...</span>;
  }

  return (
    <div className='text-legacyColor-neutral-1-500'>
      <header className='px-12 py-8 bg-legacyColor-neutral-2-100'>
        <h1 className='text-headline2 mb-4'>Clients</h1>
      </header>
      <section className='m-12'>
        {data.clientCompanies.map((clientCompany) => (
          <div
            key={clientCompany.id}
            className='max-w-md mb-4 border-neutral-300 border-2 rounded-xl p-8'
          >
            <Link to={`${clientCompany.id}/attendances`}>
              <div className='text-body mb-1'>{clientCompany.name}</div>
              <div>
                <span className='rounded-full bg-legacyColor-primary-200 px-2 mr-1 text-caption'>
                  {clientCompany.attendanceCount}
                </span>
                <span className='text-overline'>Attendances to Invoice</span>
              </div>
            </Link>
          </div>
        ))}
      </section>
    </div>
  );
};
