import clsx from "clsx";
import { tenantPath } from "helpers/tenant_path";
import i18next from "i18next";
import "@/i18n/config";
import { useState } from "react";
import Flatpickr from "react-flatpickr";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import { useNavigate } from "react-router-dom";

import DownArrow from "@assets/icons/down.svg";
import Info from "@assets/icons/info-primary-900.svg";
import UpArrow from "@assets/icons/up.svg";
import PeopleInLivingRoomImageUrl from "@assets/illustrations/people_in_living_room.svg";
import graphqlClient from "@graphql/client_app/client";
import { Position } from "@graphql/client_app/types";
import { useCreateBuyoutRequestsMutation } from "@graphql/client_app/types";

import { WEPLOYEES_BUYOUT_REQUEST_SUCCESS_PATH } from "@/client_app/config";

import { CommunityMemberAvatar } from "@/client_app/components/CommunityMemberAvatar";
import { PrimaryButton } from "@/ui/buttons";
import { FullLayoutModal } from "@/ui/FullLayoutModal";

interface BuyoutRequestProp {
  onBack: () => void;
  isOpen: boolean;
  selectedPositions?: Array<PositionType>;
}

type ExtensionRequestType = {
  isPending: boolean;
};

type BuyoutRequestType = {
  isPending: boolean;
};

type ChangeFields<T, R> = Omit<T, keyof R> & R;
export type PositionType = ChangeFields<
  Position,
  {
    communityMember: Omit<
      Position["communityMember"],
      "email" | "preferredName" | "timeZone"
    >;
    extensionRequests?: ExtensionRequestType[];
    buyoutRequests?: BuyoutRequestType[];
  }
>;

function BuyoutRequestPreview({
  onBack,
  selectedPositions = [],
}: BuyoutRequestProp): JSX.Element {
  const {
    register,
    handleSubmit: reactHookFormHandleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const mutation = useCreateBuyoutRequestsMutation(graphqlClient);

  const [listExpanded, setListExpanded] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function getHeading() {
    return selectedPositions.length > 1
      ? `${selectedPositions.length} Weployees selected`
      : `${selectedPositions[0].communityMember.fullName} selected`;
  }

  function formatDate(dateString) {
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
    };

    return new Date(dateString).toLocaleDateString("en-AU", dateOptions);
  }

  function handleSubmit(data): void {
    setIsSubmitting(true);
    const variables = {
      positionIds: selectedPositions.map(({ id }) => id),
      ...data.buyout_request,
    };

    mutation.mutateAsync(variables).then((data) => {
      const errors = data.createBuyoutRequests.errors;
      setIsSubmitting(false);

      if (errors.length > 0) {
        handleFailure(errors);
      } else {
        handleSuccess();
      }
    });
  }

  function handleFailure(errors): void {
    errors.forEach((error) => {
      setError(`buyout_request.${error.path}`, {
        type: "custom",
        message: error.message,
      });
    });
  }

  function handleSuccess() {
    navigate(tenantPath(WEPLOYEES_BUYOUT_REQUEST_SUCCESS_PATH, true));
  }

  function onSelectDate(date: Date): void {
    setValue("buyout_request.startOn", date.toString());
  }

  const renderStartDate = () => {
    return (
      <div className='mb-7'>
        <label
          htmlFor='start_on'
          className={clsx(
            "form-label",
            !!errors?.buyout_request?.startOn?.message && "text-error-600"
          )}
        >
          Select Ideal Permanent Start Date
        </label>
        <Flatpickr
          className={clsx(
            "form-control",
            !!errors?.buyout_request?.startOn?.message && "border-error-600"
          )}
          onClose={onSelectDate}
          name='start_on'
          options={{
            dateFormat: "d F Y",
            minDate: Date.now(),
          }}
          placeholder='28 February 2022'
        />
        <input type='hidden' {...register("buyout_request.startOn")} />
        <div className='form-error first-letter:capitalize mt-2'>
          {errors?.buyout_request?.startOn?.message}
        </div>
      </div>
    );
  };

  const renderSelectedWeployee = () => {
    return (
      <>
        <div className='flex justify-between items-center mb-6'>
          <h6 className='font-bold md:font-semibold text-newColor-neutral-900 md:h4-sm'>
            {getHeading()}
          </h6>
          {selectedPositions.length > 1 && (
            <button
              className='font-semibold'
              onClick={() => setListExpanded(!listExpanded)}
            >
              <span className='flex items-center text-newColor-primary-900'>
                {listExpanded ? "HIDE" : "VIEW"} ALL
                <SVG
                  src={listExpanded ? UpArrow : DownArrow}
                  className='icon fill-current cursor-pointer'
                />
              </span>
            </button>
          )}
        </div>
        <div>
          {selectedPositions.map(
            ({ id, communityMember, startOn, finishOn }) => {
              return (
                <div
                  className={clsx(
                    "odd:bg-neutral-50 even:bg-neutral-100 flex items-center last:mb-6 p-3",
                    listExpanded ? "block" : "hidden"
                  )}
                  key={id}
                >
                  <CommunityMemberAvatar
                    size='EXTRA_SMALL'
                    profileImageUrl={communityMember.profileImageUrl}
                    name={communityMember.fullName}
                  />
                  <div className='ml-4'>{communityMember.fullName}</div>
                  <div className='ml-auto'>
                    {formatDate(startOn)} - {formatDate(finishOn)}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </>
    );
  };

  return (
    <FullLayoutModal
      isOpen={true}
      title={"Buyout Requests"}
      closeModal={onBack}
      subTitle=''
    >
      <div className='flex my-7 text-newColor-neutral-900'>
        <div>
          {renderSelectedWeployee()}
          <form
            onSubmit={reactHookFormHandleSubmit(handleSubmit)}
            className='mb-2 w-11/12 md:w-96'
          >
            {renderStartDate()}
            <div className='mb-7'>
              <label htmlFor='additional_comments' className='form-label'>
                Additional Comments
              </label>
              <textarea
                id='additional_comments'
                className='form-control mb-2 h-28'
                placeholder='Input additional comments'
                {...register("buyout_request.additionalComments")}
              />
            </div>
            <div className='flex items-center mb-7'>
              <SVG
                src={Info}
                className='w-[20px] h-[20px] min-w-[20px] min-h-[20px] mr-5 text-newColor-primary-900'
              />
              <span className='p2'>
                {i18next.t("clientApp.buyoutRequestFee")}
              </span>
            </div>
            <PrimaryButton
              loading={isSubmitting}
              size='MEDIUM'
              type='submit'
              label='Request Buyout'
            />
          </form>
        </div>
        <div className='w-full justify-center items-start hidden md:flex'>
          <SVG src={PeopleInLivingRoomImageUrl} className='w-[44rem]' />
        </div>
      </div>
    </FullLayoutModal>
  );
}

export { BuyoutRequestPreview };
