import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select", "modal"];

  declare selectTarget: HTMLSelectElement;
  declare modalTarget: HTMLElement;
  declare modalTargets: Array<HTMLElement>;

  private toggleClass = "hidden";

  connect(): void {
    this.selectTarget.addEventListener("change", this.handleChange.bind(this));
  }

  handleChange(event: InputEvent) {
    const target = event.target as HTMLSelectElement;
    const modalTargetElement = this.modalTargets.find(
      (modal: HTMLElement): boolean => {
        return modal.dataset.statusTarget == target.value;
      }
    );

    if (modalTargetElement) {
      modalTargetElement.classList.remove(this.toggleClass);
    } else {
      this.selectTarget.value = this.selectTarget.dataset.defaultValue;
    }
  }

  hideModal(event): void {
    const target = event.target;

    const modalTargetElement = this.modalTargets.find(
      (modal: HTMLElement): boolean => {
        return modal.dataset.statusTarget == target.dataset.statusTarget;
      }
    );

    if (modalTargetElement) {
      this.selectTarget.value = this.selectTarget.dataset.defaultValue;
      modalTargetElement.classList.add(this.toggleClass);
    }
  }
}
