import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "selectAllCheckbox",
    "checkbox",
    "downloadCsvButton",
    "invitationButton",
    "selectedCount",
  ];

  declare selectAllCheckboxTarget: HTMLInputElement;
  declare checkboxTargets: [HTMLInputElement];
  declare downloadCsvButtonTarget: HTMLAnchorElement;
  declare invitationButtonTarget: HTMLAnchorElement;
  declare selectedCountTarget: HTMLDivElement;
  declare hasSelectAllCheckboxTarget: boolean;
  declare hasDownloadCsvButtonTarget: boolean;
  declare hasInvitationButtonTarget: boolean;

  connect(): void {
    if (!this.hasSelectAllCheckboxTarget) return;

    if (this.selectAllCheckboxTarget.checked) {
      this.#showButtons();
    } else {
      this.#hideButtons();
    }

    if (this.hasInvitationButtonTarget) {
      this.invitationButtonTarget.addEventListener(
        "click",
        this.handleInvitationButtonOnClick.bind(this)
      );
    }

    this.element.addEventListener(
      "change",
      this.handleCheckboxOnChange.bind(this)
    );
  }

  toggle(event: Event): void {
    const target = event.target as HTMLInputElement;

    if (target.checked) {
      this.selectAll();
    } else {
      this.deSelectAll();
    }
  }

  handleInvitationButtonOnClick(event: Event): void {
    event.preventDefault();
    const form = (event.target as HTMLAnchorElement)
      .parentNode as HTMLFormElement;
    const checkedCheckboxes = this.checkboxTargets.filter(
      (x) => x.checked == true
    );

    checkedCheckboxes.forEach((checkbox) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.value = checkbox.value;
      input.name = checkbox.name;
      form.appendChild(input);
    });

    form.submit();
  }

  selectAll(): void {
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = true));
    this.#showButtons();
  }

  deSelectAll(): void {
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = false));
    this.#hideButtons();
  }

  handleCheckboxOnChange(event: Event): void {
    if (!this.checkboxTargets.includes(event.target as HTMLInputElement))
      return;

    if (this.checkboxTargets.some((checkbox) => checkbox.checked)) {
      this.#showButtons();
    } else {
      this.#hideButtons();
    }
  }

  #hideButtons(): void {
    this.#hideCounting();
    if (this.hasDownloadCsvButtonTarget)
      this.downloadCsvButtonTarget.classList.add("invisible");

    if (this.hasInvitationButtonTarget)
      this.invitationButtonTarget.classList.add("invisible");
  }

  #showButtons(): void {
    this.#showCounting();
    if (this.hasDownloadCsvButtonTarget)
      this.downloadCsvButtonTarget.classList.remove("invisible");

    if (this.hasInvitationButtonTarget)
      this.invitationButtonTarget.classList.remove("invisible");
  }

  #hideCounting(): void {
    this.selectedCountTarget.textContent = "";
    this.selectedCountTarget.classList.add("invisible");
  }

  #showCounting(): void {
    const selectedRows = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked
    );
    this.selectedCountTarget.textContent = `${selectedRows.length} Selected`;
    this.selectedCountTarget.classList.remove("invisible");
  }
}
