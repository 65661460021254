import clsx from "clsx";

import { CommunityMemberAvatar } from "@/client_app/components/CommunityMemberAvatar";
import { Checkbox } from "@/ui/Checkbox";

interface CommunityMember {
  fullName: string;
  profileImageUrl?: string;
}

interface PastPositionListItemMobileProps {
  communityMember: CommunityMember;
  finishOn: string;
  startOn: string;
  title: string | JSX.Element;
  checked: boolean;
  onChange: () => void;
}

const PastPositionListItemMobile = ({
  communityMember,
  finishOn,
  startOn,
  title,
  checked,
  onChange,
}: PastPositionListItemMobileProps): JSX.Element => {
  const { fullName, profileImageUrl } = communityMember;
  return (
    <div
      className={clsx(
        "flex border-neutral-200 border rounded-xl bg-shade-50",
        "gap-2 items-start p-3"
      )}
    >
      <div className='flex gap-x-2'>
        <Checkbox checked={checked} onChange={onChange} />
        <CommunityMemberAvatar
          name={fullName}
          profileImageUrl={profileImageUrl}
          size='SMALL'
        />
      </div>

      <div className='w-full'>
        <p className='flex justify-between items-center mb-2 text-primary-900 font-semibold paragraph-2'>
          {fullName}
        </p>
        <p className='mb-2 text-neutral-700'>{title}</p>
        <p className='mb-2'>
          <span className='text-neutral-900'>Start Date: </span>
          <span className='text-neutral-700'>{startOn}</span>
        </p>
        <p className='mb-2'>
          <span className='text-neutral-900'>End Date: </span>
          <span className='text-error-600'>{finishOn}</span>
        </p>
      </div>
    </div>
  );
};

export { PastPositionListItemMobile };
