import { WageBreakdown } from "@graphql/client_app/types";

interface CostSummaryAwardItemsProp {
  payrates: WageBreakdown[];
}

function CostSummaryAwardItems({
  payrates,
}: CostSummaryAwardItemsProp): JSX.Element {
  return (
    <>
      {payrates.map((rate: WageBreakdown, index: number) => {
        return (
          <div key={index} className='flex justify-between pt-4 md:pt-8'>
            <span className='font-normal text-neutral-700'>
              {`${rate.description} x ${rate.hoursWorked}`}
            </span>
            <span className='font-semibold text-neutral-900'>{rate.wage}</span>
          </div>
        );
      })}
    </>
  );
}

export { CostSummaryAwardItems };
