export const FlaggedAttendancesGroupHeading = (): JSX.Element => {
  return (
    <div className='hidden md:grid grid-cols-7 text-button-md font-bold text-center mb-7'>
      <div className='col-span-2'></div>
      <div className='col-span-5 grid grid-cols-11 px-4 md:px-6'>
        <p className='col-span-1'></p>
        <p className='col-span-2'>DATE</p>
        <p className='col-span-2'>HOURS</p>
        <p className='col-span-2'>BREAKS</p>
        <p className='col-span-2'>DAILY TOTAL</p>
        <p className='col-span-2'></p>
      </div>
    </div>
  );
};
