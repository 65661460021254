import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];
  declare inputTarget: HTMLInputElement;

  connect() {
    this.count = parseInt(this.inputTarget.value) || 0;
    this.inputTarget.value = this.count.toString();
  }

  increment() {
    this.count++;
  }

  decrement() {
    if (this.count == 0) return;
    this.count--;
  }

  get count() {
    return parseInt(this.data.get("count"));
  }

  set count(value) {
    this.data.set("count", value.toString());
    this.inputTarget.value = this.count.toString();
  }
}
