import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  declare containerTarget: HTMLElement;
  declare currentCallerId: string;

  initialize() {
    this.currentCallerId = null;
  }

  populateContent(event) {
    event.stopPropagation();

    this.containerTarget.innerHTML = "";
    this.currentCallerId = event.currentTarget.id;

    const targetPath = event.currentTarget.dataset.targetPath;

    Rails.ajax({
      url: `${targetPath}`,
      type: "GET",
      dataType: "json",
      success: (data) => {
        if (data.id == this.currentCallerId) {
          this.containerTarget.innerHTML = data.content;
        }
      },
    });
  }
}
