import Rails from "@rails/ujs";
import { intersection } from "lodash";
import { Controller } from "stimulus";

type RateCard = {
  id: string;
  name: string;
  type: string;
};

type KeyDuty = {
  text: string;
  value: string;
  custom_text: string;
  group_key: string;
};

export default class extends Controller {
  static targets = [
    "keyDutiesSelect",
    "element",
    "rateCardId",
    "rateCardIdContainer",
    "rateCardType",
    "otherKeyDutyContainer",
    "otherKeyDuty",
    "employmentType",
  ];

  static values = {
    hourlyPayRateCalculationPath: String,
    allRateCards: Object,
    keyDutiesOptions: Object,
  };

  declare allRateCardsValue: {
    complex_outbound: Array<RateCard>;
    complex_inbound: Array<RateCard>;
    general: Array<RateCard>;
  };
  declare keyDutiesOptionsValue: {
    complex_outbound: Array<KeyDuty>;
    complex_inbound: Array<KeyDuty>;
    general: Array<KeyDuty>;
  };
  declare hourlyPayRateCalculationPathValue: string;
  declare keyDutiesSelectTarget: HTMLSelectElement;
  declare elementTargets: Array<HTMLInputElement>;
  declare rateCardIdTarget: HTMLSelectElement;
  declare rateCardIdContainerTarget: HTMLElement;
  declare rateCardTypeTarget: HTMLInputElement;
  declare payRateContainer: HTMLElement;
  declare otherKeyDutyContainerTarget: HTMLElement;
  declare otherKeyDutyTarget: HTMLInputElement;
  declare employmentTypeTarget: HTMLInputElement;

  private hiddenClass = "hidden";
  private permanentType = "permanent";

  connect(): void {
    this.rateCardIdContainerTarget.classList.add(this.hiddenClass);
    this.otherKeyDutyContainerTarget.classList.add(this.hiddenClass);
    this.payRateContainer = document.getElementById("payRateContainer");
  }

  employmentTypeChange(event): void {
    const value = event.target.value;
    const rateCardIdContainerClassList = this.rateCardIdContainerTarget
      .classList as DOMTokenList;
    const keyDuties = this.keyDutiesSelectTarget;

    if (value == this.permanentType) {
      this.hideRateCardIdAndPayRate();
    } else {
      if (keyDuties.value.length) {
        rateCardIdContainerClassList.remove(this.hiddenClass);
        const event = new Event("change");
        this.keyDutiesSelectTarget.dispatchEvent(event);
      }
    }
  }

  handleKeyDutiesChange(event): void {
    const keyDutiesOptions = this.keyDutiesOptionsValue;
    const keyDuties = event.target.values || [event.target.value];
    this.toggleElement(keyDuties);

    const employmentType = this.employmentTypeTarget.querySelector(
      "input:checked"
    ) as HTMLInputElement;

    // Hide rate card when employment type is permanent
    if (employmentType?.value == this.permanentType) {
      this.hideRateCardIdAndPayRate();
      return;
    }

    if (keyDuties.includes("other")) {
      this.hideRateCardIdAndPayRate();
      this.otherKeyDutyContainerTarget.classList.remove(this.hiddenClass);
    } else if (
      keyDuties.length > 0 &&
      this.includeKeyDutyInList(keyDuties, keyDutiesOptions.complex_outbound)
    ) {
      this.generateRateCardSelect(this.allRateCardsValue.complex_outbound);
    } else if (
      keyDuties.length > 0 &&
      this.includeKeyDutyInList(keyDuties, keyDutiesOptions.complex_inbound)
    ) {
      this.generateRateCardSelect(this.allRateCardsValue.complex_inbound);
    } else if (
      keyDuties.length > 0 &&
      this.includeKeyDutyInList(keyDuties, keyDutiesOptions.general)
    ) {
      this.generateRateCardSelect(this.allRateCardsValue.general);
    } else {
      this.hideRateCardIdAndPayRate();
    }
  }

  onChangeRateCard(event): void {
    const { target } = event;
    const id = target.value;
    const type = target.options[target.selectedIndex].getAttribute("type");
    if (id.length) {
      this.rateCardTypeTarget.value = type;
      this.getPayRates(id, type);
    } else {
      this.rateCardTypeTarget.value = null;
      this.removePayRateContailer();
    }
  }

  private includeKeyDutyInList(keyDutiesProvided, listOfkeyDuties): boolean {
    const combineKeyDuties = [
      ...new Set(keyDutiesProvided.concat(listOfkeyDuties)),
    ];
    return (
      combineKeyDuties.length <
      keyDutiesProvided.length + listOfkeyDuties.length
    );
  }

  private generateRateCardSelect(listOfRateCards: Array<RateCard>): void {
    this.rateCardIdContainerTarget.classList.remove(this.hiddenClass);
    this.otherKeyDutyTarget.value = null;
    this.otherKeyDutyContainerTarget.classList.add(this.hiddenClass);

    const currentRateCardId = this.rateCardIdTarget.value;

    if (listOfRateCards.length == 1) {
      this.rateCardIdTarget.innerHTML = "";
    } else {
      this.rateCardIdTarget.innerHTML = `<option value="">Select</option>`;
    }

    listOfRateCards.forEach((rateCard) => {
      const checked =
        listOfRateCards.length == 1 ||
        (currentRateCardId && rateCard.id && currentRateCardId === rateCard.id);
      this.rateCardIdTarget.innerHTML += `<option type="${
        rateCard.type
      }" value="${rateCard.id}" ${checked ? "selected" : ""}>${
        rateCard.name
      }</option>`;
    });

    const event = new Event("change");
    this.rateCardIdTarget.dispatchEvent(event);
  }

  private toggleElement(values): void {
    this.hideElementTargets();
    const showElements = this.elementTargets.filter((element) => {
      const showValues = element.dataset.showValues.split(",").sort();
      const hideValues =
        (element.dataset.hideValues &&
          element.dataset.hideValues.split(",").sort()) ||
        [];
      return (
        intersection(values, showValues).length > 0 &&
        !(intersection(values, hideValues).length > 0)
      );
    });

    showElements.forEach((element) => {
      if (element.querySelector("input"))
        element.querySelector("input").disabled = false;
      if (element.querySelector("select"))
        element.querySelector("select").disabled = false;
      element.classList.remove(this.hiddenClass);
    });
  }

  private hideElementTargets(): void {
    this.elementTargets.forEach((element) => {
      if (element.querySelector("input")) {
        element.querySelector("input").disabled = true;
        element.querySelector("input").value = "";
      }
      if (element.querySelector("select")) {
        element.querySelector("select").disabled = true;
        element.querySelector("select").value = "";
      }

      element.classList.add(this.hiddenClass);
    });
  }

  private getPayRates(id, type): void {
    const dataParams = new URLSearchParams({
      id: id,
      type: type.trim(),
    }).toString();

    Rails.ajax({
      url: this.hourlyPayRateCalculationPathValue,
      type: "GET",
      data: dataParams,
      success: ({ replace_content }) => {
        if (this.rateCardIdTarget.value && this.rateCardIdTarget.value == id) {
          this.payRateContainer.innerHTML = replace_content.pay_rate_container;
        }
      },
    });
  }

  private removePayRateContailer(): void {
    this.payRateContainer.innerHTML = "";
  }

  private hideRateCardIdAndPayRate(): void {
    this.rateCardIdTarget.innerHTML = `<option value="">Select</option>`;
    this.rateCardTypeTarget.value = null;
    this.rateCardIdContainerTarget.classList.add(this.hiddenClass);
    this.otherKeyDutyContainerTarget.classList.add(this.hiddenClass);
    this.removePayRateContailer();
  }
}
