import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "exemptionSelect",
    "noOption",
    "tfnInput",
    "warning",
    "yesOption",
  ];
  static values = {
    validTfnPresent: Boolean,
  };
  declare tfnInputTarget: HTMLInputElement;
  declare exemptionSelectTarget: HTMLSelectElement;
  declare yesOptionTarget: HTMLInputElement;
  declare noOptionTarget: HTMLInputElement;
  declare warningTarget: HTMLElement;
  declare validTfnPresentValue: boolean;
  declare hasValidTfnPresentValue: boolean;

  connect(): void {
    if (!this.hasValidTfnPresentValue) {
      this.warningTarget.classList.add("hidden");
    } else if (this.validTfnPresentValue) {
      this.enableTfnInput();
    } else {
      this.enableExemptionSelect();
    }
  }

  enableTfnInput(): void {
    this.tfnInputTarget.disabled = false;

    this.exemptionSelectTarget.disabled = true;
    this.exemptionSelectTarget.value = "";
    this.warningTarget.classList.add("hidden");
  }

  enableExemptionSelect(): void {
    this.tfnInputTarget.disabled = true;
    this.tfnInputTarget.value = "";

    this.exemptionSelectTarget.disabled = false;
    this.warningTarget.classList.remove("hidden");
  }

  handleExemptionSelect(): void {
    if (this.noOptionTarget.checked) return;
    this.noOptionTarget.checked = true;
    this.enableExemptionSelect();
  }

  handleTfnInput(): void {
    if (this.yesOptionTarget.checked) return;
    this.yesOptionTarget.checked = true;
    this.enableTfnInput();
  }
}
