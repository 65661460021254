import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "field",
    "latitude",
    "longitude",
    "address",
    "city",
    "state",
    "postalCode",
    "country",
    "street",
    "streetNumber",
    "plusCode",
    "formatted_address",
    "latitude",
    "longitude",
  ];

  declare autocomplete: google.maps.places.Autocomplete;

  declare plusCodeTarget: HTMLInputElement;
  declare hasPlusCodeTarget: boolean;

  declare formattedAddressTarget: HTMLInputElement;
  declare hasFormattedAddressTarget: boolean;

  declare fieldTarget: HTMLInputElement;
  declare hasFieldTarget: boolean;

  declare latitudeTarget: HTMLInputElement;
  declare hasLatitudeTarget: boolean;

  declare longitudeTarget: HTMLInputElement;
  declare hasLongitudeTarget: boolean;

  declare addressTarget: HTMLInputElement;
  declare hasAddressTarget: boolean;

  declare cityTarget: HTMLInputElement;
  declare hasCityTarget: boolean;

  declare stateTarget: HTMLInputElement;
  declare hasStateTarget: boolean;

  declare postalCodeTarget: HTMLInputElement;
  declare hasPostalCodeTarget: boolean;

  declare countryTarget: HTMLInputElement;
  declare hasCountryTarget: boolean;

  declare streetTarget: HTMLInputElement;
  declare hasStreetTarget: boolean;

  declare streetNumberTarget: HTMLInputElement;
  declare hasStreetNumberTarget: boolean;

  setLatitudeTargetValue(value) {
    if (this.hasLatitudeTarget) {
      this.latitudeTarget.value = value;
    }
  }

  setLongitudeTargetValue(value) {
    if (this.hasLongitudeTarget) {
      this.longitudeTarget.value = value;
    }
  }

  setAddressTargetValue(value) {
    if (this.hasAddressTarget) {
      this.addressTarget.value = value;
    }
  }

  setCityTargetValue(value) {
    if (this.hasCityTarget) {
      this.cityTarget.value = value;
    }
  }

  setStateTargetValue(value) {
    if (this.hasStateTarget) {
      this.stateTarget.value = value;
    }
  }

  setPostalCodeTargetValue(value) {
    if (this.hasPostalCodeTarget) {
      this.postalCodeTarget.value = value;
    }
  }

  setCountryTargetValue(value) {
    if (this.hasCountryTarget) {
      this.countryTarget.value = value;
    }
  }

  setStreetTargetValue(value) {
    if (this.hasStreetTarget) {
      this.streetTarget.value = value;
    }
  }

  setStreetNumberTargetValue(value) {
    if (this.hasStreetNumberTarget) {
      this.streetNumberTarget.value = value;
    }
  }

  setPlusCodeTargetValue(value) {
    if (this.hasPlusCodeTarget) {
      this.plusCodeTarget.value = value;
    }
  }

  setFieldTargetValue(value) {
    if (this.hasFieldTarget) {
      this.fieldTarget.value = value;
    }
  }

  setFormattedAddressTargetValue(value) {
    if (this.formattedAddressTarget) {
      this.formattedAddressTarget.value = value;
    }
  }

  connect() {
    if (typeof google != "undefined") {
      this.initAutocomplete();
    }
  }

  initAutocomplete() {
    const optionsTypes = this.data.has("options-types")
      ? this.parseAndCleanOptionsTypes(this.data.get("options-types"))
      : [];

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget);
    this.autocomplete.setFields([
      "name",
      "geometry",
      "address_components",
      "formatted_address",
    ]);
    this.autocomplete.setOptions({
      componentRestrictions: { country: ["au", "nz"] },
      types: optionsTypes,
    });
    this.autocomplete.addListener(
      "place_changed",
      this.placeChanged.bind(this)
    );
    this.fieldTarget.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
    // google.maps.event.addDomListener(
    //   this.fieldTarget,
    //   "keydown",
    //   this.disableEnterIfAutocompleteIsVisible.bind(this)
    // );
  }

  parseAndCleanOptionsTypes(optionsTypes) {
    return (optionsTypes || "")
      .split(",")
      .filter((item) => item)
      .map((item) => item.trim());
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();

    if (!this.fieldTarget.value) place = undefined;

    if (!!place && !!place.geometry && !!place.address_components) {
      this.setAddressFieldsFromGeocode(place);
    } else if (!!place.name && this.fieldTarget.value == place.name) {
      return;
    } else {
      this.setLatitudeTargetValue("");
      this.setLongitudeTargetValue("");
      this.setAddressTargetValue("");
      this.setStreetTargetValue("");
      this.setStreetNumberTargetValue("");
      this.setCityTargetValue("");
      this.setStateTargetValue("");
      this.setPostalCodeTargetValue("");
      this.setCountryTargetValue("");
      this.setPlusCodeTargetValue("");
      this.setFormattedAddressTargetValue("");
    }
    this.fieldTarget.dispatchEvent(new CustomEvent("placeChange"));
  }

  setAddressFields(place) {
    this.setLatitudeTargetValue(place.geometry.location.lat());
    this.setLongitudeTargetValue(place.geometry.location.lng());

    // Example address_components payload
    // 0: {long_name: "1", short_name: "1", types: ["street_number"]}
    // 1: {long_name: "Alexandra Parade", short_name: "Alexandra Parade", types: ["route"]}
    // 2: {long_name: "Collingwood", short_name: "Collingwood", types: ["locality", "political"]}
    // 3: {long_name: "City of Yarra", short_name: "Yarra", types: ["administrative_area_level_2", "political"]}
    // 4: {long_name: "Victoria", short_name: "VIC", types: ["administrative_area_level_1", "political"]}
    // 5: {long_name: "Australia", short_name: "AU", types: ["country", "political"]}
    // 6: {long_name: "3066", short_name: "3066", types: ["postal_code"]}

    this.setAddressTargetValue(
      `${this.findComponentLongName(
        place.address_components,
        "street_number"
      )} ${this.findComponentLongName(place.address_components, "route")}`
    );

    this.setStreetTargetValue(
      this.findComponentLongName(place.address_components, "route")
    );
    this.setStreetNumberTargetValue(
      this.findComponentLongName(place.address_components, "street_number")
    );

    this.setCityTargetValue(
      this.findComponentLongName(place.address_components, "locality")
    );
    this.setStateTargetValue(
      this.findComponentShortName(
        place.address_components,
        "administrative_area_level_1"
      )
    );
    this.setPostalCodeTargetValue(
      this.findComponentLongName(place.address_components, "postal_code")
    );
    this.setCountryTargetValue(
      this.findComponentShortName(place.address_components, "country")
    );
    this.setPlusCodeTargetValue(
      this.findComponentLongName(place.address_components, "plus_code")
    );

    if (!this.postalCodeValueNotMissing()) {
      this.geocodePostalCode({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  }

  setAddressFieldsFromGeocode(place) {
    this.setAddressFields(place);
    this.setFieldTargetValue(place.formatted_address);
  }

  findComponentLongName(components, type) {
    const component = components.find((component) =>
      component.types.includes(type)
    );
    if (typeof component == "undefined") {
      return "";
    }
    return component.long_name;
  }

  findComponentShortName(components, type) {
    const component = components.find((component) =>
      component.types.includes(type)
    );
    if (typeof component == "undefined") {
      return "";
    }
    return component.short_name;
  }

  disableEnterIfAutocompleteIsVisible(event) {
    const ENTER_KEY = 13;

    if (event.keyCode === ENTER_KEY && this.isAutocompleteElementVisible()) {
      event.preventDefault();
    }
  }

  isAutocompleteElementVisible() {
    const GOOGLE_AUTOCOMPLETE_ELEMENT_CLASS = "pac-container";

    const element = document.getElementsByClassName(
      GOOGLE_AUTOCOMPLETE_ELEMENT_CLASS
    )[0] as HTMLElement;

    if (element === null || element === undefined) {
      console.log(
        "PlacesController: could not find `pac-container` class, check if Google has changed the autocomplete element"
      );
      return false;
    }

    return !!(
      element.offsetWidth ||
      element.offsetHeight ||
      element.getClientRects().length
    );
  }

  postalCodeValueNotMissing() {
    if (this.hasPostalCodeTarget) {
      return this.postalCodeTarget.value !== "";
    }
    return true;
  }

  geocodePostalCode(latlng) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          this.setPostalCodeTargetValue(
            this.findComponentLongName(
              results[0].address_components,
              "postal_code"
            )
          );
        }
      }
    });
  }
}
