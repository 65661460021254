import clsx from "clsx";

import { CommunityMemberAvatar } from "@/client_app/components/CommunityMemberAvatar";
import { isFinishingSoon } from "@/client_app/features/weployees/utils/operations";
import { Checkbox } from "@/ui/Checkbox";

interface CommunityMember {
  mobileNumber?: string;
  fullName: string;
  profileImageUrl?: string;
}

interface ActivePositionListItemMobileProps {
  communityMember: CommunityMember;
  finishOn: string;
  startOn: string;
  title: string | JSX.Element;
  checked: boolean;
  newJobAcceptance: boolean;
  onChange: () => void;
  positionId: string;
}

const ActivePositionListItemMobile = ({
  communityMember,
  finishOn,
  startOn,
  title,
  checked,
  newJobAcceptance,
  onChange,
  positionId,
}: ActivePositionListItemMobileProps): JSX.Element => {
  const { fullName, mobileNumber, profileImageUrl } = communityMember;
  return (
    <div
      className={clsx(
        "flex border-neutral-200 border rounded-xl bg-shade-50",
        "gap-2 items-start p-3"
      )}
    >
      <div className='flex gap-x-2'>
        <Checkbox checked={checked} onChange={onChange} />
        <CommunityMemberAvatar
          name={fullName}
          profileImageUrl={profileImageUrl}
          size='SMALL'
        />
      </div>

      <div className='w-full'>
        <p className='flex justify-between items-center mb-2 text-primary-900 font-semibold paragraph-2'>
          {fullName}
          {newJobAcceptance && (
            <div className='py-1 px-3 rounded-full bg-primary-50'>NEW</div>
          )}
          <div
            id={`position-${positionId.substring(0, 10)}`}
            className='text-red-500 hidden pending-error'
          ></div>
        </p>
        <p className='mb-2 text-neutral-700'>{title}</p>
        <p className='mb-2'>
          <span className='text-neutral-900'>Start Date: </span>
          <span className='text-neutral-700'>{startOn}</span>
        </p>
        <p className='mb-2'>
          <span className='text-neutral-900'>End Date: </span>
          <span
            className={
              isFinishingSoon(finishOn) ? "text-error-600" : "text-neutral-700"
            }
          >
            {finishOn}
          </span>
        </p>
        <p>
          <span className='text-neutral-900'>Contact: </span>
          <span className='text-neutral-700'>{mobileNumber}</span>
        </p>
      </div>
    </div>
  );
};

export { ActivePositionListItemMobile };
