import { Controller } from "stimulus";

type NameType = "short_name" | "long_name";

export default class extends Controller {
  static targets = [
    "input",
    "postcodeInput",
    "suburbInput",
    "stateInput",
    "countryCodeInput",
  ];

  declare autocomplete: google.maps.places.Autocomplete;
  declare inputTarget: HTMLInputElement;
  declare postcodeInputTarget: HTMLInputElement;
  declare suburbInputTarget: HTMLInputElement;
  declare stateInputTarget: HTMLInputElement;
  declare countryCodeInputTarget?: HTMLInputElement;

  connect(): void {
    if (typeof google != "undefined") {
      this.initAutocomplete();
    }
  }

  initAutocomplete(): void {
    this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget);
    this.autocomplete.setFields(["address_components"]);
    this.autocomplete.setOptions({
      componentRestrictions: { country: ["au", "nz"] },
      types: ["postal_code", "locality"],
    });

    this.autocomplete.addListener(
      "place_changed",
      this.handlePlaceChanged.bind(this)
    );

    this.inputTarget.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }

  handlePlaceChanged(): void {
    const place = this.autocomplete.getPlace();

    if (place.address_components) {
      this.suburbInputTarget.value = this.findComponentName(
        place.address_components,
        "locality",
        "short_name"
      );

      this.postcodeInputTarget.value = this.findComponentName(
        place.address_components,
        "postal_code",
        "short_name"
      );

      this.stateInputTarget.value = this.findComponentName(
        place.address_components,
        "administrative_area_level_1",
        "short_name"
      );

      if (this.countryCodeInputTarget) {
        this.countryCodeInputTarget.value = this.findComponentName(
          place.address_components,
          "country",
          "short_name"
        );
      }
    } else {
      this.suburbInputTarget.value = "";
      this.postcodeInputTarget.value = "";
      this.stateInputTarget.value = "";
      if (this.countryCodeInputTarget) {
        this.countryCodeInputTarget.value = "";
      }
    }
  }

  findComponentName(
    components: google.maps.places.PlaceResult["address_components"],
    name: string,
    nameType: NameType
  ): string {
    const component = components.find((component) =>
      component.types.includes(name)
    );
    if (typeof component == "undefined") {
      return "";
    }
    return component[nameType];
  }
}
