import { intersection } from "lodash";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select", "element"];

  declare selectTarget: HTMLSelectElement;
  declare elementTargets: Array<HTMLInputElement>;

  connect(): void {
    const selectedOptions = Array.from(
      this.selectTarget.selectedOptions
    ) as Array<HTMLOptionElement>;

    if (selectedOptions?.length) {
      const values = Array.from(selectedOptions).map((option) => {
        return option.value;
      });
      this.toggleElement(values);
    } else this.hideElementTargets();
  }

  handleChange(event): void {
    this.toggleElement(event.target.values || [event.target.value]);
  }

  toggleElement(values): void {
    this.hideElementTargets();
    const showElements = this.elementTargets.filter((element) => {
      const showValues = element.dataset.showValues.split(",").sort();
      const hideValues =
        (element.dataset.hideValues &&
          element.dataset.hideValues.split(",").sort()) ||
        [];
      return (
        intersection(values, showValues).length > 0 &&
        !(intersection(values, hideValues).length > 0)
      );
    });

    showElements.forEach((element) => {
      if (element.querySelector("input"))
        element.querySelector("input").disabled = false;
      if (element.querySelector("select"))
        element.querySelector("select").disabled = false;
      element.classList.remove("hidden");
    });
  }

  hideElementTargets(): void {
    this.elementTargets.forEach((element) => {
      if (element.querySelector("input"))
        element.querySelector("input").disabled = true;
      if (element.querySelector("select"))
        element.querySelector("select").disabled = true;
      element.classList.add("hidden");
    });
  }
}
