import { Route, Routes } from "react-router-dom";

import { NotFound } from "@/organization_app/components/NotFound";
import { invoicingsRoutes } from "@/organization_app/features/invoicings/routes";

export const AppRoutes = (): JSX.Element => {
  return (
    <Routes>
      {invoicingsRoutes}
      <Route path='*' element={<NotFound />} key='not-found-routes' />
    </Routes>
  );
};
