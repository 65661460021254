import { tenantPath } from "helpers/tenant_path";
import i18next from "i18next";
import "@/i18n/config";
import { FullLayoutModal } from "packs/ui/FullLayoutModal";
import SVG from "react-inlinesvg";

import ImageUrl from "@assets/illustrations/people_in_living_room.svg";

import {
  WEPLOYEE_ACTIVE_POSITIONS_PATH,
  DASHBOARD_PATH,
} from "@/client_app/config";

import { hotjarNoop } from "@/hotjar";

window.executeHotjarEvent = window.executeHotjarEvent || hotjarNoop;

export const BuyoutRequestSuccess = (): JSX.Element => {
  const closeModal = () => {
    window.location.href = tenantPath(WEPLOYEE_ACTIVE_POSITIONS_PATH, true);
  };

  return (
    <FullLayoutModal
      isOpen={true}
      closeModal={closeModal}
      title={"Back"}
      subTitle={""}
      hideHeaderBackButton
    >
      <div className='max-w-screen-md mx-auto'>
        <div className='flex flex-col items-center font-bold text-newColor-shade-100 text-center'>
          <h4 className='mt-6 md:mt-10 text-newColor-primary-900'>
            Awesome Work! 🎉
          </h4>
          <div className='flex mt-6 md:mt-10 gap-x-2'>
            <h5 className='p3 md:h5 font-normal md:font-semibold'>
              <span
                dangerouslySetInnerHTML={{
                  __html: i18next.t(
                    "clientApp.yourWeployeesHaveSentBuyoutRequestsNote",
                    { terminologyEmployee: window.terminologyEmployee }
                  ),
                }}
              ></span>
            </h5>
          </div>

          <div className='flex justify-center mt-10'>
            <a
              href={tenantPath(DASHBOARD_PATH, true)}
              className='btn-primary mx-auto py-2 px-4 text-sm md:text-base font-bold'
            >
              BACK TO DASHBOARD
            </a>
          </div>

          <div className='w-full mt-10 md:mt-16'>
            <SVG src={ImageUrl} />
          </div>
        </div>
      </div>
    </FullLayoutModal>
  );
};
