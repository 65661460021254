import { Controller } from "stimulus";
import Turbolinks from "turbolinks";

export default class extends Controller {
  static values = {
    url: String,
  };
  declare urlValue: string;
  handleChangePerPage(event: InputEvent): void {
    const target = event.target as HTMLSelectElement;
    const splittedPath = this.urlValue.split("?");
    const path = splittedPath[0];
    let query = "";
    if (splittedPath.length > 1) {
      query = splittedPath[1];
    }

    const urlParams = new URLSearchParams(query);
    urlParams.append(target.name, target.value);
    Turbolinks.visit(`${path}?${urlParams.toString()}`);
  }
}
