import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "element"];

  declare inputTargets: Array<HTMLInputElement>;
  declare elementTargets: Array<HTMLInputElement>;

  connect(): void {
    const checkedInputs = this.inputTargets.filter((input) => input.checked);

    this.hideAllElementTargets();
    checkedInputs.forEach((checkedInput) =>
      this.showElements(checkedInput.value)
    );
  }

  handleChange(event): void {
    const value = event.target.value;

    if (event.target.checked) this.showElements(value);
    else this.hideElements(value);
  }

  hideElements(value): void {
    const Elements = this.elementTargets.filter((element) => {
      const values = element.dataset.showValues.split(",");
      return values.includes(value);
    });

    Elements.forEach((element) => {
      element.classList.add("hidden");
      if (element.querySelector("input"))
        element
          .querySelectorAll("input")
          .forEach((input) => (input.disabled = true));
    });
  }

  showElements(value): void {
    const Elements = this.elementTargets.filter((element) => {
      const values = element.dataset.showValues.split(",");
      return values.includes(value);
    });

    Elements.forEach((element) => {
      element.classList.remove("hidden");
      if (element.querySelector("input"))
        element
          .querySelectorAll("input")
          .forEach((input) => (input.disabled = false));
    });
  }

  hideAllElementTargets(): void {
    this.elementTargets.forEach((element) => {
      element.classList.add("hidden");
      if (element.querySelector("input"))
        element
          .querySelectorAll("input")
          .forEach((input) => (input.disabled = true));
    });
  }
}
