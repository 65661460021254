import { debounce } from "lodash";
import { useState, useCallback } from "react";

export const useDebouncedState = (
  initialState: string,
  wait: number
): [string, (value: string) => void] => {
  const [state, setState] = useState(initialState);
  const debounceFn = useCallback(debounce(setState, wait), []);
  return [state, debounceFn];
};
