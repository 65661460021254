import { Checkbox } from "@/ui/Checkbox";

interface AttendanceHeadingProps {
  selectAllAttendance: () => void;
  isSelectAll: boolean;
}
export const AttendancesForInvoicingHeading = ({
  selectAllAttendance,
  isSelectAll,
}: AttendanceHeadingProps): JSX.Element => {
  return (
    <tr>
      <th scope='col'>
        <Checkbox onChange={selectAllAttendance} checked={isSelectAll} />
      </th>
      <th scope='col'>Attendance Id</th>
      <th scope='col'>Job</th>
      <th scope='col'>Weployer</th>
      <th scope='col'>{window.terminologyEmployee}</th>
      <th scope='col'>Attendance Date</th>
      <th scope='col'>Hours</th>
      <th scope='col'>Issues</th>
      <th scope='col'>Bill To</th>
    </tr>
  );
};
