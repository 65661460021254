import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button", "checkbox"];
  declare buttonTarget: HTMLButtonElement;
  declare checkboxTargets: Array<HTMLInputElement>;

  connect(): void {
    this.buttonTarget.disabled = true;
  }

  toggle(event: Event): void {
    event.preventDefault();
    this.buttonTarget.disabled = !this.checkboxTargets
      .map((checkbox) => {
        return checkbox.checked;
      })
      .includes(true);
  }
}
