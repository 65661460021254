import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "importRecipientsHeader",
    "importRecipientsContainer",
    "nextButtonContainer",
    "sendMessagesHeader",
    "sendMessagesContainer",
    "sendMessageButtonContainer",
    "fileInput",
    "template",
    "filenamesList",
    "details",
    "sendTo",
    "errorDetailsContainer",
    "errorDetails",
    "modal",
    "cancelModal",
    "loading",
  ];

  declare formTarget: HTMLFormElement;
  declare importRecipientsHeaderTarget: HTMLElement;
  declare importRecipientsContainerTarget: HTMLElement;
  declare nextButtonContainerTarget: HTMLElement;
  declare sendMessagesHeaderTarget: HTMLElement;
  declare sendMessagesContainerTarget: HTMLElement;
  declare sendMessageButtonContainerTarget: HTMLElement;
  declare fileInputTarget: HTMLInputElement;
  declare templateTarget: HTMLTemplateElement;
  declare filenamesListTarget: HTMLElement;
  declare detailsTarget: HTMLElement;
  declare sendToTarget: HTMLElement;
  declare errorDetailsContainerTarget: HTMLElement;
  declare errorDetailsTarget: HTMLElement;
  declare cancelModalTarget: HTMLElement;
  declare modalTarget: HTMLElement;
  declare loadingTarget: HTMLElement;

  private hiddenClass = "hidden";

  connect(): void {
    this.clearErrorDetails();
    this.clearFileList();
    this.detailsTarget.innerHTML = "No files chosen";
    this.cancelModalTarget.addEventListener("click", this.hideModal.bind(this));
  }

  showModal(e): void {
    e.preventDefault();
    this.modalTarget.classList.remove(this.hiddenClass);
  }

  hideModal(): void {
    this.modalTarget.classList.add(this.hiddenClass);
  }

  showLoading(): void {
    this.loadingTarget.classList.remove(this.hiddenClass);
  }

  hideLoading(): void {
    this.loadingTarget.classList.add(this.hiddenClass);
  }

  handleSelection(): void {
    const files = this.fileInputTarget.files;
    this.updateDetails(files.length);
    this.listFilenames(files);
  }

  handleSubmitButtonOnClick(e): void {
    e.preventDefault();
    this.clearErrorDetails();
    if (this.fileInputTarget.files[0]) {
      this.reader(this.fileInputTarget.files[0], () => {
        const formData = new FormData(this.formTarget);
        formData.append("commit", e.target.value);
        this.hideModal();
        this.showLoading();
        this.handleFormSubmit(formData);
      });
    } else {
      this.errorDetailsContainerTarget.classList.remove(this.hiddenClass);
      this.errorDetailsTarget.innerHTML =
        "<p class='font-bold'>File can't be blank</p>";
    }
  }

  private reader(file, callback): void {
    const fr = new FileReader();
    fr.onload = () => callback();
    fr.onerror = () => {
      this.hideModal();
      this.hideLoading();
      this.errorDetailsContainerTarget.classList.remove(this.hiddenClass);
      this.errorDetailsTarget.innerHTML =
        "<p class='font-bold'>There are some problems with your file content, please reload page and try again</p>";
    };
    fr.readAsText(file);
  }

  private handleFormSubmit(formData): void {
    Rails.ajax({
      type: "post",
      url: "/admin/sms/bulks",
      data: formData,
      success: (data) => {
        if (data.success) {
          if (data.validate_file_only) {
            this.hideImportRecipients();
            this.clearErrorDetails();
            this.moveToSendMessagesStep(data);
          }
        } else if (data.error) {
          this.showErrorDetails(data);
        }
        this.hideLoading();
      },
    });
  }

  private moveToSendMessagesStep(data): void {
    this.sendToTarget.innerHTML = `To ${data.valid_recipients.length} recipients from <span class="underline">${data.file_name}</span>`;
    this.sendMessagesHeaderTarget.classList.remove(this.hiddenClass);
    this.sendMessagesContainerTarget.classList.remove(this.hiddenClass);
    this.sendMessageButtonContainerTarget.classList.remove(this.hiddenClass);
  }

  private hideImportRecipients(): void {
    this.importRecipientsHeaderTarget.classList.add(this.hiddenClass);
    this.importRecipientsContainerTarget.classList.add(this.hiddenClass);
    this.nextButtonContainerTarget.classList.add(this.hiddenClass);
  }

  private clearErrorDetails(): void {
    this.errorDetailsContainerTarget.classList.add(this.hiddenClass);
    this.errorDetailsTarget.innerHTML = "";
  }

  private showErrorDetails(data): void {
    this.errorDetailsContainerTarget.classList.remove(this.hiddenClass);
    let errorDetails = `<p class="font-bold">${data.error}</p>`;
    if (data.invalid_recipients.length > 0) {
      errorDetails += "<ul>";
      data.invalid_recipients.forEach(function (item) {
        errorDetails += `<li class="mt-2 odd:bg-neutral-50 even:bg-neutral-100 p-2">row ${item.lineno}: ${item.error_msg}</li>`;
      });
      errorDetails += "</ul>";
    }
    this.errorDetailsTarget.innerHTML = errorDetails;
  }

  private clearFileList(): void {
    this.filenamesListTarget.innerHTML = "";
  }

  private updateDetails(filesLength): void {
    if (filesLength === 0) {
      this.detailsTarget.innerHTML = "No files chosen";
    } else {
      this.detailsTarget.innerHTML = "";
    }
  }

  private listFilenames(files): void {
    this.clearFileList();
    Array.from(files)
      .map(({ name }) => name)
      .sort()
      .forEach((filename) => {
        const listItem = this.getListItemElementFromTemplate();
        listItem.innerText = filename;
        listItem.prepend(this.getIconCheckCircle());
        listItem.append(this.getIconBin());
        this.filenamesListTarget.appendChild(listItem);
      });
  }

  private getListItemElementFromTemplate(): HTMLElement {
    return this.templateTarget.content
      .querySelector("li")
      .cloneNode(true) as HTMLElement;
  }

  private getIconCheckCircle(): HTMLElement {
    return this.templateTarget.content
      .querySelector("svg.icon-check-circle")
      .cloneNode(true) as HTMLElement;
  }

  private getIconBin(): HTMLElement {
    return this.templateTarget.content
      .querySelector("a.link-icon-bin")
      .cloneNode(true) as HTMLElement;
  }
}
