// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as ActiveStorage from "@rails/activestorage";
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import "channels";
import "chartkick/chart.js";
// Tailwind CSS
import "../stylesheets/application.scss";

window.dispatchMapsEvent = function (...args) {
  const event = new Event("google-maps-callback", {
    bubbles: true,
    cancelable: true,
  });
  event.args = args;
  window.dispatchEvent(event);
};

Rails.start();
Turbolinks.start();
ActiveStorage.start();

const importAll = (r) => r.keys().forEach(r);
require.context("../images", true, /\.(jpg|jpeg|png)$/);
importAll(require.context("../../assets/images", true, /\.svg$/));

import "./bugsnag";
import "controllers";
import "./client_app";
import "./organization_app";
import "./react_components";
