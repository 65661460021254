import clsx from "clsx";
import { gaClickTrack } from "helpers/ga_event_tracking";
import * as React from "react";
import SVG from "react-inlinesvg";

import DownArrow from "@assets/icons/down.svg";
import { Attendance as AttendanceType } from "@graphql/client_app/types";

import {
  minimumEngagementChip,
  publicHolidayChip,
  updatedTimesheetChip,
  workedThroughBreakChip,
} from "@/client_app/features/timesheetApproval/components/FlaggedAttendancesGroupListItem";
import {
  getBreakTimes,
  getDailyTotal,
} from "@/client_app/features/timesheetApproval/utils/operations";
import { Checkbox } from "@/ui/Checkbox";

interface SubmittedAttendancesGroupListItemProps {
  attendance: AttendanceType;
  attendancesCount: number;
  stackOrder: number;
  isExpanded: boolean;
  isMobile: boolean;
  minimumEngagementRequired: boolean;
  belowMinimumEngagementReasonToHuman: string;
  humanReadableMinimumEngagementDuration: string;
  workedThroughBreak: boolean;
  hasPublicHoliday: boolean;
  isResubmitted: boolean;
  onExpandList: (event: React.MouseEvent) => void;
  onAttendanceToggle: (attendanceId: string) => void;
  checkedAttendances: string[];
}

export const SubmittedAttendancesGroupListItem = React.forwardRef(
  (
    {
      attendance,
      attendancesCount,
      stackOrder,
      isExpanded,
      isMobile,
      minimumEngagementRequired,
      belowMinimumEngagementReasonToHuman,
      isResubmitted,
      humanReadableMinimumEngagementDuration,
      workedThroughBreak,
      hasPublicHoliday,
      onExpandList,
      onAttendanceToggle,
      checkedAttendances,
    }: SubmittedAttendancesGroupListItemProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [showAdditionalNotes, setShowAdditionalNotes] = React.useState(false);
    const cellClassNames = "col-span-full md:col-span-2";
    const style = {
      transform: isExpanded
        ? "translateY(0%)"
        : `translateY(${(stackOrder - 1) * -100}%)`,
    };

    function gaEventTracking(clickText: string) {
      gaClickTrack({
        click_text: clickText,
      });
    }

    function handleViewAllShifts(event: React.MouseEvent): void {
      onExpandList(event);
      gaEventTracking("VIEW ALL SHIFTS");
    }

    function renderActionCellContent(
      attendancesCount: number,
      isExpanded: boolean
    ): JSX.Element {
      if (attendancesCount > 1 && !isExpanded) {
        return (
          <button
            className='flex font-semibold text-primary-900'
            onClick={handleViewAllShifts}
          >
            <span className='flex items-center'>
              VIEW ALL SHIFTS
              <SVG
                src={DownArrow}
                className='icon fill-current mr-4 cursor-pointer'
              />
            </span>
          </button>
        );
      }
    }

    return (
      <div
        ref={ref}
        style={style}
        className={clsx(
          "border-neutral-300 border-2 rounded-xl bg-shade-50",
          "text-neutral-700 text-left font-semibold md:text-center",
          "p-2",
          "relative origin-top-left transition-all",
          {
            "mb-2 md:mb-4 last:mb-0": isExpanded,
            "z-50": stackOrder === 1,
            "opacity-0 z-[-999]": stackOrder > 1 && !isExpanded,
            "opacity-1": isExpanded,
          }
        )}
      >
        <div className='h-full grid grid-cols-11 items-center p-4 gap-2 md:p-6 xl:gap-x-2.5 2xl:gap-x-6'>
          <div className='col-span-full justify-self-end md:justify-self-auto -mt-11 md:mt-0 md:col-span-1'>
            {isExpanded && (
              <Checkbox
                checked={checkedAttendances.includes(attendance.id)}
                onChange={() => {
                  onAttendanceToggle(attendance.id);
                }}
              />
            )}
          </div>
          <div className='col-span-full text-button-md text-primary-900 font-bold order-first md:text-neutral-700 md:font-semibold md:col-span-2 md:order-none'>
            {attendance.workedOnDate}
          </div>
          <div className={cellClassNames}>
            <span className='md:hidden'>Hours: </span>
            <span className='font-normal md:font-semibold'>
              {`${attendance.startTime} - ${attendance.finishTime}`}
            </span>
          </div>
          <div className={cellClassNames}>
            <span className='md:hidden'>Breaks: </span>
            <span className='font-normal md:font-semibold'>
              {getBreakTimes(attendance.breaks).map((breakTime, index) => (
                <div key={index}>{breakTime}</div>
              ))}
            </span>
          </div>
          <div className={cellClassNames}>
            <span className='md:hidden'>Daily Total: </span>
            <span
              className={clsx(
                "font-normal md:font-semibold",
                minimumEngagementRequired && "text-error-600"
              )}
            >
              {getDailyTotal(attendance.duration, attendance.breaks)}
            </span>
          </div>
          <div className='col-span-2 hidden md:block'>
            {renderActionCellContent(attendancesCount, isExpanded)}
          </div>
          {(minimumEngagementRequired ||
            hasPublicHoliday ||
            isResubmitted ||
            workedThroughBreak ||
            !!attendance.additionalNotes) && (
            <div className='col-span-full text-left md:mt-1.5 md:px-7'>
              <hr className='border-neutral-100 hidden md:block md:mx-6' />
              <div className='p-4 md:px-6 md:py-4'>
                {minimumEngagementRequired && !isMobile && (
                  <div className='grid grid-cols-11 gap-2 xl:gap-x-2.5 2xl:gap-x-6 mb-2'>
                    <div className='col-span-7'>
                      <div className='paragraph-1'>
                        {minimumEngagementChip}
                        <div>
                          <strong className='font-bold'>
                            Minimum engagment reason:{" "}
                          </strong>
                          {belowMinimumEngagementReasonToHuman}
                        </div>
                      </div>
                    </div>
                    <div className='col-span-2 text-center font-normal text-error-600 md:font-bold'>
                      {humanReadableMinimumEngagementDuration}
                    </div>
                  </div>
                )}
                {hasPublicHoliday && !isMobile && (
                  <div className='mb-2'>{publicHolidayChip}</div>
                )}
                {isResubmitted && !isMobile && (
                  <div className='mb-2'>{updatedTimesheetChip}</div>
                )}
                {workedThroughBreak && !isMobile && (
                  <div className='mb-2'>{workedThroughBreakChip}</div>
                )}
                {minimumEngagementRequired && isMobile && (
                  <div className='paragraph-1 mb-2'>
                    <strong className='font-bold'>
                      Minimum engagment reason:{" "}
                    </strong>
                    {belowMinimumEngagementReasonToHuman}
                  </div>
                )}
                {!!attendance.additionalNotes && (
                  <div className='paragraph-1 mb-2'>
                    <span className='font-bold'>Additional Notes:</span>{" "}
                    <span>{attendance.additionalNotes}</span>{" "}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);
