import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  declare autocomplete: google.maps.places.Autocomplete;
  declare inputTarget: HTMLInputElement;

  connect(): void {
    if (typeof google != "undefined") {
      this.initAutocomplete();
    }
  }

  initAutocomplete(): void {
    this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget);
    this.autocomplete.setFields(["address_components"]);
    this.autocomplete.setOptions({
      componentRestrictions: { country: ["au"] },
      types: ["postal_code", "locality"],
    });

    this.inputTarget.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }
}
