import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selectAllCheckbox", "checkbox", "selectedCount"];

  static values = {
    candidatesCounterId: String,
  };

  declare selectAllCheckboxTarget: HTMLInputElement;
  declare checkboxTargets: [HTMLInputElement];
  declare selectedCountTarget: HTMLDivElement;
  declare hasSelectAllCheckboxTarget: boolean;
  declare candidatesCounterIdValue: string;
  declare candidatesCountTarget: HTMLSpanElement;

  connect(): void {
    this.candidatesCountTarget = document.getElementById(
      this.candidatesCounterIdValue
    );
    if (!this.hasSelectAllCheckboxTarget) return;

    if (this.selectAllCheckboxTarget.checked) {
      this.#selectAll();
    } else {
      this.#deSelectAll();
    }
  }

  toggle(event: Event): void {
    const target = event.target as HTMLInputElement;

    if (target.checked) {
      this.#selectAll();
    } else {
      this.#deSelectAll();
    }
  }

  handleChange(): void {
    if (this.checkboxTargets.every((checkbox) => checkbox.checked)) {
      this.#selectAll();

      return;
    }

    if (this.checkboxTargets.some((checkbox) => checkbox.checked)) {
      this.#showCounting();
      this.selectAllCheckboxTarget.checked = false;
      document.dispatchEvent(
        new CustomEvent("select_all_toggle_controller.some_selected")
      );
      return;
    }

    this.#deSelectAll();
    document.dispatchEvent(
      new CustomEvent("select_all_toggle_controller.deselect_all")
    );
  }

  #selectAll(): void {
    this.selectAllCheckboxTarget.checked = true;
    this.checkboxTargets.forEach((checkbox) => {
      if (!checkbox.disabled) checkbox.checked = true;
    });
    this.#showCounting();

    document.dispatchEvent(
      new CustomEvent("select_all_toggle_controller.select_all")
    );
  }

  #deSelectAll(): void {
    this.selectAllCheckboxTarget.checked = false;
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = false));
    this.#hideCounting();

    document.dispatchEvent(
      new CustomEvent("select_all_toggle_controller.deselect_all")
    );
  }

  #hideCounting(): void {
    this.selectedCountTarget.textContent = "";
    this.selectedCountTarget.classList.add("invisible");

    this.updateCandidatesCount(0);
  }

  #showCounting(): void {
    const selectedRows = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked
    );
    this.selectedCountTarget.textContent = `${selectedRows.length} Selected`;
    this.selectedCountTarget.classList.remove("invisible");
    this.updateCandidatesCount(selectedRows.length);
  }

  updateCandidatesCount(count) {
    if (!this.candidatesCountTarget) return;

    const candidateText = count > 1 ? "candidates" : "candidate";
    this.candidatesCountTarget.innerHTML = `${count.toString()} ${candidateText}`;
  }
}
