import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];
  static values = {
    editUrl: String,
  };
  declare formTarget: HTMLFormElement;
  declare editUrlValue: string;

  refreshPageWithParams(): void {
    const formData = new FormData(this.formTarget);
    formData.set("_method", "POST");
    const params = new URLSearchParams(formData as URLSearchParams);

    Rails.ajax({
      type: "POST",
      url: this.editUrlValue,
      dataType: "json",
      data: params.toString(),
    });
  }
}
