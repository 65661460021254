import { tenantPath } from "helpers/tenant_path";
import { useState, useEffect } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { useLocation, useSearchParams } from "react-router-dom";

import graphqlClient from "@graphql/client_app/client";
import {
  useFetchAllSubmittedAttendancesQuery,
  useFetchAllFlaggedAttendancesQuery,
} from "@graphql/client_app/types";

import {
  SUBMITTED_ATTENDANCES_PATH,
  REVIEW_ATTENDANCES_PATH,
} from "@/client_app/config";

import { ApprovalSuccess } from "./ApprovalSuccess";
import { AttendancesApprovalTab } from "./AttendancesApprovalTab";

import { FilterByManager } from "@/client_app/components/FilterByManager";
import { Form } from "@/client_app/components/Form";
import { useBackendContext } from "@/client_app/stores/backend-context";
import { usePageTitle } from "@/hooks/usePageTitle";

type locationStateType = {
  from: string;
};

export const AttendanceApproval = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { currentClientCompanyUserId, titlePage } = useBackendContext();
  const location = useLocation();

  const [selectedManager, setSelectedManager] = useState({
    id: searchParams.has("manager_id")
      ? searchParams.get("manager_id")
      : currentClientCompanyUserId,
    name: "Me",
  });

  const {
    isFetching: isFetchingSubmittedAttendances,
    data: dataSubmittedAttendances,
    error: errorSubmittedAttendances,
    refetch: refetchAllSubmittedAttendances,
  } = useFetchAllSubmittedAttendancesQuery(graphqlClient, {
    managerId: selectedManager.id,
  });

  const {
    isFetching: isFetchingFlaggedAttendances,
    data: dataFlaggedAttendances,
    error: errorFlaggedAttendances,
    refetch: refetchAllFlaggedAttendances,
  } = useFetchAllFlaggedAttendancesQuery(graphqlClient, {
    managerId: selectedManager.id,
  });

  usePageTitle("Timesheet Approval", titlePage);

  useEffect(() => {
    if (!location.state) return;

    const { from } = location.state as locationStateType;

    if (from === ApprovalSuccess.name) {
      refetchAllSubmittedAttendances();
      refetchAllFlaggedAttendances();
    }
  });

  if (errorSubmittedAttendances || errorFlaggedAttendances) {
    return <div>Error!</div>;
  }

  if (isFetchingSubmittedAttendances || isFetchingFlaggedAttendances) {
    return <span>Loading...</span>;
  }

  const submittedAttendancesCount =
    dataSubmittedAttendances.submittedAttendances.length;

  const flaggedAttendancesCount =
    dataFlaggedAttendances.flaggedAttendances.length;

  return (
    <>
      <div className='pb-3 pt-6 md:pb-6'>
        <h5 className='font-bold text-shade-100 md:h4'>Timesheet Approval</h5>
      </div>
      <div className='flex justify-between border-b-2 border-neutral-200 flex-col-reverse md:flex-row'>
        <div className='flex gap-4 lg:gap-12'>
          <AttendancesApprovalTab
            to={tenantPath(SUBMITTED_ATTENDANCES_PATH)}
            count={submittedAttendancesCount}
            label='Submitted'
          />
          <AttendancesApprovalTab
            to={tenantPath(REVIEW_ATTENDANCES_PATH)}
            count={flaggedAttendancesCount}
            label='To Review'
          />
        </div>
        <div>
          <Form>
            <FilterByManager
              selectedManagerId={selectedManager.id}
              setSelectedManager={setSelectedManager}
            />
          </Form>
        </div>
      </div>
      <Outlet
        context={{
          selectedManagerId: selectedManager.id,
          refetchAllFlaggedAttendances,
          refetchAllSubmittedAttendances,
        }}
      />
    </>
  );
};

type OutletContextType = {
  selectedManagerId: string;
  refetchAllFlaggedAttendances: () => void;
  refetchAllSubmittedAttendances: () => void;
};

export function useAttendanceApprovalOutletContext(): OutletContextType {
  return useOutletContext<OutletContextType>();
}
