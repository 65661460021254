import { useState } from "react";
import {
  FieldValues,
  SubmitHandler,
  useForm,
  FormProvider,
} from "react-hook-form";

import graphqlClient from "@graphql/organization_app/client";
import { useCreatePreviewInvoiceMutation } from "@graphql/organization_app/types";

import {
  ErrorMessage,
  Input,
  Select,
  buildOptions,
  RadioGroup,
} from "@/organization_app/components/formFields";
import { PrimaryButton, SecondaryButton } from "@/ui/buttons";
import { flash } from "@/ui/Flash";

interface PreviewInvoicesBillingFormFields {
  billingContactId: string;
  invoicesConsolidation: "true" | "false";
  reference: string;
}

const consolidateAttendancesOptions = [
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];

export const PreviewInvoicesBillingForm = ({
  clientCompany,
  closeModal,
  attendanceCostItems,
}): JSX.Element => {
  const formMethods = useForm<PreviewInvoicesBillingFormFields>({
    shouldUnregister: true,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutateAsync } = useCreatePreviewInvoiceMutation(graphqlClient);
  const disabledSubmit = !(
    attendanceCostItems && attendanceCostItems.length > 0
  );

  const createInvoice: SubmitHandler<FieldValues> = (values) => {
    if (disabledSubmit) return;
    setIsSubmitting(true);
    const variables = {
      clientCompanyId: clientCompany.id,
      billingContactId: values.billingContactId,
      attendanceIds: attendanceCostItems.map((item) => item.attendanceId),
      invoicesConsolidation: values.invoicesConsolidation === "true",
      reference: values.reference,
    };

    mutateAsync(variables).then((data) => {
      const errors = data.createPreviewInvoice.errors;
      setIsSubmitting(false);

      if (errors.length > 0) {
        handleFailure(errors);
      } else {
        handleSuccess();
      }
    });
  };

  function handleSuccess() {
    flash.notice("Invoices have been sent to Xero");
    closeModal();
  }

  function handleFailure(errors): void {
    errors.forEach((error) => {
      formMethods.setError(error.path, {
        type: "custom",
        message: error.message,
      });
    });
  }

  return (
    <div>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(createInvoice)}>
          <div className='bg-legacyColor-primary-50 rounded p-8 border border-legacyColor-neutral-1-500 mb-8 min-w-[370px]'>
            <div className='text-button mb-4'>Billing Information</div>
            <div className='flex items-center mb-8'>
              <label className='mr-8 text-legacyColor-neutral-1-500 w-1/5 text-overline'>
                Billing Contact
              </label>
              <div className='flex-1'>
                <Select
                  name='billingContactId'
                  id='billingContactId'
                  defaultValue={""}
                  prompt={"Please select a contact"}
                  options={buildOptions(
                    clientCompany.billingContacts,
                    "id",
                    "billingName"
                  )}
                />
                <ErrorMessage name='billingContactId' />
              </div>
            </div>
            <div className='flex items-center mb-8'>
              <label className='mr-8 text-legacyColor-neutral-1-500 w-1/5 text-overline'>
                Company
              </label>
              <div className='flex-1'>{clientCompany.name}</div>
            </div>
            <div className='flex items-center mb-8'>
              <label className='mr-8 text-legacyColor-neutral-1-500 w-1/5 text-overline'>
                Reference
              </label>
              <div className='flex-1'>
                <Input name='reference' id='reference' defaultValue={""} />
                <ErrorMessage name='reference' />
              </div>
            </div>
            <div>
              <div className='mr-8 text-legacyColor-neutral-1-500 text-overline mb-2'>
                Consolidate attendances
              </div>
              <RadioGroup
                name='invoicesConsolidation'
                options={consolidateAttendancesOptions}
              />
              <ErrorMessage name='invoicesConsolidation' />
            </div>
          </div>

          <div className='flex justify-between items-center'>
            <SecondaryButton label='Cancel' onClick={closeModal} />
            <PrimaryButton
              type='submit'
              label='Send to Xero'
              loading={isSubmitting}
              disabled={disabledSubmit}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
