import clsx from "clsx";

export const MinimumEngagementChip = (): JSX.Element => {
  return (
    <div
      className={clsx(
        "inline-block rounded-full py-1 px-3",
        "text-button-sm font-bold uppercase",
        "bg-error-100 text-error-600"
      )}
    >
      Minimum Engagement
    </div>
  );
};
