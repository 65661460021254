import { useEffect } from "react";

import graphqlClient from "@graphql/organization_app/client";
import { useFetchAttendancesForInvoicingQuery } from "@graphql/organization_app/types";

import { AttendancesForInvoicingHeading } from "./AttendancesForInvoicingHeading";

import {
  getDateWorkedOn,
  getDailyTotal,
} from "@/organization_app/features/invoicings/utils/AttendanceUtils";
import { Checkbox } from "@/ui/Checkbox";

function renderIssue({
  minimumEngagementRequired,
  additionalNotes,
  belowMinimumEngagementReasonToHuman,
}): JSX.Element {
  if (minimumEngagementRequired) {
    return (
      <p title={additionalNotes}>
        <span>ME applied "{belowMinimumEngagementReasonToHuman}"</span>
        <sup> ?</sup>
      </p>
    );
  } else {
    return <p>-</p>;
  }
}

export const AttendancesForInvoicingTable = ({
  clientCompanyId,
  communityMemberName,
  selectedAttendanceIds,
  setSelectedAttendanceIds,
  selectedManager,
  selectedSubdivision,
  startAt,
  finishAt,
  isForceRefetch,
  setIsForceRefetch,
  selectedPositionTitle,
}): JSX.Element => {
  const paramsForQuery = {
    clientCompanyId,
    communityMemberName,
    managerId: selectedManager,
    subdivision: selectedSubdivision,
    startAt: startAt || null,
    finishAt: finishAt || null,
    positionTitle: selectedPositionTitle,
  };

  const {
    isFetching: attendancesForInvoicingIsFetching,
    data: attendancesForInvoicingData,
    error: attendancesForInvoicingError,
    refetch: refetchAttendances,
  } = useFetchAttendancesForInvoicingQuery(graphqlClient, paramsForQuery);

  useEffect(() => {
    if (isForceRefetch) {
      refetchAttendances();
      setIsForceRefetch(false);
    }
  }, [isForceRefetch]);

  if (attendancesForInvoicingError) {
    return <div>Error!</div>;
  }

  if (attendancesForInvoicingIsFetching) {
    return <span>Loading...</span>;
  }

  const attendances = attendancesForInvoicingData.attendancesForInvoicing;
  const attendanceIds = attendances.map((attendance) => attendance.id);

  function handleSelectAllAttendance(): void {
    setSelectedAttendanceIds((selectedAttendanceIds): string[] => {
      return selectedAttendanceIds.length === attendanceIds.length
        ? []
        : attendanceIds;
    });
  }

  function handleSelectAttendance(attendanceId): void {
    setSelectedAttendanceIds((selectedAttendanceIds): string[] => {
      if (selectedAttendanceIds.includes(attendanceId)) {
        return selectedAttendanceIds.filter((att) => att !== attendanceId);
      } else {
        return [...selectedAttendanceIds, attendanceId];
      }
    });
  }

  const isSelectAll =
    selectedAttendanceIds.length !== 0 &&
    selectedAttendanceIds.length === attendances.length;

  return (
    <table className='table-list text-xs'>
      <thead>
        <AttendancesForInvoicingHeading
          selectAllAttendance={handleSelectAllAttendance}
          isSelectAll={isSelectAll}
        />
      </thead>
      {attendances.length === 0 ? (
        <div className='py-2 px-6 text-body'>No attendances found</div>
      ) : (
        <tbody>
          {attendances.map((attendance) => (
            <tr key={attendance.id}>
              <td>
                <Checkbox
                  checked={selectedAttendanceIds.includes(attendance.id)}
                  onChange={() => {
                    handleSelectAttendance(attendance.id);
                  }}
                />
              </td>
              <td>{attendance.id}</td>
              <td>{attendance.position.title}</td>
              <td>{attendance.position.manager.fullName}</td>
              <td>{attendance.workedBy.fullName}</td>
              <td>{getDateWorkedOn(attendance.startAt)}</td>
              <td>
                {attendance.minimumEngagementRequired
                  ? getDailyTotal(attendance.minimumEngagementDuration, [])
                  : getDailyTotal(attendance.duration, attendance.breaks)}
              </td>
              <td>
                {renderIssue({
                  minimumEngagementRequired:
                    attendance.minimumEngagementRequired,
                  additionalNotes: attendance.additionalNotes,
                  belowMinimumEngagementReasonToHuman:
                    attendance.belowMinimumEngagementReasonToHuman,
                })}
              </td>
              <td>{attendance.position.manager.email}</td>
            </tr>
          ))}
        </tbody>
      )}
    </table>
  );
};
