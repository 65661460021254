import clsx from "clsx";

export const UpdatedTimesheetChip = (): JSX.Element => {
  return (
    <div
      className={clsx(
        "inline-block rounded-full py-1 px-3",
        "text-button-sm font-bold uppercase",
        "bg-warning-100 text-warning-600"
      )}
    >
      Updated Timesheet
    </div>
  );
};
