import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    classes: Array,
    autoHide: Boolean,
  };
  declare classesValue: Array<string>;
  declare autoHideValue: boolean;

  connect(): void {
    setTimeout(() => this.show(), 300);
    if (this.autoHideValue) {
      setTimeout(() => this.hide(), 5000);
    }
  }

  show(): void {
    this.element.classList.add(...this.classesValue);
  }

  hide(): void {
    this.element.classList.remove(...this.classesValue);
    setTimeout(() => this.destroy(), 1000);
  }

  destroy(): void {
    if (!this.element.parentNode) return;

    this.element.parentNode.removeChild(this.element);
  }
}
