import i18next from "i18next";
import "@/i18n/config";
import { useCallback } from "react";
import SVG from "react-inlinesvg";

import ComputerAndPlantImageUrl from "@assets/illustrations/computer_and_plant.svg";

interface NoPastPositionsProps {
  noSearchResults: boolean;
  noActivePositionImageURL: string;
}

const NoPastPositions = ({
  noSearchResults,
  noActivePositionImageURL,
}: NoPastPositionsProps): JSX.Element => {
  let heading;
  let textContent;
  const customStyles = {
    height: "200px",
    width: "100%",
  };

  if (noSearchResults) {
    heading = i18next.t("clientApp.noPastPositions.searchResults.heading");
    textContent = i18next.t(
      "clientApp.noPastPositions.searchResults.textContent"
    );
  } else {
    heading = i18next.t("clientApp.noPastPositions.defaultResults.heading");
    textContent = i18next.t(
      "clientApp.noPastPositions.defaultResults.textContent",
      {
        terminologyEmployee: window.terminologyEmployee,
      }
    );
  }
  const renderImage = useCallback(() => {
    if (noActivePositionImageURL) {
      return (
        <img
          src={noActivePositionImageURL}
          alt='Empty Stage Image'
          style={customStyles}
        />
      );
    } else {
      return <SVG src={ComputerAndPlantImageUrl} width='100%' height='200' />;
    }
  }, [noActivePositionImageURL]);
  return (
    <div className='mt-8 text-neutral-800 flex justify-center'>
      <div className='text-center'>
        {renderImage()}
        <h4 className='font-bold mb-3 mt-7'>{heading}</h4>
        <p className='font-semibold text-subheading'>{textContent}</p>
      </div>
    </div>
  );
};

export { NoPastPositions };
