import clsx from "clsx";
import i18next from "i18next";
import "@/i18n/config";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

import graphqlClient from "@graphql/client_app/client";
import { useFetchAllPastPositionsQuery } from "@graphql/client_app/types";

import { FilterByWeployee } from "./FilterByWeployee";
import { NoPastPositions } from "./NoPastPositions";
import { PastPositionListItemDesktop } from "./PastPositionListItemDesktop";
import { PastPositionListItemMobile } from "./PastPositionListItemMobile";
import { PositionTabs } from "./PositionTabs";
import { RehireRequestPreview, PositionType } from "./RehireRequestPreview";

import { FilterByManager } from "@/client_app/components/FilterByManager";
import { Form } from "@/client_app/components/Form";
import { useMediaQuery } from "@/client_app/hooks/useMediaQuery";
import { useBackendContext } from "@/client_app/stores/backend-context";
import { useDebouncedState } from "@/hooks/useDebouncedState";
import { usePageTitle } from "@/hooks/usePageTitle";
import { PrimaryButton } from "@/ui/buttons";
import { Checkbox } from "@/ui/Checkbox";

export type ActionsType = "REHIRE" | null;

export const PastPositions = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { currentClientCompanyUserId, titlePage, noActivePositionImageURL } =
    useBackendContext();
  const [selectedManager, setSelectedManager] = useState({
    id: searchParams.has("manager_id")
      ? searchParams.get("manager_id")
      : currentClientCompanyUserId,
    name: "Me",
  });
  const employees = window.terminologyEmployee + "s";
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [selectedPositionIds, setSelectedPositionIds] = useState([]);
  const [communityMemberName, setCommunityMemberName] = useDebouncedState(
    "",
    500
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [action, setAction] = useState<ActionsType>(null);

  const { isFetching, isSuccess, data, error } = useFetchAllPastPositionsQuery(
    graphqlClient,
    {
      communityMemberName,
      managerId: selectedManager.id,
    }
  );
  const pastPositions = data?.pastPositions || [];
  const selecteablePastPositionIds = pastPositions.flatMap(
    (position) => position.id
  );

  const tableHeaderStyleClasses =
    "text-button-md font-bold text-primary-900 uppercase";

  const handleSelectAllCheckboxChange = useCallback(
    (event) => {
      if (event.target.checked) {
        setSelectedPositionIds(pastPositions.map((position) => position.id));
      } else {
        setSelectedPositionIds([]);
      }
    },
    [selectedPositionIds, pastPositions]
  );

  const handleSelectCheckboxChange = (positionId: string) => {
    const newSelectedPositionIds = new Set(selectedPositionIds);
    newSelectedPositionIds.has(positionId)
      ? newSelectedPositionIds.delete(positionId)
      : newSelectedPositionIds.add(positionId);

    setSelectedPositionIds([...newSelectedPositionIds]);
  };

  const handleRehire = useCallback(() => {
    if (selectedPositionIds.length < 1) return;

    setIsModalOpen(true);
    setAction("REHIRE");
  }, [selectedPositionIds]);

  if (error) {
    return <div>Error!</div>;
  }

  const handleBack = () => {
    setIsModalOpen(false);
    setAction(null);
  };

  const getSelectedPositions = (): PositionType[] => {
    return pastPositions.filter(({ id }) => {
      return new Set(selectedPositionIds).has(id);
    });
  };

  usePageTitle(employees, titlePage);

  return (
    <>
      {isModalOpen && action === "REHIRE" && (
        <RehireRequestPreview
          isOpen={isModalOpen}
          onBack={handleBack}
          selectedPositions={getSelectedPositions()}
          setSelectedPositionIds={setSelectedPositionIds}
        />
      )}
      <h5 className='font-bold text-shade-100 md:h4 py-6'>{employees}</h5>
      <div>
        <div className='flex justify-between border-b-2 border-neutral-200'>
          <div className='flex gap-4 lg:gap-12'>
            <PositionTabs />
          </div>
          <div>
            <Form>
              <FilterByManager
                selectedManagerId={selectedManager.id}
                setSelectedManager={setSelectedManager}
              />
            </Form>
          </div>
        </div>
      </div>
      <Form>
        <div className='md:flex gap-x-6'>
          <FilterByWeployee
            setSelectedPositionIds={setSelectedPositionIds}
            communityMemberName={communityMemberName}
            setCommunityMemberName={(name: string) =>
              setCommunityMemberName(name)
            }
          />
          <div className='flex gap-x-2 items-center my-4 md:mt-6 md:my-0'>
            <Checkbox
              checked={
                selectedPositionIds.length === selecteablePastPositionIds.length
              }
              onChange={handleSelectAllCheckboxChange}
            />
            <p className='p3 font-semibold'>Select all</p>
            <div className='ml-4'>
              <PrimaryButton
                disabled={selectedPositionIds.length === 0}
                label={i18next.t(
                  "clientApp.pastPositions.button.requestRehire"
                )}
                onClick={handleRehire}
              />
            </div>
          </div>
        </div>
      </Form>
      {isFetching && <div>Loading...</div>}
      {!isFetching && isSuccess && (
        <div>
          <>
            {pastPositions.length === 0 && (
              <NoPastPositions
                noSearchResults={false}
                noActivePositionImageURL={noActivePositionImageURL}
              />
            )}
            {pastPositions.length > 0 &&
              isMobile &&
              pastPositions.map(
                ({ communityMember, finishOn, id, startOn, title }) => (
                  <div className='mb-2 last:mb-0' key={id}>
                    <PastPositionListItemMobile
                      communityMember={communityMember}
                      finishOn={finishOn}
                      startOn={startOn}
                      title={title}
                      checked={selectedPositionIds.includes(id)}
                      onChange={() => handleSelectCheckboxChange(id)}
                    />
                  </div>
                )
              )}
            {pastPositions.length > 0 && !isMobile && (
              <table
                className='w-full text-center border-separate table-auto'
                style={{ borderSpacing: "0 24px" }}
              >
                <thead className={clsx(tableHeaderStyleClasses)}>
                  <tr>
                    <th></th>
                    <th>
                      {i18next.t("clientApp.pastPositions.tableHeader.role")}
                    </th>
                    <th>
                      {i18next.t(
                        "clientApp.pastPositions.tableHeader.startDate"
                      )}
                    </th>
                    <th>
                      {i18next.t("clientApp.pastPositions.tableHeader.endDate")}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {pastPositions.map(
                    ({ communityMember, finishOn, id, startOn, title }) => (
                      <PastPositionListItemDesktop
                        communityMember={communityMember}
                        finishOn={finishOn}
                        startOn={startOn}
                        title={title}
                        key={id}
                        checked={selectedPositionIds.includes(id)}
                        onChange={() => handleSelectCheckboxChange(id)}
                      />
                    )
                  )}
                </tbody>
              </table>
            )}
          </>
        </div>
      )}
    </>
  );
};
