import clsx from "clsx";
import { tenantPath } from "helpers/tenant_path";
import i18next from "i18next";
import "@/i18n/config";
import { useState } from "react";
import Flatpickr from "react-flatpickr";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import { useNavigate } from "react-router-dom";

import DownArrow from "@assets/icons/down.svg";
import Info from "@assets/icons/info-primary-900.svg";
import UpArrow from "@assets/icons/up.svg";
import PeopleInLivingRoomImageUrl from "@assets/illustrations/people_in_living_room.svg";
import graphqlClient from "@graphql/client_app/client";
import {
  useFetchExtensionRequestReasonsQuery,
  useCreateExtensionRequestsMutation,
} from "@graphql/client_app/types";

import { WEPLOYEES_EXTENSION_REQUEST_SUCCESS_PATH } from "@/client_app/config";

import { PositionType } from "./PositionType";

import { CommunityMemberAvatar } from "@/client_app/components/CommunityMemberAvatar";
import { PrimaryButton } from "@/ui/buttons";
import { FullLayoutModal } from "@/ui/FullLayoutModal";
interface ExtensionRequestProp {
  onBack: () => void;
  isOpen: boolean;
  selectedPositions?: Array<PositionType>;
}

function ExtensionRequestPreview({
  onBack,
  selectedPositions = [],
}: ExtensionRequestProp): JSX.Element {
  const {
    register,
    handleSubmit: reactHookFormHandleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const mutation = useCreateExtensionRequestsMutation(graphqlClient);

  const [listExpanded, setListExpanded] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: extensionRequestReasonsData,
    isLoading: isLoadingExtensionRequestReasons,
    isSuccess: isSuccessExtensionRequestReasons,
  } = useFetchExtensionRequestReasonsQuery(graphqlClient);

  function getHeading() {
    return selectedPositions.length > 1
      ? `${selectedPositions.length} Weployees selected`
      : `${selectedPositions[0].communityMember.fullName} selected`;
  }

  function getLatestFinishOn() {
    const datesSorted = selectedPositions
      .map(({ finishOn }) => finishOn)
      .sort();
    const latestDate = datesSorted[datesSorted.length - 1];

    const latestDateObj = new Date(latestDate);
    latestDateObj.setDate(latestDateObj.getDate() + 1);

    return latestDateObj;
  }

  function formatDate(dateString) {
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
    };

    return new Date(dateString).toLocaleDateString("en-AU", dateOptions);
  }

  function handleSubmit(data): void {
    setIsSubmitting(true);
    const variables = {
      positionIds: selectedPositions.map(({ id }) => id),
      ...data.extension_request,
    };

    mutation.mutateAsync(variables).then((data) => {
      const errors = data.createExtensionRequests.errors;
      setIsSubmitting(false);

      if (errors.length > 0) {
        handleFailure(errors);
      } else {
        handleSuccess();
      }
    });
  }

  function handleFailure(errors): void {
    errors.forEach((error) => {
      setError(`extension_request.${error.path}`, {
        type: "custom",
        message: error.message,
      });
    });
  }

  function handleSuccess() {
    navigate(tenantPath(WEPLOYEES_EXTENSION_REQUEST_SUCCESS_PATH, true));
  }

  function onSelectDate(date: Date): void {
    setValue("extension_request.newFinishDate", date.toString());
  }

  return (
    <FullLayoutModal
      isOpen={true}
      title={"Extension Requests"}
      closeModal={onBack}
      subTitle=''
    >
      <div className='flex my-7 text-newColor-neutral-900'>
        <div>
          <div className='flex justify-between items-center mb-6'>
            <h6 className='font-bold md:font-semibold text-newColor-neutral-900 md:h4-sm'>
              {getHeading()}
            </h6>
            {selectedPositions.length > 1 && (
              <button
                className='font-semibold'
                onClick={() => setListExpanded(!listExpanded)}
              >
                <span className='flex items-center text-newColor-primary-900'>
                  {listExpanded ? "HIDE" : "VIEW"} ALL
                  <SVG
                    src={listExpanded ? UpArrow : DownArrow}
                    className='icon fill-current cursor-pointer'
                  />
                </span>
              </button>
            )}
          </div>
          <div>
            {selectedPositions.map(
              ({ id, communityMember, startOn, finishOn }) => {
                return (
                  <div
                    className={clsx(
                      "odd:bg-neutral-50 even:bg-neutral-100 flex items-center last:mb-6 p-3",
                      listExpanded ? "block" : "hidden"
                    )}
                    key={id}
                  >
                    <CommunityMemberAvatar
                      size='EXTRA_SMALL'
                      profileImageUrl={communityMember.profileImageUrl}
                      name={communityMember.fullName}
                    />
                    <div className='ml-4'>{communityMember.fullName}</div>
                    <div className='ml-auto'>
                      {formatDate(startOn)} - {formatDate(finishOn)}
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <form
            onSubmit={reactHookFormHandleSubmit(handleSubmit)}
            className='mb-2 w-11/12 md:w-96'
          >
            <div className='mb-7'>
              {isLoadingExtensionRequestReasons &&
                "Loading extension request reasons"}
              {isSuccessExtensionRequestReasons && (
                <>
                  <label
                    htmlFor='extension_request_reasons'
                    className={clsx(
                      "form-label",
                      !!errors?.extension_request?.reason?.message &&
                        "text-error-600"
                    )}
                  >
                    Reason for Extension
                  </label>
                  <select
                    id='extension_request_reasons'
                    className={clsx(
                      "form-select down-icon mb-2 bg-none",
                      !!errors?.extension_request?.reason?.message &&
                        "border-error-600"
                    )}
                    defaultValue=''
                    {...register("extension_request.reason")}
                  >
                    <option value='' hidden disabled>
                      Please Choose
                    </option>
                    <>
                      {extensionRequestReasonsData.extensionRequestReasons.map(
                        (option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          );
                        }
                      )}
                    </>
                  </select>
                  <div className='form-error first-letter:capitalize'>
                    {errors?.extension_request?.reason?.message}
                  </div>
                </>
              )}
            </div>
            <div className='mb-7'>
              <label htmlFor='time_requirements' className='form-label'>
                Time Requirements
              </label>
              <textarea
                id='time_requirements'
                className='form-control mb-2 h-28'
                placeholder='Eg. Weekly on Monday and Tuesday'
                {...register("extension_request.timeRequirements")}
              />
            </div>
            <div className='mb-7'>
              <label
                htmlFor='new_finish_date'
                className={clsx(
                  "form-label",
                  !!errors?.extension_request?.newFinishDate?.message &&
                    "text-error-600"
                )}
              >
                Select new finish date
              </label>
              <Flatpickr
                className={clsx(
                  "form-control",
                  !!errors?.extension_request?.newFinishDate?.message &&
                    "border-error-600"
                )}
                onClose={onSelectDate}
                name='new_finish_date_picker'
                options={{
                  dateFormat: "d F Y",
                  minDate: getLatestFinishOn(),
                }}
                placeholder='28 February 2022'
              />
              <input
                type='hidden'
                {...register("extension_request.newFinishDate")}
              />
              <div className='form-error first-letter:capitalize mt-2'>
                {errors?.extension_request?.newFinishDate?.message}
              </div>
            </div>
            <div className='flex items-center mb-7'>
              <SVG
                src={Info}
                className='w-[20px] h-[20px] min-w-[20px] min-h-[20px] mr-5 text-newColor-primary-900'
              />
              <span className='p2'>
                {i18next.t(
                  "clientApp.weployeesAcceptOrDeclineExtensionRequest",
                  { terminologyEmployee: window.terminologyEmployee }
                )}
              </span>
            </div>
            <PrimaryButton
              loading={isSubmitting}
              size='MEDIUM'
              type='submit'
              label='Request Extension'
            />
          </form>
        </div>
        <div className='w-full justify-center items-start hidden md:flex'>
          <SVG src={PeopleInLivingRoomImageUrl} className='w-[44rem]' />
        </div>
      </div>
    </FullLayoutModal>
  );
}

export { ExtensionRequestPreview };
