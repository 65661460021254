import Rails from "@rails/ujs";
import BaseController from "controllers/base_controller";
import { FormEvent } from "react";

export default class extends BaseController {
  showConversation(e: FormEvent<HTMLFormElement>) {
    this.toggleSelectedConversationStyle(e);
    if (e.currentTarget.dataset.showMessaegsModeValue == "normal") {
      window.location.href = e.currentTarget.dataset.getConversationUrlValue;
    } else {
      Rails.ajax({
        type: "get",
        url: e.currentTarget.dataset.getConversationUrlValue,
        dataType: "json",
        success: ({ replace_content }) => {
          const conversationContainer = document.getElementById(
            "conversation_container"
          );

          if (conversationContainer) {
            conversationContainer.innerHTML =
              replace_content.conversation_container;
          }

          this.scrollToTheBottom();
        },
      });
    }
  }

  toggleSelectedConversationStyle(e: FormEvent<HTMLFormElement>) {
    const activeElement = document.getElementsByClassName(
      "conversation-row active"
    )[0];
    if (activeElement) {
      activeElement.classList.remove("bg-neutral-200", "active");
    }

    e.currentTarget.classList.add("bg-neutral-200", "active");
  }
}
