import React, { useState } from "react";
import { useParams } from "react-router-dom";

import graphqlClient from "@graphql/organization_app/client";
import { useFetchClientCompanyQuery } from "@graphql/organization_app/types";

import { AttendancesForInvoicingFilter } from "./AttendancesForInvoicingFilter";
import { AttendancesForInvoicingSelected } from "./AttendancesForInvoicingSelected";
import { AttendancesForInvoicingTable } from "./AttendancesForInvoicingTable";
import { PreviewInvoices } from "./PreviewInvoices";

import { useDebouncedState } from "@/hooks/useDebouncedState";
import { Form } from "@/organization_app/components/formFields";
import { FullLayoutModal } from "@/ui/FullLayoutModal";

export const AttendancesForInvoicing = (): JSX.Element => {
  const { clientCompanyId } = useParams();
  const [communityMemberName, setCommunityMemberName] = useDebouncedState(
    "",
    500
  );
  const [selectedAttendanceIds, setSelectedAttendanceIds] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const [selectedSubdivision, setSubdivision] = useState("");
  const [selectedPositionTitle, setSelectedPositionTitle] = useState("");
  const [isOpen, setIsOpen] = useState(null);
  const [isForceRefetch, setIsForceRefetch] = useState(false);
  const [dateRange, setDateRange] = useState({
    startAt: "",
    finishAt: "",
  });

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setSelectedAttendanceIds([]);
    setIsOpen(false);
    setIsForceRefetch(true);
  };

  const {
    isFetching: clientCompanyIsFetching,
    data: clientCompanyData,
    error: clientCompanyError,
  } = useFetchClientCompanyQuery(
    graphqlClient,
    {
      clientCompanyId,
    },
    { refetchOnWindowFocus: false }
  );

  if (clientCompanyError) {
    return <div>Error!</div>;
  }

  if (clientCompanyIsFetching) {
    return <span>Loading...</span>;
  }

  return (
    <div className='text-legacyColor-neutral-1-500'>
      <header className='px-12 py-8 bg-legacyColor-neutral-2-100'>
        <h1 className='text-headline2 mb-4'>
          {clientCompanyData.clientCompany.name}
        </h1>
      </header>
      <ul className='flex px-12 text-legacyColor-neutral-1-500 bg-legacyColor-neutral-2-100'>
        <li className='mr-12 text-legacyColor-neutral-1-500 border-legacyColor-neutral-1-500 font-bold h-full border-b-2 pb-2'>
          <div className='px-2 mr-1 font-bold inline-block rounded-full text-legacyColor-neutral-1-500 bg-legacyColor-neutral-1-100'>
            {clientCompanyData.clientCompany.attendanceCount}
          </div>
          <a
            href={`/admin/invoicings/client_companies/${clientCompanyData.clientCompany.id}/attendances`}
          >
            To Invoice
          </a>
        </li>
        <li className='mr-12 '>
          <a
            href={`/admin/client_companies/${clientCompanyData.clientCompany.id}/billing_contacts`}
          >
            Billing Contacts
          </a>
        </li>
      </ul>
      <section className='m-12'>
        <Form>
          <AttendancesForInvoicingFilter
            communityMemberName={communityMemberName}
            selectedManager={selectedManager}
            selectedSubdivision={selectedSubdivision}
            setSelectedManager={setSelectedManager}
            setSubdivision={setSubdivision}
            setCommunityMemberName={setCommunityMemberName}
            setDateRange={setDateRange}
            selectedPositionTitle={selectedPositionTitle}
            setSelectedPositionTitle={setSelectedPositionTitle}
          />
        </Form>
        <AttendancesForInvoicingSelected
          selectedAttendanceIds={selectedAttendanceIds}
          openModal={openModal}
        />
        <AttendancesForInvoicingTable
          clientCompanyId={clientCompanyId}
          communityMemberName={communityMemberName}
          selectedAttendanceIds={selectedAttendanceIds}
          setSelectedAttendanceIds={setSelectedAttendanceIds}
          selectedManager={selectedManager}
          selectedSubdivision={selectedSubdivision}
          startAt={dateRange.startAt}
          finishAt={dateRange.finishAt}
          isForceRefetch={isForceRefetch}
          setIsForceRefetch={setIsForceRefetch}
          selectedPositionTitle={selectedPositionTitle}
        />

        {isOpen && (
          <FullLayoutModal
            isOpen={isOpen}
            closeModal={closeModal}
            title={"Invoice Preview"}
            subTitle={clientCompanyData.clientCompany.name}
          >
            <PreviewInvoices
              attendanceIds={selectedAttendanceIds}
              clientCompany={clientCompanyData.clientCompany}
              closeModal={closeModal}
            />
          </FullLayoutModal>
        )}
      </section>
    </div>
  );
};
