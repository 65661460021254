interface CostSummaryFeeItemProps {
  label: string;
  value: string;
}

export const CostSummaryFeeItem = ({
  label,
  value,
}: CostSummaryFeeItemProps): JSX.Element => {
  return (
    <div className='flex justify-between py-4 md:py-8 border-primary-100 border-t-2'>
      <span className='font-normal text-neutral-700'>{label}</span>
      <span className='font-semibold text-neutral-900 basis-4/12 text-right'>
        {value}
      </span>
    </div>
  );
};
