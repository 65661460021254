import { useEffect } from "react";

const NotFound = (): JSX.Element => {
  useEffect(() => {
    window.location.href = "/404";
  });

  return <></>;
};

export { NotFound };
