import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const metaData = document.getElementsByTagName("meta");
const releaseStage = metaData.releaseStage.content;

if (["production", "staging", "demo"].includes(releaseStage)) {
  let opts = {
    releaseStage,
    apiKey: metaData.bugsnagApiKey.content,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ["production", "staging", "demo"],
  };
  if (metaData.bugsnagAppVersion && metaData.bugsnagAppVersion.content) {
    opts.appVersion = metaData.bugsnagAppVersion.content;
  }
  Bugsnag.start(opts);
}

// listener for Google Maps API error
// we can use gm_authFailure but then we don't get any information about the error
const console = window.console;
let lastGoogleMapsApiError;
if (console) {
  const originalError = console.error;
  console.error = function () {
    if (
      arguments &&
      arguments[0] &&
      `${arguments[0]}`.startsWith("Google Maps JavaScript API error")
    ) {
      lastGoogleMapsApiError = arguments[0];
    }

    if (originalError.apply) {
      originalError.apply(console, arguments);
    }
  };
  window.gm_authFailure = function () {
    Bugsnag.notify({
      name: "Google Maps JavaScript API error",
      message: lastGoogleMapsApiError,
    });
    lastGoogleMapsApiError = undefined;
  };
}
