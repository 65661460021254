import { format } from "date-fns";
import pluralize from "pluralize";

export const getDateWorkedOn = (date: string): string =>
  format(new Date(date), "EEEE d LLL yyyy");

export const formatISO8601Date = (date: Date): string => {
  const year = date.toLocaleDateString("en-US", { year: "numeric" });
  const month = date.toLocaleDateString("en-US", { month: "2-digit" });
  const day = date.toLocaleDateString("en-US", { day: "2-digit" });

  return `${year}-${month}-${day}`;
};

type DurationSecond = number;
interface BreakTimesInterface {
  startAt: string;
  finishAt: string;
  duration: DurationSecond;
}

export const getDailyTotal = (
  durationSeconds: number,
  breaks?: BreakTimesInterface[]
): string => {
  const breakDurationSeconds = getBreakDuration(breaks);
  const durationExcludingBreaks = durationSeconds - breakDurationSeconds;

  return humanizeSecondsWorked(durationExcludingBreaks);
};

const humanizeSecondsWorked = (secondsWorked: number): string => {
  const hours = Math.floor(secondsWorked / 3600);
  const minutes = (secondsWorked % 3600) / 60;

  const hoursString = pluralize("hr", hours, true);
  const minutesString = pluralize("min", minutes, true);

  return `${hoursString} ${minutes ? minutesString : ""}`;
};

const getBreakDuration = (breaks: BreakTimesInterface[]): number => {
  return breaks.reduce((sum, brk): number => sum + brk.duration, 0);
};
