import { Controller } from "stimulus";
import Turbolinks from "turbolinks";

export default class extends Controller {
  static values = { url: String };
  declare urlValue: string;
  visit(): void {
    Turbolinks.visit(this.urlValue);
  }
}
