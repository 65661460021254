import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];
  static values = {
    url: String,
  };
  declare formTarget: HTMLFormElement;
  declare urlValue: string;

  refreshPageWithParams(): void {
    this.formTarget.setAttribute("method", "get");
    this.formTarget.setAttribute("action", this.urlValue);

    this.formTarget.submit();
  }
}
