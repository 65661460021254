import Inputmask from "inputmask";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["maskedInput"];

  declare maskedInputTargets: [HTMLInputElement];

  connect(): void {
    Inputmask({ placeholder: "" }).mask(this.maskedInputTargets);
  }
}
