import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["yesOption", "noOption", "visaCountry"];
  static values = {
    canClaim: Boolean,
  };
  declare yesOptionTargets: Array<HTMLInputElement>;
  declare noOptionTargets: Array<HTMLInputElement>;
  declare visaCountryTarget: HTMLInputElement;
  declare canClaimValue: boolean;

  connect(): void {
    if (this.canClaimValue) {
      this.enableClaims();
    } else {
      this.disableClaims();
    }
  }

  hideVisaCountry(): void {
    this.visaCountryTarget.classList.add("hidden");
  }

  showVisaCountry(): void {
    this.visaCountryTarget.classList.remove("hidden");
  }

  enableClaims(): void {
    this.yesOptionTargets.forEach((target) => (target.disabled = false));
  }

  disableClaims(): void {
    this.noOptionTargets.forEach((target) => (target.checked = true));
    this.yesOptionTargets.forEach((target) => (target.disabled = true));
  }
}
