import { Toggle } from "tailwindcss-stimulus-components";

export default class NavItemToggle extends Toggle {
  declare toggleableTargets: [HTMLInputElement];

  connect() {
    super.connect();
    this.toggleAtLoad();
  }

  toggleAtLoad() {
    const activeNavLinkItem = document.querySelector(
      ".left-nav-link-item.active"
    );
    if (!activeNavLinkItem) return;

    let sibling: HTMLElement = activeNavLinkItem.parentElement
      .previousElementSibling as HTMLElement;

    while (sibling) {
      if (sibling.matches(".toggle-btn")) {
        sibling.classList.add("toggle-active");
        sibling.click();
        return;
      }
      sibling = sibling.previousElementSibling as HTMLElement;
    }
  }
}
