import Rails from "@rails/ujs";
import BaseController from "controllers/base_controller";

export default class extends BaseController {
  sendRequestMobile(event: {
    preventDefault: () => void;
    currentTarget: HTMLElement;
  }) {
    event.preventDefault();
    const conversationElement = event.currentTarget;
    Rails.ajax({
      type: "post",
      url: conversationElement.dataset.markConversationAsReadUrlValue,
      success: ({ success, total_unread_messages_count }) => {
        if (success) {
          conversationElement.querySelector(".unread-mesasge-count")?.remove();
          document
            .querySelectorAll(".total-unread-messages-count")
            .forEach((element) => {
              if (total_unread_messages_count > 0) {
                element.innerHTML = total_unread_messages_count.toString();
              } else {
                element.remove();
              }
            });

          window.location.href =
            conversationElement.dataset.getConversationUrlValue;
        }
      },
    });
  }

  sendRequest(event: {
    preventDefault: () => void;
    currentTarget: HTMLElement;
  }) {
    const conversationElement = event.currentTarget;
    Rails.ajax({
      type: "post",
      url: conversationElement.dataset.markConversationAsReadUrlValue,
      success: ({ success, total_unread_messages_count }) => {
        if (success) {
          conversationElement.querySelector(".unread-mesasge-count")?.remove();
          document
            .querySelectorAll(".total-unread-messages-count")
            .forEach((element) => {
              if (total_unread_messages_count > 0) {
                element.innerHTML = total_unread_messages_count.toString();
              } else {
                element.remove();
              }
            });
        }
      },
    });
  }
}
