import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "content"];

  static values = {
    createMessageUrl: String,
  };

  declare formTarget: HTMLFormElement;
  declare contentTarget: HTMLInputElement;
  declare createMessageUrlValue: string;

  connect() {
    this.scrollToMessageForm();
  }

  sendMessage(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const formData = new FormData(this.formTarget);
    formData.append("content", this.contentTarget.value);
    Rails.ajax({
      type: "post",
      url: this.createMessageUrlValue,
      data: formData,
      dataType: "json",
      success: (data) => {
        document.getElementById("messages_container").innerHTML =
          data.replace_content.messages_container;
      },
    });
  }

  scrollToMessageForm() {
    const element = document.getElementById("messages_container");
    element.scrollIntoView(false);
  }
}
