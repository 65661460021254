import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "latInput",
    "lonInput",
    "withRadius",
    "withRadiusSelect",
  ];
  declare autocomplete: google.maps.places.Autocomplete;

  declare inputTarget: HTMLInputElement;
  declare latInputTarget: HTMLInputElement;
  declare lonInputTarget: HTMLInputElement;
  declare withRadiusTarget: HTMLDivElement;
  declare withRadiusSelectTarget: HTMLSelectElement;

  connect(): void {
    if (typeof google != "undefined") {
      this.initAutocomplete();
    }

    this.toggle();
  }

  initAutocomplete(): void {
    this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget);
    this.autocomplete.setFields(["geometry"]);
    this.autocomplete.setOptions({
      componentRestrictions: { country: ["au"] },
    });

    this.autocomplete.addListener(
      "place_changed",
      this.handlePlaceChanged.bind(this)
    );
    this.inputTarget.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }

  handlePlaceChanged(): void {
    const place = this.autocomplete?.getPlace();
    if (place?.geometry) {
      this.latInputTarget.value = place.geometry.location.lat().toString();
      this.lonInputTarget.value = place.geometry.location.lng().toString();
    } else {
      this.latInputTarget.value = "";
      this.lonInputTarget.value = "";
    }
    this.toggle();
  }

  toggle(): void {
    if (!this.inputTarget.value.trim()) {
      this.withRadiusTarget.classList.add("hidden");
      this.withRadiusSelectTarget.disabled = true;
    } else {
      this.withRadiusTarget.classList.remove("hidden");
      this.withRadiusSelectTarget.disabled = false;
    }
  }
}
