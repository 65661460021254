import { Controller } from "stimulus";

export default class extends Controller {
  readonly SESSION_STORAGE_KEY = "currentCommunityAppCalendarDay";

  static targets = ["day"];

  declare dayTargets: [HTMLInputElement];

  initialize(): void {
    this.setFocusedDay();
  }

  saveFocusedDay({ target }: { target: EventTarget }): void {
    if (target instanceof HTMLInputElement) {
      sessionStorage.setItem(this.SESSION_STORAGE_KEY, target.dataset.wday);
    }
  }

  setFocusedDay(): void {
    const selectedDay =
      sessionStorage.getItem(this.SESSION_STORAGE_KEY) || new Date().getDay();

    const selectedRadioButton = this.dayTargets.find(
      (day) => day.dataset.wday == selectedDay
    );

    selectedRadioButton.checked = true;
  }

  setFocusedDayToToday(): void {
    const today = String(new Date().getDay());

    sessionStorage.setItem(this.SESSION_STORAGE_KEY, today);

    const selectedRadioButton = this.dayTargets.find(
      (day) => day.dataset.wday == today
    );

    selectedRadioButton.checked = true;
  }
}
