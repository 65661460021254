import { tenantPath } from "helpers/tenant_path";
import i18next from "i18next";
import "@/i18n/config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import graphqlClient from "@graphql/client_app/client";
import {
  useFetchAttendancesCostSummaryQuery,
  useApproveAttendancesMutation,
} from "@graphql/client_app/types";

import { APPROVAL_SUCCESS_PATH } from "@/client_app/config";
import { APPROVAL_FAILURE_PATH } from "@/client_app/config";

import { ApproveAttendancesProfile } from "./ApproveAttendancesProfile";
import { CostSummaryAwardItems } from "./CostSummaryAwardItems";
import { CostSummaryFeeItem } from "./CostSummaryFeeItem";
import { FullLayoutModal } from "./FullLayoutModal";

import { PrimaryButton } from "@/ui/buttons";
import { Checkbox } from "@/ui/Checkbox";

interface ApproveAttendancesPreviewProp {
  onBack: () => unknown;
  isOpen: boolean;
  attendanceIds: string[];
}

export const COMPONENT_NAME = ApproveAttendancesPreview.name;

function ApproveAttendancesPreview({
  onBack,
  isOpen,
  attendanceIds,
}: ApproveAttendancesPreviewProp): JSX.Element {
  const navigate = useNavigate();
  const [approvalConsented, setApprovalConsented] = useState(false);
  const [sendingApproval, setSendingApproval] = useState(false);
  const [showErrors, setShowErrors] = useState([]);

  const mutation = useApproveAttendancesMutation(graphqlClient);
  const { isFetching, data, error } = useFetchAttendancesCostSummaryQuery(
    graphqlClient,
    { attendanceIds }
  );

  if (error) {
    return <div>Error!</div>;
  }

  if (isFetching) {
    return <span>Loading...</span>;
  }

  const {
    workedDates,
    communityMembers,
    primaryRates,
    secondaryRates,
    payrollComplianceFeeInAud,
    casualLoadingFeeInAud,
    serviceFeeInAud,
    gstInAud,
    grandTotalInAud,
    paymentTermSurchargeInAud,
  } = data.attendancesCostSummary;

  const modalTitle = () => {
    if (communityMembers.length > 1)
      return i18next.t("clientApp.approveMultipleWeployeesTimesheet", {
        terminologyEmployee: window.terminologyEmployee,
      });

    const weployeePreferredName = communityMembers[0].preferredName;
    return `Approve ${weployeePreferredName}'s Timesheet`;
  };

  const findPrefix = () => {
    if (communityMembers.length === 1) {
      return `?prefix=${communityMembers[0].preferredName}`;
    }
  };

  const handleSubmit = () => {
    const variables = {
      attendanceIds: attendanceIds,
      approvalConsent: approvalConsented,
    };

    setSendingApproval(true);

    mutation.mutateAsync(variables).then((data) => {
      const errors = data.approveAttendances.errors;

      if (errors.length === 0) {
        navigate(
          {
            pathname: tenantPath(APPROVAL_SUCCESS_PATH),
            search: findPrefix(),
          },
          {
            state: { from: COMPONENT_NAME },
          }
        );
      } else {
        navigate(
          {
            pathname: tenantPath(APPROVAL_FAILURE_PATH),
          },
          {
            state: { from: COMPONENT_NAME },
          }
        );

        setShowErrors(data.approveAttendances.errors);
      }
    });
  };

  const zeroPaymentTermSurcharge = (): boolean => {
    const value = paymentTermSurchargeInAud.split("$");
    return parseFloat(value[1]) <= 0;
  };

  return (
    <FullLayoutModal isOpen={isOpen} title={modalTitle()} closeModal={onBack}>
      <div className='max-w-screen-md'>
        {communityMembers.length > 1 ? (
          <div className='text-primary-900 mt-10'>
            <h4 className='h6-sm font-bold md:h4-sm'>
              Timesheet for {communityMembers.length}{" "}
              {window.terminologyEmployee}s
            </h4>
            <div className='justify-between font-semibold md:font-bold md:mt-8 md:flex'>
              <h4 className='p1 my-4 md:my-0 md:h4-sm'>
                Timesheet for: {workedDates}
              </h4>
              <h4 className='h6-sm text-neutral-900 md:text-inherit md:h4-sm'>
                Grand Total: {grandTotalInAud}
              </h4>
            </div>
          </div>
        ) : (
          <ApproveAttendancesProfile
            workedDates={workedDates}
            communityMember={communityMembers[0]}
          />
        )}

        <h5 className='font-semibold h6-sm text-neutral-900 mt-8 md:mt-10 md:h5'>
          Payrate Estimate
        </h5>

        <div className='p1 mt-4 md:mt-8 md:p3'>
          <div className='pb-4 md:pb-8'>
            <CostSummaryAwardItems payrates={primaryRates} />
          </div>

          {secondaryRates.length > 0 && (
            <div className='pb-4 border-primary-100 border-t-2 md:pb-8'>
              <CostSummaryAwardItems payrates={secondaryRates} />
              <p className='mt-6 text-caption font-normal text-neutral-600 md:mt-8 md:p1 md:pl-3'>
                *Penalty rates are calculated as per the awards for each
                profession.
              </p>
            </div>
          )}

          <CostSummaryFeeItem
            label='Payroll compliance fee (Super, Payroll Tax and Work Cover)'
            value={payrollComplianceFeeInAud}
          />
          <CostSummaryFeeItem
            label='Casual loading fee'
            value={casualLoadingFeeInAud}
          />
          <CostSummaryFeeItem label='Service fee' value={serviceFeeInAud} />
          <CostSummaryFeeItem label='GST' value={gstInAud} />
          {!zeroPaymentTermSurcharge() && (
            <CostSummaryFeeItem
              label='Payment Term Surcharge'
              value={paymentTermSurchargeInAud}
            />
          )}
        </div>

        <div className='flex justify-between py-4 md:py-8 border-primary-100 border-t-2 font-semibold p2 md:text-subheading'>
          <span className='text-neutral-700'>Grand Total</span>
          <span className='text-neutral-900 basis-4/12 text-right'>
            {grandTotalInAud}
          </span>
        </div>

        <div className='flex items-center mt-4 md:mt-10'>
          <Checkbox
            id='attendance_approvals_approval_consent'
            checked={approvalConsented}
            onChange={() => {
              setApprovalConsented(!approvalConsented);
            }}
          />
          <label
            htmlFor='attendance_approvals_approval_consent'
            className='form-label p1 text-neutral-900 ml-2 md:text-subheading'
          >
            By clicking this box I declare the hours worked are accurate and
            authorize payment for the total amount.
          </label>
        </div>
        <div className='mt-4 mb-7'>
          <>
            {showErrors.length > 0 &&
              showErrors.map((error) => {
                return (
                  <div className='form-error first-letter:capitalize'>
                    {error.message}
                  </div>
                );
              })}
          </>
          <PrimaryButton
            label='APPROVE TIMESHEETS'
            loading={sendingApproval}
            disabled={!approvalConsented}
            onClick={handleSubmit}
          />
          <p className='p2 text-secondary-700 py-7 md:text-subheading'>
            If this doesn't look quite right,{" "}
            <a
              href='mailto:applications@weployapp.com'
              className='font-semibold'
            >
              send us an email
            </a>{" "}
            and we'll get back to you.
          </p>
        </div>
      </div>
    </FullLayoutModal>
  );
}

export { ApproveAttendancesPreview };
