import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field"];
  declare fieldTarget: HTMLInputElement;

  initialize(): void {
    this.handleInput = this.handleInput.bind(this);
  }
  connect() {
    this.fieldTarget.addEventListener("input", this.handleInput);
  }

  getCompanyList() {
    const companyList = document.querySelector("#company-list");
    return JSON.parse(companyList.textContent);
  }

  handleInput(event: InputEvent): void {
    if (event.target instanceof HTMLInputElement) {
      const name = event.target.value;
      const nameWords = name.split(" ");
      const companyList = this.getCompanyList();
      const companyMatches = companyList.filter((conpany) =>
        nameWords.some((element) =>
          conpany.toLowerCase().includes(element.toLowerCase())
        )
      );

      let companyMatchesHTML = "";
      companyMatches.forEach(
        (company) => (companyMatchesHTML += `<li>${company}</li>`)
      );
      document.querySelector("#company-match-list").innerHTML =
        companyMatchesHTML;
      document.querySelector("#client-company-match").innerHTML =
        companyMatchesHTML;

      if (!name || !companyMatches.length) {
        document.querySelector("#company-match").classList.add("hidden");
        document.querySelector("#modal-company-match").classList.add("hidden");
      } else {
        document.querySelector("#company-match").classList.remove("hidden");
        document
          .querySelector("#modal-company-match")
          .classList.remove("hidden");
      }
    }
  }
}
