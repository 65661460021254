import { useFormContext } from "react-hook-form";

interface InputProps {
  name: string;
  label: string;
  id?: string;
  placeholder?: string;
  type?: "text" | "number" | "email" | "password";
  defaultValue?: string;
  onChange?: (event: InputEvent) => void;
}

const Input = ({
  name,
  label,
  id = name,
  placeholder = "",
  type = "text",
  defaultValue = "",
  onChange,
}: InputProps): JSX.Element => {
  const { register } = useFormContext();

  return (
    <>
      <label className='block mb-2 text-neutral-800 p2' htmlFor={id}>
        {label}
      </label>
      <input
        className='form-control'
        defaultValue={defaultValue}
        id={id}
        placeholder={placeholder}
        type={type}
        {...register(name, { onChange: onChange })}
      />
    </>
  );
};

export { Input };
