import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "element", "container", "hide"];

  declare inputTargets: Array<HTMLInputElement>;
  declare elementTargets: Array<HTMLInputElement>;
  declare hideTargets: Array<HTMLInputElement>;
  declare containerTargets: Array<HTMLInputElement>;

  connect(): void {
    this.inputTargets.forEach((input) => {
      this.toggleElement(input.checked, input.dataset.toggleTarget);
    });
    this.containerTargets.forEach((input) => {
      this.toggleElement(!input.checked, input.dataset.toggleTarget);
    });
  }

  handleChange(event): void {
    const checkedInput = event.target.checked;
    const toggleTarget = event.target.dataset.toggleTarget;
    this.toggleElement(checkedInput, toggleTarget);
  }

  handleChangeToHide(event): void {
    const checkedInput = event.target.checked;
    const toggleTarget = event.target.dataset.toggleTarget;
    this.toggleElement(!checkedInput, toggleTarget);
  }

  toggleElement(checked, toggleTarget): void {
    if (checked) {
      this.showElementTargets(toggleTarget);
    } else {
      this.hideElementTargets(toggleTarget);
    }
  }

  showElementTargets(target): void {
    const showElements = this.elementTarget(target);
    showElements.forEach((element) => {
      element.classList.remove("hidden");
    });
    const hideElements = this.hideTarget(target);
    hideElements.forEach((element) => {
      element.classList.add("hidden");
    });
  }

  hideElementTargets(target): void {
    const showElements = this.elementTarget(target);
    showElements.forEach((element) => {
      element.classList.add("hidden");
    });
    const hideElements = this.hideTarget(target);
    hideElements.forEach((element) => {
      element.classList.remove("hidden");
    });
  }

  elementTarget(target): Array<HTMLInputElement> {
    return this.elementTargets.filter((element) => {
      const values = element.dataset.showValues;
      return values.includes(target);
    });
  }

  hideTarget(target): Array<HTMLInputElement> {
    return this.hideTargets.filter((element) => {
      const values = element.dataset.showValues;
      return values.includes(target);
    });
  }
}
