import { Navigate, Route } from "react-router-dom";

import { NotFound } from "@/organization_app/components/NotFound";
import {
  ATTENDANCES_PATH,
  COMPANIES_PATH,
  INVOICINGS_PATH,
} from "@/organization_app/config/paths";
import { AttendancesForInvoicing } from "@/organization_app/features/invoicings/components/AttendancesForInvoicing";
import { ClientCompanies } from "@/organization_app/features/invoicings/components/ClientCompanies";
import { Invoicings } from "@/organization_app/features/invoicings/components/Invoicings";

export const invoicingsRoutes = [
  <Route
    path={INVOICINGS_PATH}
    element={<Invoicings />}
    key={"invoicings-routes"}
  >
    <Route index element={<Navigate to={COMPANIES_PATH} replace />} />
    <Route path={COMPANIES_PATH} element={<ClientCompanies />} />
    <Route path={ATTENDANCES_PATH} element={<AttendancesForInvoicing />} />
    <Route path='*' element={<NotFound />} />
  </Route>,
];
