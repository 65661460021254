import { FormEventHandler } from "react";
import { useFormContext } from "react-hook-form";
interface OptionType {
  value: string;
  label: string;
}

interface radioProps {
  onChange?: FormEventHandler<HTMLDivElement>;
  isInline?: boolean;
  required?: boolean;
  options: Array<OptionType>;
  name: string;
  defaultValue?: string | number;
}

export const RadioGroup = ({
  name,
  isInline = true,
  defaultValue,
  onChange,
  options,
  required = true,
}: radioProps): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  let classNames = isInline ? "flex space-x-10" : "block";
  if (errors[name]) {
    classNames += " field_with_errors";
  }
  return (
    <div onChange={onChange} className={classNames}>
      {options.map((option) => (
        <div
          key={`radio-${name}-${option.value}`}
          className='flex items-center space-x-2'
        >
          <input
            type='radio'
            className='form-radio'
            id={`${name}-${option.value}`}
            value={option.value}
            defaultChecked={option.value === defaultValue}
            required={required}
            {...register(name)}
          />
          <label
            className='form-radio-label'
            htmlFor={`${name}-${option.value}`}
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};
