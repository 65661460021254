import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];
  static values = {
    toggleCssClass: String,
  };

  declare buttonTargets: HTMLElement[];
  declare toggleCssClassValue: string;

  connect(): void {
    document.addEventListener(
      "select_all_toggle_controller.select_all",
      this.#show
    );
    document.addEventListener(
      "select_all_toggle_controller.some_selected",
      this.#show
    );
    document.addEventListener(
      "select_all_toggle_controller.deselect_all",
      this.#hide
    );
  }

  #hide = (): void => {
    this.buttonTargets.forEach((button) =>
      button.classList.add(...this.toggleCssClassValue.split(" "))
    );
  };

  #show = (): void => {
    this.buttonTargets.forEach((button) =>
      button.classList.remove(...this.toggleCssClassValue.split(" "))
    );
  };
}
