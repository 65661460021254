type executeHotjarEventType = (e: Event) => void;

declare global {
  interface Window {
    executeHotjarEvent: executeHotjarEventType;
    terminologyEmployee: string;
    noTimesheetsImageUrl: string;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function hotjarNoop(e: Event): void {
  // do nothing
}
