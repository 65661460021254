import autocomplete from "autocomplete.js";
import axios from "axios";
import { Controller } from "stimulus";

type Suggestion = {
  full_name: string;
  component: string;
};

type SuggestionsHandler = (data: Suggestion[]) => void;

export default class extends Controller {
  static targets = ["field", "form"];
  static values = {
    url: String,
  };

  declare fieldTarget: HTMLInputElement;
  declare formTarget: HTMLFormElement;
  declare urlValue: string;
  declare ac: { autocomplete: { setVal: (val: string) => void } };

  search(url: string): (query: string, callback: SuggestionsHandler) => void {
    return async (query: string, callback: SuggestionsHandler) => {
      const { data } = await axios.get(url, {
        params: {
          search_term: query,
        },
      });

      callback(data);
    };
  }

  connect(): void {
    this.ac = autocomplete(
      this.fieldTarget,
      {
        hint: false,
        cssClasses: { root: "algolia-autocomplete w-full" },
      },
      [
        {
          source: this.search(this.urlValue),
          debounce: 200,
          templates: {
            suggestion: (suggestion: Suggestion) => suggestion.component,
          },
        },
      ]
    ) // Take only the second of four callback arguments
      .on("autocomplete:selected", (...[, suggestion, ,]) => {
        this.ac.autocomplete.setVal(suggestion.full_name);
        this.formTarget.submit();
      });
  }
}
