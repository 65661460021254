interface OptionType {
  value: string;
  label: string;
}

export const buildOptions = (
  objects: Array<{ [key: string]: string | number | boolean }>,
  valueField: string,
  labelField: string
): Array<OptionType> => {
  return objects.map((object) => {
    return {
      value: object[valueField].toString(),
      label: object[labelField].toString(),
    };
  });
};
