import { Controller } from "stimulus";

export default class extends Controller {
  connect(): void {
    const form = document.getElementById("sso_form");
    if (form instanceof HTMLFormElement) {
      form.submit();
    }
  }
}
