import * as React from "react";

interface BackendContextProviderProps {
  children: React.ReactNode;
  value: unknown;
}

const BackendContext = React.createContext(undefined);

function BackendContextProvider({
  children,
  value,
}: BackendContextProviderProps): JSX.Element {
  return (
    <BackendContext.Provider value={value}>{children}</BackendContext.Provider>
  );
}

function useBackendContext<BackendContext>(): BackendContext {
  const context = React.useContext(BackendContext);
  if (context === undefined) {
    throw new Error(
      "useBackendContext must be used within a BackendContextProvider"
    );
  }
  return context;
}

export { BackendContextProvider, useBackendContext };
