import { DirectUpload } from "@rails/activestorage";
import { CustomDropzone } from "controllers/upload_crop_controller";
import Dropzone from "dropzone";

interface ICreateDirectUpload {
  dropzoneInstance: CustomDropzone;
  file: Dropzone.DropzoneFile;
  resizedFile: File;
  imageHiddenInput: HTMLInputElement;
  uploadedUrl: string;
}

export class CreateDirectUpload {
  declare directUpload: DirectUpload;
  declare dropzoneInstance: CustomDropzone;
  declare file: Dropzone.DropzoneFile;
  declare imageHiddenInput: HTMLInputElement;
  declare uploadedUrl: string;

  constructor({
    dropzoneInstance,
    file,
    resizedFile,
    imageHiddenInput,
    uploadedUrl,
  }: ICreateDirectUpload) {
    this.directUpload = new DirectUpload(resizedFile, uploadedUrl, this);
    this.dropzoneInstance = dropzoneInstance;
    this.file = file;
    this.imageHiddenInput = imageHiddenInput;
  }

  start(): void {
    this.directUpload.create((error: Error, blob: ActiveStorage.Blob) => {
      if (error) {
        this.imageHiddenInput.value = null;
        this.emitDropzoneError(error);
      } else {
        this.imageHiddenInput.value = blob.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  directUploadWillStoreFileWithXHR(xhr: XMLHttpRequest): void {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr: XMLHttpRequest): void {
    xhr.upload.addEventListener("progress", (event: ProgressEvent) =>
      this.emitDropzoneUploadProgressing(event)
    );
  }

  emitDropzoneUploading(): void {
    this.file.status = Dropzone.UPLOADING;
    this.dropzoneInstance.emit("processing", this.file);
  }

  emitDropzoneUploadProgressing(event: ProgressEvent): void {
    const progress = (event.loaded / event.total) * 100;
    if (progress >= 100) this.generateThumbnail();

    this.dropzoneInstance.emit(
      "uploadprogress",
      this.file,
      progress,
      event.loaded
    );
  }

  emitDropzoneError(error: Error): void {
    this.file.status = Dropzone.ERROR;
    this.dropzoneInstance.emit("error", this.file, error);
    this.dropzoneInstance.emit("complete", this.file);
  }

  emitDropzoneSuccess(): void {
    this.file.status = Dropzone.SUCCESS;
    this.dropzoneInstance.emit("success", this.file);
    this.dropzoneInstance.emit("complete", this.file);
  }

  generateThumbnail(): void {
    this.dropzoneInstance.options.createImageThumbnails = true;
    this.dropzoneInstance._enqueueThumbnail(this.file);
  }
}
