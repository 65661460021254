import { Route, Navigate } from "react-router-dom";

import {
  ATTENDANCES_APPROVAL_PATH,
  SUBMITTED_ATTENDANCES_PATH,
  REVIEW_ATTENDANCES_PATH,
  APPROVAL_SUCCESS_PATH,
  APPROVAL_FAILURE_PATH,
  MONASH_ATTENDANCES_APPROVAL_PATH,
  MONASH_SUBMITTED_ATTENDANCES_PATH,
  MONASH_REVIEW_ATTENDANCES_PATH,
  MONASH_APPROVAL_SUCCESS_PATH,
  MONASH_APPROVAL_FAILURE_PATH,
} from "@/client_app/config";

import { ApprovalFailure } from "@/client_app/features/timesheetApproval/components/ApprovalFailure";
import { ApprovalSuccess } from "@/client_app/features/timesheetApproval/components/ApprovalSuccess";
import { AttendanceApproval } from "@/client_app/features/timesheetApproval/components/AttendanceApproval";
import { FlaggedAttendances } from "@/client_app/features/timesheetApproval/components/FlaggedAttendances";
import { SubmittedAttendances } from "@/client_app/features/timesheetApproval/components/SubmittedAttendances";

export const timesheetApprovalRoutes = [
  <Route
    path={ATTENDANCES_APPROVAL_PATH}
    element={<AttendanceApproval />}
    key='timesheetApprovalRoute'
  >
    <Route
      index
      element={<Navigate to={SUBMITTED_ATTENDANCES_PATH} replace />}
    />
    <Route
      path={SUBMITTED_ATTENDANCES_PATH}
      element={<SubmittedAttendances />}
    />
    <Route path={REVIEW_ATTENDANCES_PATH} element={<FlaggedAttendances />} />
    <Route path={APPROVAL_SUCCESS_PATH} element={<ApprovalSuccess />} />
    <Route path={APPROVAL_FAILURE_PATH} element={<ApprovalFailure />} />
  </Route>,
];
export const monashTimesheetApprovalRoutes = [
  <Route
    path={MONASH_ATTENDANCES_APPROVAL_PATH}
    element={<AttendanceApproval />}
    key='timesheetApprovalRoute'
  >
    <Route
      index
      element={<Navigate to={MONASH_SUBMITTED_ATTENDANCES_PATH} replace />}
    />
    <Route
      path={MONASH_SUBMITTED_ATTENDANCES_PATH}
      element={<SubmittedAttendances />}
    />
    <Route
      path={MONASH_REVIEW_ATTENDANCES_PATH}
      element={<FlaggedAttendances />}
    />
    <Route path={MONASH_APPROVAL_SUCCESS_PATH} element={<ApprovalSuccess />} />
    <Route path={MONASH_APPROVAL_FAILURE_PATH} element={<ApprovalFailure />} />
  </Route>,
];
