import { formatCurrency } from "@/organization_app/features/invoicings/utils/CurrencyFormatterUtils";

interface SummaryRowProps {
  totalHoursWorked: string;
  totalPaymentTermSurcharge: number;
  totalCost: number;
}

export const PreviewInvoicesSummaryRow = ({
  totalHoursWorked,
  totalPaymentTermSurcharge,
  totalCost,
}: SummaryRowProps): JSX.Element => {
  return (
    <>
      {totalPaymentTermSurcharge > 0 && (
        <tr>
          <td colSpan={4} className='border-none text-right pr-10'>
            <strong>Payment Term Surcharge</strong>
          </td>
          <td className='border-none'></td>
          <td className='border-none'>
            <strong>
              <span>{formatCurrency(totalPaymentTermSurcharge)}</span>
            </strong>
          </td>
          <td className='border-none' />
        </tr>
      )}
      <tr>
        <td colSpan={4} className='border-none text-right pr-10'>
          <strong>Total</strong>
        </td>
        <td className='border-none'>
          <strong>
            <span>{totalHoursWorked}</span>
          </strong>
        </td>
        <td className='border-none'>
          <strong>
            <span>{formatCurrency(totalCost)}</span>
          </strong>
        </td>
        <td className='border-none' />
      </tr>
    </>
  );
};
