import Rails from "@rails/ujs";
import BaseController from "controllers/base_controller";

export default class extends BaseController {
  static targets = ["form", "content"];

  static values = {
    createMessageUrl: String,
  };

  declare formTarget: HTMLFormElement;
  declare contentTarget: HTMLInputElement;
  declare createMessageUrlValue: string;
  declare conversationContainer: HTMLElement;
  declare messageClass: string;

  connect() {
    this.conversationContainer = document.getElementById(
      "conversation_container"
    );
    this.messageClass = "messages";
  }

  sendMessage(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const formData = new FormData(this.formTarget);
    formData.append("content", this.contentTarget.value);
    Rails.ajax({
      type: "post",
      url: this.createMessageUrlValue,
      data: formData,
      dataType: "json",
      success: (data) => this.onSuccess(data),
      error: (data) => this.errorHandling(data),
    });
  }

  onSuccess(data) {
    this.conversationContainer
      .getElementsByClassName(this.messageClass)[0]
      .insertAdjacentHTML("beforeend", data.replace_content.message_component);
    this.formTarget.reset();
    document.getElementById("send-message-error").innerText = "";
    this.scrollToTheBottom();
  }

  errorHandling(data) {
    document.getElementById("send-message-error").innerText = data.error;
    document.getElementById("message-content").classList.add("border-red-500");
  }
}
