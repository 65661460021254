import Choices from "choices.js";
import { debounce } from "lodash";
import { Controller } from "stimulus";

type SelectOption = {
  value: string;
  label: string;
  selected: boolean;
};

export default class extends Controller {
  static targets = ["select", "input"];
  static values = {
    searchPath: String,
    searchKey: String,
    selectOptions: Array,
    debouncePeriod: Number,
  };

  declare selectTarget: HTMLSelectElement;
  declare inputTarget: HTMLInputElement;
  declare searchPathValue: string;
  declare searchKeyValue: string;
  declare choices: Choices;
  declare input: HTMLInputElement;
  declare selectOptionsValue: SelectOption[];
  declare debouncePeriodValue: number;

  declare hasSearchPathValue: boolean;

  initialize(): void {
    this.search = debounce(this.search.bind(this), this.debouncePeriodValue);
    this.update = this.update.bind(this);
    this.filter = this.filter.bind(this);
    this.options = this.options.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.optionsReducer = this.optionsReducer.bind(this);
  }

  connect(): void {
    this.choices = new Choices(this.selectTarget, this.options());
    this.choices.setChoices(this.getSelectOptions());
    this.inputTarget.addEventListener("input", this.handleInput);
  }

  getSelectOptions(): SelectOption[] {
    if (this.selectOptionsValue.length > 0) {
      return this.selectOptionsValue;
    } else {
      return [];
    }
  }

  handleInput(event: InputEvent): void {
    if (event.target instanceof HTMLInputElement) {
      // Only search when searchPath is present. If not it will search by select options
      if (this.hasSearchPathValue) {
        this.search(event.target);
      }
    }
  }

  search(input: HTMLInputElement): void {
    const splittedPath = this.searchPathValue.split("?");
    let permittedParams = "";
    if (splittedPath.length == 2) {
      permittedParams = splittedPath[1];
    }
    const searchParams = new URLSearchParams(permittedParams);
    searchParams.append(this.searchKeyValue, input.value);

    fetch(`${splittedPath[0]}?${searchParams}`, {
      headers: { "X-Requested-With": "XMLHttpRequest" },
    })
      .then((response) => response.json())
      .then(this.update);
  }

  update(data: SelectOption[]): void {
    if (data) {
      this.choices.setChoices(data.filter(this.filter), "value", "label", true);
    } else {
      this.choices.clearChoices();
    }
  }

  filter(item: SelectOption): boolean {
    return ![...this.selectTarget.options].some((option) => {
      return option.value === item.value;
    });
  }

  // see more: https://github.com/Choices-js/Choices#configuration-options
  options(): Record<string, unknown> {
    return "renderChoiceLimit maxItemCount addItems removeItems removeItemButton editItems duplicateItemsAllowed delimiter paste searchEnabled searchChoices searchFloor searchResultLimit position resetScrollPosition addItemFilter shouldSort shouldSortItems placeholder placeholderValue prependValue appendValue renderSelectedChoices loadingText noResultsText noChoicesText itemSelectText addItemText maxItemText silent"
      .split(" ")
      .reduce(this.optionsReducer, {});
  }

  optionsReducer(
    accumulator: Record<string, unknown>,
    currentValue: string
  ): Record<string, unknown> {
    if (
      this.element instanceof HTMLElement &&
      this.element.dataset[currentValue]
    )
      accumulator[currentValue] = this.element.dataset[currentValue];
    return accumulator;
  }
}
